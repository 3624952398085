/* import react modules  */
import React, { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';

/* import CSS modules  */
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
// import PropTypes from 'prop-types';
import {  Input, FormGroup } from 'reactstrap';
import {AppHeader} from '@coreui/react';
import { AppHeaderDropdown, AppSidebarToggler } from '@coreui/react';

// const propTypes = {
//   children: PropTypes.node,
// };
// const defaultProps = {};

class DefaultHeader extends Component {
        /**** render view  ****/
        render() {
         
          // const { children, ...attributes } = this.props;
            if(this.props.isHeader === 'SUPERADMIN' ){
              return(
                <AppHeader fixed>
                    <Suspense>    
                      <React.Fragment>
                        <AppSidebarToggler className="d-lg-none" display="md" mobile />
                        <Link to="/" className="navbar-brand">
                        <img src="../../assets/img/logoinner.png" alt="MadeTo Grow" className="navbar-brand-full" />
                        </Link>
                        <span className="adminhead"> MTG Admin Panel</span>
                        {/*<AppSidebarToggler className="d-md-down-none" display="lg" />*/}
                        {/* <Nav className="d-md-down-none" navbar>
                          <NavItem className="px-3">
                            <Link to="/" className="nav-link" >Dashboard</Link>
                          </NavItem>
                        </Nav> */}
                        <Nav className="ml-auto" navbar>
                          {/* <AppHeaderDropdown direction="down">
                            <DropdownToggle nav><i className="fa fa-search fa-lg"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg">
                                  <FormGroup>
                                  <Input type="text" id="ccnumber" placeholder="Search" required />
                                  </FormGroup>
                            </DropdownMenu>
                          </AppHeaderDropdown> */}
                       
                          {/* <Link to="/" className="nav-link" > */}
                            <i className="fa fa-sign-out fa-lg" onClick={ () => {this.props.superAdminlogOut();}}></i>
                          {/* </Link>                 */}
                        </Nav>
                      </React.Fragment>
                    </Suspense>
                  </AppHeader>
              )
            }
           
            else if(this.props.isHeader === 'ADMIN'){
                return (
                  <AppHeader fixed>
                    <Suspense>    
                      <React.Fragment>
                        <AppSidebarToggler className="d-lg-none" display="md" mobile />
                        <Link to="/" className="navbar-brand">
                        <img src="../../assets/img/logoinner.png" alt="MadeTo Grow" className="navbar-brand-full" />
                        </Link>
                        <span className="adminhead">Admin Panel</span>
                        {/*<AppSidebarToggler className="d-md-down-none" display="lg" />*/}
                        {/* <Nav className="d-md-down-none" navbar>
                          <NavItem className="px-3">
                            <Link to="/" className="nav-link" >Dashboard</Link>
                          </NavItem>
                        </Nav> */}
                        <Nav className="ml-auto" navbar>
                          {/* <AppHeaderDropdown direction="down">
                            <DropdownToggle nav><i className="fa fa-search fa-lg"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg">
                                  <FormGroup>
                                  <Input type="text" id="ccnumber" placeholder="Search" required />
                                  </FormGroup>
                            </DropdownMenu>
                          </AppHeaderDropdown> */}
                          {/* <Link to="/" className="nav-link" > */}
                            <i className="fa fa-sign-out fa-lg" onClick={ () => {this.props.logout();}}></i>
                          {/* </Link>                 */}
                        </Nav>
                      </React.Fragment>
                    </Suspense>
                  </AppHeader>
                );
                  } else{
                    return(
                    <div></div>
                    )
                  }
              }
}

// DefaultHeader.propTypes = propTypes;
// DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
