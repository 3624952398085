import React from 'react';
import { Link } from 'react-router-dom';
import {  Card, CardBody, CardGroup, Col, Container,  Row} from 'reactstrap';
import validator from 'validator';
import { isNumeric, isLength, isEmail } from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import Constants from '../../../config/constants';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import BlockUi from 'react-block-ui';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SuperAdminSignin extends React.Component{
    constructor(props){
        super(props);
        let superAdminId = localStorage.getItem("superAdminId");
        this.state = {
            username : "",
            password: "",
            blocking: false,
            
        }
        this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**** function to change form data ****/
    handleChange(event) {
        
        if (event.target.id === 'username') {
          this.setState({ username: event.target.value });
        } else {
          this.setState({ password: event.target.value });
        }
        }



    /**** function to handle submit event ****/
	handleSubmit(event) {

        // localStorage.setItem("superAdminToken", "datatoken", 365);
        // localStorage.setItem("superAdminName", "datadataname", 365);
        // localStorage.setItem("superAdminNumber", "datacontactNumber", 365);
        // localStorage.setItem("superAdminId", "dataofficerDesignation", 365);
        // this.props.updateSuperAdminState();
        // event.preventDefault();
        // let _that = this;
      

        event.preventDefault();
        var _that = this;
        if (_that.state.username.trim() != undefined && _that.state.username.trim() != null && _that.state.username.trim() != "") {
            if(_that.state.password.trim() != undefined && _that.state.password.trim() != null && _that.state.password.trim() != ""){
        
        
        _that.setState({ blocking: true });
                
        fetch(Constants.SERVER_URL + "admin/sign-in", {
            method: 'POST', 
            headers: {                
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                email: _that.state.username.trim(),
                password: _that.state.password.trim()
            }),
          }).then(function (response) {
          
            if(response.status === 200){
              let responseData = response.json();
              responseData.then(function (data) {
                
                toast.success( Constants.SUCCESS_SIGNIN, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false
                  });

                _that.setState({ blocking: false});
                localStorage.setItem("superAdminToken", data.token, 365);
                localStorage.setItem("superAdminName", data.data.role_data.name, 365);
                localStorage.setItem("superAdminId", data.data.role_id, 365);
                _that.props.updateSuperAdminState();
                

                // Alert.success('<h5>' + Constants.SUCCESS_SIGNIN + '</h5>', {
                //   position: 'top-right',
                //   effect: 'slide',
                //   beep: false,
                //   timeout: 2000,
                //   offset: Constants.OffSetValue
                // });


                

                
              })
            
            } 

            else if(response.status === 403){ 
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
               
                Alert.error('<h5>' + "Password is invalid" + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }


            else if(response.status === 404){ 
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
               
                Alert.error('<h5>' + Constants.EMPTY_USER + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }
            else if(response.status === 403 && response.status === 401){ 
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
               
                Alert.error('<h5>' + Constants.CHECK_CREDENTIALS + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }
            else{ 
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
               
                Alert.error('<h5>' + data.message + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }
          }).catch(function (error) {
            _that.setState({ blocking: false });
            if (navigator.onLine) {
              Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            } else {
              Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            }
          });

        } else{
            
            Alert.error('<h5>' + "Password Shouldn't be blank" + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
            });
        }

        } else{
            
            Alert.error('<h5>' + "Username Shouldn't be blank" + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
            });
        }




	}

    render(){
        
        
        const required = (value, props) => {
			if (!value || (props.isCheckable && !props.checked)) {
				return <span className="validationForm">Required</span>;
			}
        };

        const email = (value) => {
          if (!isEmail(value)) {
            return <span className="form-error is-visible">Please enter a valid Email</span>;
          }
        };

        const number = (value) => {
            if (!isNumeric(value)) {
              return <span className="validationForm">Please enter a valid Contact Number</span>;
            }
        };

        const minNo = (value, props) => {
            if (!isLength(value, { min: 10 })) {
              return <span className="validationForm">Please Enter a Valid Contact Number</span>;
            }
        };
      
        const maxNo = (value, props) => {
            if (!isLength(value, { max: 10 })) {
                return <span className="validationForm">Please Enter a Valid Contact Number</span>;
            }
        };

        const maxPassword = (value, props) => {
            if (!isLength(value, { max: 32 })) {
              return <span className="form-error is-visible passerror">You have reached your maximum limit of characters allowed</span>;
            }
          };
      
        
        // const required = (value) => {
        //     if (!value.toString().trim().length) {
        //       // We can return string or jsx as the 'error' prop for the validated Component
        //       return 'require';
        //     }
        //   };

        return(
        <div className="mainContent sAdminMainContent">
        <Alert html={true} />
        {/* <ToastContainer/> */}
         <div className="app flex-row align-items-center loginpage">
            <Container>
            <BlockUi blocking={this.state.blocking}>
              <Row className="justify-content-center">
              <Alert html={true} />
               <Col md="12">
                <CardGroup>
                  <Card>
                    <CardBody>
                      <Row className="justify-content-center">
                        <Col md="7" className="loginleft">
                            <Link to="/" className="navbar-brand">
                              <img src="../../assets/img/logo-inner.png" alt="madetogrow" className="navbar-brand-full" />
                            </Link>
                            <div className="welcometext">
                                <h1>Welcome to MadeTo Grow</h1>
                                <p>Log in to access your account</p>
                            </div>
                        </Col>
                        <Col md="5">
                          <div className="loginform">
                                <div className="limiter">
                                    <div className="wrap-login100 p-l-85 p-r-85 p-t-55 p-b-55">
                                        {/* <form className="login100-form validate-form flex-sb flex-w"> */}
                                        <Form ref={c => { this.form = c }} onSubmit={this.handleSubmit}>
                                            <h1>Super Admin Login </h1>

                                            <div className="wrap-input100 validate-input m-b-36" >
                                                {/* <input className="input100" type="text" name="username" /> */}
                                                <Input type="text"  placeholder="Email" name='username' id="username" onChange={this.handleChange} value={this.state.username} validations={[required, email]} autoComplete="off"/>
                                                <span className="focus-input100"></span>
                                            </div>
                                            
                                            <div className="wrap-input100 validate-input m-b-24" >
                                                {/* <span className="btn-show-pass">
                                                    <i className="fa fa-eye"></i>
                                                </span> */}
                                                {/* <Input className="input100" type="password" name="pass" /> */}
                                                <Input type='password' id="password" placeholder="Password" type="password" name="pass" onChange={this.handleChange} value={this.state.password} validations={[required, maxPassword]} autoComplete="off"/>
                                                <span className="focus-input100"></span>
                                            </div>
                                            
                                            {/* <div className="flex-sb-m w-full p-b-48">
                                                <div className="contact100-form-checkbox">
                                                    <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me"/>
                                                    <label className="label-checkbox100" for="ckb1">
                                                        Remember me
                                                    </label>
                                                </div>

                                                <div>
                                                    <a href="#" className="txt3">
                                                        Forgot Password?
                                                    </a>
                                                </div>
                                            </div> */}

                                            <div className="container-login100-form-btn">
                                                {/* <button className="login100-form-btn">
                                                    Login
                                                </button> */}
                                                <Button id="login" className="login100-form-btn" >LOGIN</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            </BlockUi>
          </Container>
        </div>
            
        </div>
        )
    }
}

export default SuperAdminSignin;