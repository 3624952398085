/* import react modules  */
import React, { Component } from 'react';
import { Redirect, Switch, Route} from 'react-router-dom';

// import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { Container } from 'reactstrap';
// import {AppHeader,AppSidebar} from '@coreui/react';
import './App.scss';
import DefaultHeader from './containers/DefaultLayout/header';
import DefaultSidebarnav from './containers/DefaultLayout/sidebarnav';
import Dashboard from './components/menu/dashboard';

import Login from './components/auth/login';

import SuperAdminSignin from './components/superAdmin/superAdminAuth/superAdminSignin';

/* import helper file */
import HelperFunction from './config/helperFunction';
import Constants from './config/constants';

/* modules for Alerts */
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import Organizational from './components/superAdmin/superAdminMenu/organizational';
import Users from './components/superAdmin/superAdminMenu/users';
import PrivateUsers from './components/superAdmin/superAdminMenu/privateUsers';
import Subscription from './components/superAdmin/superAdminMenu/subscription';
import Task from './components/superAdmin/superAdminMenu/task';
import Themes from './components/superAdmin/superAdminMenu/themes';
import Programs from './components/superAdmin/superAdminMenu/programs';
import LifeResetUsers from './components/superAdmin/superAdminMenu/lifeResetUsers';

import AdminDashboard from './components/superAdmin/superAdminMenu/adminDashboard';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Layout extends Component {
  constructor(props){
    super(props);
    
    let email = localStorage.getItem("email");
    let password = localStorage.getItem("id");
    let token = localStorage.getItem("token");
    

    let superAdminToken = localStorage.getItem("superAdminToken");
    let superAdminName = localStorage.getItem("superAdminName");
    let superAdminId = localStorage.getItem("superAdminId");
    
    if(token && email && password ){
      
      this.state = { loggedInType: "ADMIN", userData: { email: email, password: password, token: token, isLogin: true } };
    } 

    else if(superAdminToken && superAdminName && superAdminId){
      this.state = { loggedInType: "SUPERADMIN", adminData: { adminName: superAdminName, adminNumber: superAdminId, token: superAdminToken, isLogin: true } };
    }
    
    else{
      
      this.state = { loggedInType: "", manage: false, userData: { email: "", password: "", token: "", isLogin: false } };
    }

    /**** binding to make `this` work in the callback ***/
    this.updateState = this.updateState.bind(this);
    this.logOut = this.logOut.bind(this);
    this.updateSuperAdminState = this.updateSuperAdminState.bind(this);
    this.superAdminlogOut = this.superAdminlogOut.bind(this);
  }

  /**** function for updateState ****/
  updateState(){
    
    let email = localStorage.getItem("email");
    let password = localStorage.getItem("id");
    let token = localStorage.getItem("token");
    
    if(token && email && password){
      this.setState({loggedInType: "ADMIN", userData: { email: email, password: password, token: token, isLogin: true }});
    } else{
      this.setState({ loggedInType: "", manage: false, userData: { email: "", password: "", token: "", isLogin: false } });
    }
  }

  /**** method for update super admin state ***/
  updateSuperAdminState(){
    
    let superAdminToken = localStorage.getItem("superAdminToken");
    let superAdminName = localStorage.getItem("superAdminName");
    let superAdminId = localStorage.getItem("superAdminId");
    

    if(superAdminToken && superAdminName && superAdminId){
      this.setState({ loggedInType: "SUPERADMIN", adminData: { adminName: superAdminName, adminNumber: superAdminId, token: superAdminToken, isLogin: true } });
    } else {
      this.setState({ loggedInType: "", adminData: { adminName: "", adminNumber: "", token: "", isLogin: false } });
    }
  }


  /**** API function for Token ****/
  logOut(){
          localStorage.clear();
          localStorage.removeItem("email");
          localStorage.removeItem("id");
          localStorage.removeItem("token");
          this.updateState();
  }

   /**** API function for Token ****/
   superAdminlogOut(){
    localStorage.clear();
    this.updateSuperAdminState();
    }

 
  /**** render view 300000  ****/
  render() {

  
    return (
          <div className="app">
            <ToastContainer/>

              {/* <AppHeader fixed>
                <Suspense>                 */}
                <Alert html={true} />
                  <DefaultHeader propValue={this.updateState} logout={this.logOut} superAdminlogOut={this.superAdminlogOut} isHeader={this.state.loggedInType} onRef={ref => (this.clickFuc = ref)}/>
                {/* </Suspense>
              </AppHeader> */}
                  <div className="app-body">
                    {/* <AppSidebar fixed display="lg">    */}
                      <DefaultSidebarnav isHeader={this.state.loggedInType} />               
                    {/* </AppSidebar> */}
                    <main className="main">            
                        <Container fluid>
                          <Switch>
                            {/* <Route exact path="/" component={((props) => {
                              if(this.state.loggedInType === "ADMIN" ){
                                return(
                                  <Redirect to='/dashboard' />
                                )
                              }
                              else if( this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Redirect to='/admin/Organisation' />
                                )
                              }
                               else{
                                return(
                                <Login propValue={this.updateState} isHeader={this.state.loggedInType}/>
                                )
                              }
                            })}/> */}


                            <Route exact path="/" component={((props) => {
                             if( this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Redirect to='/admin/dashboard' />
                                )
                              }
                               else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>
                            
                            {/* <Route path="/forgetPassword" component={ ((props) => {
                              if(this.state.loggedInType === "ADMIN"){
                                return(
                                  <Redirect to='/dashboard' />
                                  )
                              } else{
                                return(
                                  <ForgetPassword propValue={props}/>
                                )
                              }
                            })}/> */}

                          <Route exact path="/admin" component={((props) => {
                              if( this.state.loggedInType == "SUPERADMIN" ){
                                
                                return (
                                  // <Dashboard propValue={props} updateAdminState={this.updateAdminState}/>
                                  <Redirect to="/admin/dashboard" />
                                )  
                              } else{
                                return (
                                  <SuperAdminSignin propValue={props} updateSuperAdminState={this.updateSuperAdminState} updateAdminState={this.updateState}/>
                                )
                              }
                            })} />
                            {/* <Route path="/authentication" component={ ((props) => {
                              if(this.state.loggedInType === "ADMIN"){
                                return(
                                  <Redirect to='/dashboard' />
                                  )
                              } else{
                                return(
                                  <AdminAuthentication propValue={props}/>
                                )
                              }
                            })}/> */}

                            <Route path="/dashboard" component={ ((props) => {
                              if(this.state.loggedInType === "ADMIN" ){
                                return(
                                  <Dashboard updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/' />
                                )
                              }
                            })}/>

                            <Route path="/admin/organisation" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Organizational updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>

                            <Route path="/admin/dashboard" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <AdminDashboard updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>

                            <Route path="/admin/users" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Users updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>
                    
                            <Route path="/admin/privateusers" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <PrivateUsers updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>

                          {/* IMP CODE */}
                          {/* <Route path="/admin/themes" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Themes updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>

                          <Route path="/admin/programs" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Programs updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/> */}

                            <Route path="/admin/task" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Task updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/> 

                            <Route path="/admin/subscription" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <Subscription updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>

                            <Route path="/admin/lifeResetUsers" component={ ((props) => {
                              if(this.state.loggedInType === "SUPERADMIN"){
                                return(
                                  <LifeResetUsers updateState={this.updateState} />
                                  )
                              } else{
                                return(
                                  <Redirect to='/admin' />
                                )
                              }
                            })}/>
                            
                            <Route component={((props) => {
                                return (
                                  <Redirect to="/" />
                                )
                            })} />
                          </Switch>
                        </Container>
                      </main>
                  </div>
          </div>
    );
  }
}

export default Layout;
