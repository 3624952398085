import React from 'react';
import { Badge, Card, CardBody,  Col, Row } from 'reactstrap';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Constants from '../../../config/constants';
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';

/* modules for validation */
import { isEmail, isLength, isNumeric, isAlpha } from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

// import { ToastContainer, toast } from 'react-toastify';
// import '../../../assests';
import Pagination from "react-js-pagination";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// var addMonths = require('date-fns/add_months')
// var subDays = require('date-fns/subDays');
// import { subDays } from 'date-fns'

class Users extends React.Component {
  constructor(props) {
    super(props);
    let superAdminId = localStorage.getItem("superAdminId");
    let superAdminToken = localStorage.getItem("superAdminToken");

    this.state = {
      superAdminId: superAdminId,
      superAdminToken: superAdminToken,

      search: "",
      delayTimer: 0,
      searchExist: false,
      blocking: false,
      showForAddSchool: false,
      addUName: "",
      addUserName: "",
      radioValue: "",
      addUserYOB: "",
      addUserEmail: "",
      addUserPassword: "",
      show: false,
      editUsername: "",
      editName: "",
      editRadio: "",
      editYOB: "",
      editEmail: "",
      editPass: "",
      currentSchoolId: 0,
      selectedState: null,
      stateOptions: [],
      userListing: [],
      currentEditId: 0,
      uploadFile: "",
      selectAuthority: "SCHOOL",

      modalIconStatus: 0,
      password: "",
      confirmPassword: "",
      editPassword: "",
      editConfirmPassword: "",
      importDataPopup: false,
      importFileData: [],
      page: 1,
      activePage: 1,
      pageSize: 10,
      isMore: false,
      selectedValue: "",
      finalPagesCount: 1,
      genderListData: [{ id: "MALE", value: "MALE" },
      { id: "FEMALE", value: "FEMALE" },
      { id: "OTHER", value: "OTHER" }],
      date: "",
      showMigratePopup: false,
      selectMigrateAuthority: "SCHOOL",
      currentSchoolMigrateId: 0,
      selectedMigrate: null,
      migrateOptions: [],
      currentUIdMigrate: 0,
      currentSelectRole: 0,
      selectRoleData: [],
      // selectRoleData: [{label: "STUDENT", value: "STUDENT"},
      // {label: "TEACHER", value: "TEACHER"},
      // {label: "LEADER", value: "LEADER"},
      // {label: "EMPLOYEE", value: "EMPLOYEE"},
      // {label: "OTHER", value: "OTHER"}],

      isChecked: false
      // dob: {},
      // day: 0,
      // month: 0,
      // year: 0,
      // selectedDate: ""
    }
    this.schoolList = this.schoolList.bind(this);
    this.openAddUser = this.openAddUser.bind(this);
    this.closeForAddUser = this.closeForAddUser.bind(this);
    this.handleAddOfficerChange = this.handleAddOfficerChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.showEditSchool = this.showEditSchool.bind(this);
    this.closeEditSchool = this.closeEditSchool.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.userList = this.userList.bind(this);
    this.resetAddUser = this.resetAddUser.bind(this);
    this.resetEditUser = this.resetEditUser.bind(this);
    this.uploadHandleChange = this.uploadHandleChange.bind(this);
    this.handleSelectAuthority = this.handleSelectAuthority.bind(this);
    this.varificationRequest = this.varificationRequest.bind(this);
    this.handleChangeForPassword = this.handleChangeForPassword.bind(this);
    this.handleChangeForEditPassword = this.handleChangeForEditPassword.bind(this);
    this.bulkUserSubmit = this.bulkUserSubmit.bind(this);

    this.openImportData = this.openImportData.bind(this);
    this.closeImportData = this.closeImportData.bind(this);
    this.isMoreData = this.isMoreData.bind(this);
    this.searchHandle = this.searchHandle.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    // this.dateChange = this.dateChange.bind(this);
    this.openMigratePopup = this.openMigratePopup.bind(this);
    this.closeMigratePopup = this.closeMigratePopup.bind(this);
    this.handleSelectMigrateAuthority = this.handleSelectMigrateAuthority.bind(this);
    this.schoolMigrateList = this.schoolMigrateList.bind(this);
    this.handleMigrateSubmit = this.handleMigrateSubmit.bind(this);
    this.confirmMovePrivate = this.confirmMovePrivate.bind(this);
    this.MovePrivate = this.MovePrivate.bind(this);
    this.ResendAllCheckbox = this.ResendAllCheckbox.bind(this);
    this.confirmSendAllUser = this.confirmSendAllUser.bind(this);
    this.selectAllVerification = this.selectAllVerification.bind(this);
    this.downloadUsersFile = this.downloadUsersFile.bind(this);
  }


  /**** Component life cycle method ***/
  componentWillMount() {
    this.schoolList(); //get schoolList Details
    this.schoolMigrateList(); // get migration List Details in popup
  }

  // get school listing
  schoolList() {

    var _that = this;
    var param = {
      page_number: 1, page_size: 1000,
      account_type: _that.state.selectAuthority
      // , search: _that.state.search.trim(), designationId: _that.state.selectId
    }
    _that.setState({ blocking: true });

    fetch(Constants.SERVER_URL + "admin/accounts?" + $.param(param), {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": _that.state.superAdminToken
      }
    }).then(function (response) {

      if (response.status === 200) {
        let responseData = response.json();
        responseData.then(function (data) {

          _that.setState({ blocking: false });

          // if (data.data.length == _that.state.pageSize) {
          //   _that.setState({
          //     isMore: true
          //   });
          // } else {
          //   _that.setState({
          //     isMore: false
          //   });
          // }

          if (_that.state.page == 1) {
            var resultArray2 = data.data.map(function (obj) {
              var object = obj
              object.label = obj.name;
              object.value = obj.id;
              // delete obj.name;
              // delete obj.id;
              return object
            });
            _that.setState({
              blocking: false,
              stateOptions: resultArray2
            });

          }
          else if (data.data.length > 0) {
            let oldData = JSON.parse(JSON.stringify(_that.state.stateOptions));
            let newData = JSON.parse(JSON.stringify(data.data));
            let totalData = oldData.concat(newData);

            _that.setState({ stateOptions: totalData, blocking: false });
          } else if (_that.state.searchExist == false) {

            let oldData = JSON.parse(JSON.stringify(_that.state.stateOptions));
            let newData = JSON.parse(JSON.stringify(data.data));
            let totalData = oldData.concat(newData);

            _that.setState({ stateOptions: totalData, blocking: false });
          } else {
            _that.setState({ blocking: false, stateOptions: data.data, page: 1 });
          }

        })

      } else {
        let responseData = response.json();
        responseData.then(function (data) {

          _that.setState({ blocking: false });

          Alert.error('<h5>' + data.message + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        })
      }
    }).catch(function (error) {
      _that.setState({ blocking: false });
      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });
  }


  // get school migrate listing
  schoolMigrateList() {

    var _that = this;
    var param = {
      page_number: 1, page_size: 1000,
      account_type: _that.state.selectMigrateAuthority
      // , search: _that.state.search.trim(), designationId: _that.state.selectId
    }
    _that.setState({ blocking: true });

    fetch(Constants.SERVER_URL + "admin/accounts?" + $.param(param), {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": _that.state.superAdminToken
      }
    }).then(function (response) {

      if (response.status === 200) {
        let responseData = response.json();
        responseData.then(function (data) {

          _that.setState({ blocking: false });

          // if (data.data.length == _that.state.pageSize) {
          //   _that.setState({
          //     isMore: true
          //   });
          // } else {
          //   _that.setState({
          //     isMore: false
          //   });
          // }

          if (_that.state.page == 1) {
            var resultArray2 = data.data.map(function (obj) {
              var object = obj
              object.label = obj.name;
              object.value = obj.id;
              // delete obj.name;
              // delete obj.id;
              return object
            });
            _that.setState({
              blocking: false,
              migrateOptions: resultArray2
            });

          }
          else if (data.data.length > 0) {
            let oldData = JSON.parse(JSON.stringify(_that.state.migrateOptions));
            let newData = JSON.parse(JSON.stringify(data.data));
            let totalData = oldData.concat(newData);

            _that.setState({ migrateOptions: totalData, blocking: false });
          } else if (_that.state.searchExist == false) {

            let oldData = JSON.parse(JSON.stringify(_that.state.migrateOptions));
            let newData = JSON.parse(JSON.stringify(data.data));
            let totalData = oldData.concat(newData);

            _that.setState({ migrateOptions: totalData, blocking: false });
          } else {
            _that.setState({ blocking: false, migrateOptions: data.data, page: 1 });
          }

        })

      } else {
        let responseData = response.json();
        responseData.then(function (data) {

          _that.setState({ blocking: false });

          Alert.error('<h5>' + data.message + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        })
      }
    }).catch(function (error) {
      _that.setState({ blocking: false });
      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });
  }


  varificationRequest(account_id) {

    var _that = this;
    var param = {
      user_id: account_id
      // , search: _that.state.search.trim(), designationId: _that.state.selectId
    }
    _that.setState({ blocking: true });

    fetch(Constants.SERVER_URL + "admin/user/verification-send?" + $.param(param), {
      method: 'GET',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": _that.state.superAdminToken
      }
    }).then(function (response) {

      if (response.status === 204) {
        // let responseData = response.json();
        // responseData.then(function (data) {
        _that.setState({ blocking: false });
        // _that.setState({ blocking: false});
        //   _that.setState({ blocking: false, 
        //     userListing: data.data 
        //   });
        _that.userList(_that.state.currentSchoolId);
        Alert.success('<h5>' + " Verification mail has been sent to registered Email" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
        // })

      } else {
        // let responseData = response.json();
        // responseData.then(function (data) {

        _that.setState({ blocking: false });

        Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
        // })
      }
    }).catch(function (error) {
      _that.setState({ blocking: false });
      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });
  }


  // get user listing
  userList(account_id) {

    var _that = this;

    if (account_id != 0) {

      var param = {
        page_number: _that.state.activePage, page_size: _that.state.pageSize, account_id: account_id
        , search_text: _that.state.search.trim()
        , gender: _that.state.selectedValue
      }
      _that.setState({ blocking: true });

      fetch(Constants.SERVER_URL + "admin/users?" + $.param(param), {
        method: 'GET',
        headers: {
          "Accept": "application/json",
          "Content-type": "application/json",
          "Authorization": _that.state.superAdminToken
        }
      }).then(function (response) {

        if (response.status === 200) {
          let responseData = response.json();
          responseData.then(function (data) {

            _that.setState({ blocking: false });

            // if (data.data.length == _that.state.pageSize) {
            //   _that.setState({
            //     isMore: true
            //   });
            // } else {
            //   _that.setState({
            //     isMore: false
            //   });
            // }

            // if (_that.state.page == 1) {
            _that.setState({
              blocking: false,
              userListing: data.data, finalPagesCount: data.totalUsers
            });
            // }
            // else if (data.data.length > 0) {
            //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
            //   let newData = JSON.parse(JSON.stringify(data.data));
            //   let totalData = oldData.concat(newData);

            //   _that.setState({ userListing: totalData, blocking: false });
            // } else if (_that.state.searchExist == false) {

            //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
            //   let newData = JSON.parse(JSON.stringify(data.data));
            //   let totalData = oldData.concat(newData);

            //   _that.setState({ userListing: totalData, blocking: false });
            // } else {
            //   _that.setState({ blocking: false, userListing: data.data, page: 1 });
            // }

          })

        } else {
          let responseData = response.json();
          responseData.then(function (data) {

            _that.setState({ blocking: false });

            Alert.error('<h5>' + data.message + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });

    }
  }


  openAddUser() {
    if (this.state.currentSchoolId != 0) {
      this.setState({ showForAddSchool: true });
    } else {
      Alert.error('<h5>' + Constants.Select_School_First + '</h5>', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 2000,
        offset: Constants.OffSetValue
      });

      // toast.error( Constants.Select_School_First, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false
      //   });
    }
  }

  closeForAddUser(event) {
    this.setState({ showForAddSchool: false });
    this.resetAddUser();
  };

  /**** function to handle change for add officer popup ****/
  handleAddOfficerChange(event) {

    // if (event.target.id == 'addUserName') {
    //   this.setState({ addUserName: event.target.value });
    // } else 

    if (event.target.id == 'radio1') {
      this.setState({ radioValue: "MALE" });
    } else if (event.target.id == 'radio2') {
      this.setState({ radioValue: "FEMALE" });
    } else if (event.target.id == 'radio3') {
      this.setState({ radioValue: "OTHER" });
    }

    else if (event.target.id == 'addUserYOB') {
      this.setState({ addUserYOB: event.target.value });
    } else if (event.target.id == 'addUserEmail') {
      this.setState({ addUserEmail: event.target.value });
    } else if (event.target.id == 'addUserPassword') {
      this.setState({ addUserPassword: event.target.value });
    } else if (event.target.id == 'addUName') {
      this.setState({ addUName: event.target.value });
    }
    // else if (event.target.id == 'addUserSchoolId') {
    //   this.setState({ addUserSchoolId: event.target.value });
    // }addUName
    else if (event.target.id == "selectRole") {
      this.setState({ currentSelectRole: event.target.value });
    }
    else {

    }
    return true;
  }

  // reset add user
  resetAddUser() {
    this.setState({
      addUName: "",
      addUserName: "",
      radioValue: "",
      addUserYOB: "",
      addUserEmail: "",
      addUserPassword: "",
      addUName: "",
      password: "",
      confirmPassword: "",
      modalIconStatus: 0,
      date: "",
      currentSelectRole: 0
    })
  }

  // Add Users submit button
  handleSubmit(e) {
    e.preventDefault();
    var _that = this;

    var d = new Date();
    var currentYear = d.getFullYear();

    if (_that.state.addUName.trim() != undefined && _that.state.addUName.trim() != null && _that.state.addUName.trim() != "") {
      if (_that.state.addUserYOB.trim() == "" || (_that.state.addUserYOB.trim() != "" && _that.state.addUserYOB.trim() > 1900 && _that.state.addUserYOB.trim() < currentYear)) {
        if (_that.state.password.trim() == _that.state.confirmPassword.trim()) {

          _that.setState({ blocking: true });

          fetch(Constants.SERVER_URL + "admin/user", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": _that.state.superAdminToken
            },
            body: JSON.stringify({
              name: _that.state.addUName.trim(),
              // user_name: _that.state.addUserName.trim(),
              gender: _that.state.radioValue,
              year_of_birth: Number(_that.state.addUserYOB.trim()),
              email: _that.state.addUserEmail,
              password: _that.state.confirmPassword.trim(),
              account_id: _that.state.currentSchoolId,
              subscription_expiry: _that.state.selectedDate,
              role_id: Number(_that.state.currentSelectRole)
            }),
          }).then(function (response) {

            if (response.status === 200) {
              let responseData = response.json();
              responseData.then(function (data) {

                _that.setState({ blocking: false, showForAddSchool: false });
                _that.resetAddUser();
                _that.userList(_that.state.currentSchoolId);

                Alert.success('<h5>' + "Added successfully" + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 4000,
                  offset: Constants.OffSetValue
                });

              })

            } else if (response.status === 409) {
              let responseData = response.json();
              responseData.then(function (data) {

                _that.setState({ blocking: false });
                // _that.resetAddUser();
                // _that.userList(_that.state.currentSchoolId);

                Alert.error('<h5>' + data.message + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });

              })

            } else {
              let responseData = response.json();
              responseData.then(function (data) {

                _that.resetAddUser();
                _that.setState({ blocking: false, showForAddSchool: false });

                Alert.error('<h5>' + data.message + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }
          }).catch(function (error) {
            _that.setState({ blocking: false, showForAddSchool: false });
            _that.resetAddUser();
            if (navigator.onLine) {
              Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            } else {
              Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            }
          });

        } else {
          Alert.error('<h5>' + "Password should be same" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }

      } else {

        Alert.error('<h5>' + "Please enter correct Year of Birth" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }

    } else {

      Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 3000,
        offset: Constants.OffSetValue
      });
    }
  }


  confirmDeleteUser(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirmpopup custom-ui'>
            <div className="con_header">
              {/* <h1>Confirm to Block tap {tap_number} on plant {this.state.currentPlantName}?</h1> */}
              <h1>Delete User</h1>
            </div>
            <div className="con_body">
              <p>Are you sure, you want to Delete User?</p>
            </div>
            <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
              {/* <button className="nobtn" onClick={() => {this.suru();}}>Cancel</button> */}
              <button onClick={() => {
                this.deleteUser(id);
                // alert("test");
                onClose()
              }}>Delete</button>
            </div>
          </div>
        )
      },
      closeOnClickOutside: false
    })
  };

  // user active/inactive confirm popup
  isActiveAlertPopup(id, status) {
    // console.log(id,"value in popup",status);
    let isActive, header;
    if(status == 3){
      isActive = false;
      header = "Active";
    } else {
      isActive = true;
      header = "Deactive";
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirmpopup custom-ui'>
            <div className="con_header">
              <h1>{header} User</h1>
            </div>
            <div className="con_body">
              <p>Are you sure, you want to {header} this User?</p>
            </div>
            <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
              
              <button onClick={() => {
                this.isActiveUserSubmit(id, isActive);
                // alert("hola");
                onClose()
              }}>{header}</button>
            </div>
          </div>
        )
      },
      closeOnClickOutside: false
    })
  };

  // API function to Active/Deactive user
  isActiveUserSubmit = (id, isActive) => {
    var _that = this;
    let param = {userId: id}
      _that.setState({ blocking: true });
      fetch(Constants.SERVER_URL + "admin/user/activate-deactivate-user", {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": _that.state.superAdminToken
        },
        body:JSON.stringify({
          isActive: isActive,
          userId: id
        })
      }).then(function (response) {

        if (response.status === 204 || response.status === 200) {
          let responseData = response.json();
          responseData.then(function (data) {
          // _that.userList(_that.state.currentSchoolId);
          _that.userList(_that.state.currentSchoolId);
          Alert.success('<h5>' + data.message + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
          })
        } 
        else if (response.status === 401) {
          // let responseData = response.json();
          // responseData.then(function (data) {
          _that.setState({ blocking: false });
          Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });

          // })
        } else {
          let responseData = response.json();
          responseData.then(function (data) {
          _that.setState({ blocking: false });
          Alert.error('<h5>' + data.message + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
          })
        }
      }).catch(function (error) {

        _that.setState({ blocking: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });
   
  }

  // API function to Delete single School
  deleteUser(id) {
    var _that = this;
    if (id) {
      _that.setState({ blocking: true });
      fetch(Constants.SERVER_URL + "admin/user/" + id, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": _that.state.superAdminToken
        }
      }).then(function (response) {

        if (response.status === 204 || response.status === 200) {
          // let responseData = response.json();
          // responseData.then(function (data) {
          _that.userList(_that.state.currentSchoolId);
          Alert.success('<h5>' + "Successfully deleted" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
          // })
        } else if (response.status === 200) {
          // let responseData = response.json();
          // responseData.then(function (data) {
          _that.userList(_that.state.currentSchoolId);
          Alert.success('<h5>' + "Successfully deleted" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
          // })
        }

        else if (response.status === 401) {
          // let responseData = response.json();
          // responseData.then(function (data) {
          _that.setState({ blocking: false });
          Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });

          // })
        } else {
          // let responseData = response.json();
          // responseData.then(function (data) {
          _that.setState({ blocking: false });
          Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
          // })
        }
      }).catch(function (error) {

        _that.setState({ blocking: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });
    } else {
      Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 3000,
        offset: Constants.OffSetValue
      });
    }
  }


  confirmMovePrivate(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirmpopup custom-ui'>
            <div className="con_header">
              {/* <h1>Confirm to Block tap {tap_number} on plant {this.state.currentPlantName}?</h1> */}
              <h1>Move to Private User</h1>
            </div>
            <div className="con_body">
              <p>Are you sure, you want to Move to Private User?</p>
            </div>
            <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
              {/* <button className="nobtn" onClick={() => {this.suru();}}>Cancel</button> */}
              <button onClick={() => {
                this.MovePrivate(id);
                // alert("test");
                onClose()
              }}>Move</button>
            </div>
          </div>
        )
      },
      closeOnClickOutside: false
    })
  };

  // API function to Move to Private User
  MovePrivate(id) {

    var _that = this;
    // if (_that.state.currentSchoolMigrateId != undefined && _that.state.currentSchoolMigrateId != null && _that.state.currentSchoolMigrateId != 0) {

    _that.setState({ blocking: true });

    fetch(Constants.SERVER_URL + "admin/migrate/user", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": _that.state.superAdminToken
      },
      body: JSON.stringify({
        user_id: id,
        // account_id: _that.state.currentSchoolMigrateId,
        migrateTo: "PRIVATE"
      }),
    }).then(function (response) {

      if (response.status === 200) {
        let responseData = response.json();
        responseData.then(function (data) {

          _that.setState({ blocking: false });

          _that.userList(_that.state.currentSchoolId);

          Alert.success('<h5>' + data.message + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 4000,
            offset: Constants.OffSetValue
          });

        })

      } else {
        let responseData = response.json();
        responseData.then(function (data) {

          _that.setState({ blocking: false });

          Alert.error('<h5>' + data.message + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        })
      }
    }).catch(function (error) {
      _that.setState({ blocking: false, showMigratePopup: false });

      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });

    // } else{
    //     Alert.error('<h5>' + "Select School or Organization first" + '</h5>', {
    //         position: 'top-right',
    //         effect: 'slide',
    //         beep: false,
    //         timeout: 3000,
    //         offset: Constants.OffSetValue
    //     });
    // }
  }



  // show function for edit School popup
  showEditSchool(stateData) {

    let editedDate, StartDate;
    if (stateData.subscription_expiry != null) {
      editedDate = stateData.subscription_expiry;

      StartDate = moment(editedDate, "DD/MM/YYYY").valueOf();
      // let newDate = new Date((StartDate/1000));


    } else {
      StartDate = ""
    }

    this.setState({
      show: true,
      currentEditId: stateData.id, editName: stateData.name, editRadio: (stateData.gender).toUpperCase(),
      editEmail: stateData.email, editYOB: stateData.year_of_birth
      , editUsername: stateData.user_name, date: StartDate,
      currentSelectRole: stateData.role_id
    });
  }

  // close function for add state popup
  closeEditSchool(event) {

    this.setState({
      show: false, date: ""
      // , currentIdForAddState: "" 
    });
    this.resetEditUser();
  };

  handleChange(event) {
    event.preventDefault();

    if (event.target.id === "editName") {
      this.setState({ editName: event.target.value });
    } else if (event.target.id === "editRadio1") {
      this.setState({ editRadio: "MALE" });
    } else if (event.target.id === "editRadio2") {
      this.setState({ editRadio: "FEMALE" });
    }

    else if (event.target.id === "editRadio3") {
      this.setState({ editRadio: "OTHER" });
    }

    else if (event.target.id === "editYOB") {
      this.setState({ editYOB: event.target.value });
    } else if (event.target.id === "editEmail") {
      this.setState({ editEmail: event.target.value });
    } else if (event.target.id === "editPass") {
      this.setState({ editPass: event.target.value });
    } else if (event.target.id === "editUsername") {
      this.setState({ editUsername: event.target.value });
    }
    // else if (event.target.id === "insertStateSN") {
    //   this.setState({ insertStateSN: event.target.value });
    // }editUsername
    return true;
  }

  // reset edit user
  resetEditUser() {
    this.setState({
      editName: "",
      editRadio: "",
      editYOB: "",
      editEmail: "",
      editPass: "",

      editPassword: "",
      editConfirmPassword: "",
      modalIconStatus: 0,
      date: "",
      currentSelectRole: 0
    });
  }

  // edit popup submit
  handleEditSubmit(e) {
    e.preventDefault();
    var _that = this;
    if (_that.state.editName.trim() != undefined && _that.state.editName.trim() != null && _that.state.editName.trim() != "") {
      // if(_that.state.editRadio.trim() != undefined && _that.state.editRadio.trim() != null && _that.state.editRadio.trim() != ""){


      _that.setState({ blocking: true });

      fetch(Constants.SERVER_URL + "admin/user/" + _that.state.currentEditId, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": _that.state.superAdminToken
        },
        body: JSON.stringify({
          name: _that.state.editName.trim(),
          // user_name: _that.state.editUsername.trim(),
          gender: _that.state.editRadio,
          year_of_birth: Number(_that.state.editYOB),
          email: _that.state.editEmail,
          // password: _that.state.editConfirmPassword,
          account_id: _that.state.currentSchoolId,
          subscription_expiry: _that.state.selectedDate,
          role_id: Number(_that.state.currentSelectRole)
        }),
      }).then(function (response) {

        if (response.status === 204) {
          // let responseData = response.json();
          // responseData.then(function (data) {
          _that.setState({ blocking: false, show: false });

          _that.userList(_that.state.currentSchoolId);
          _that.resetEditUser();
          Alert.success('<h5>' + "Success" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
          // })
        } else {
          // let responseData = response.json();
          // responseData.then(function (data) {

          _that.setState({ blocking: false, show: false });
          _that.resetEditUser();
          Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
            // });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false, show: false });
        _that.resetEditUser();

        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });

      // } else{

      //     Alert.error('<h5>' + "Address Shouldn't be blank" + '</h5>', {
      //         position: 'top-right',
      //         effect: 'slide',
      //         beep: false,
      //         timeout: 3000,
      //         offset: Constants.OffSetValue
      //     });
      // }

    } else {

      Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 3000,
        offset: Constants.OffSetValue
      });
    }
  }


  // function for upload user file
  uploadHandleChange(event) {
    // event.preventDefault();

    let reader = new FileReader();
    var targetFile = event.target.files[0];
    this.setState({ uploadFile: targetFile });
  }

  // /**** function to handle Bulk memberadd submit event ****/
  bulkUserSubmit() {
    var _that = this;
    if (_that.state.uploadFile != undefined && _that.state.uploadFile != null && _that.state.uploadFile != "") {

      if (_that.state.currentSchoolId != undefined && _that.state.currentSchoolId != null && _that.state.currentSchoolId != "") {
        var formData = new FormData();
        let targetFile = _that.state.uploadFile;

        formData.append("file", targetFile);
        formData.append("account_id", _that.state.currentSchoolId);


        _that.setState({ blocking: true });
        fetch(Constants.SERVER_URL + 'admin/account/import-users', {

          method: 'POST',
          headers: {
            // "Accept": "application/json",
            // "Content-type": "application/json",
            // 'Content-Type': 'multipart/form-data',
            'Authorization': _that.state.token
          },
          body: formData,
        }).then(function (response) {

          if (response.status === 200) {
            // let responseData = response.json();
            // responseData.then(function (data) {
            _that.setState({ blocking: false });
            _that.userList(_that.state.currentSchoolId);

            Alert.success('<h5>' + "Success" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 2000,
              offset: Constants.OffSetValue
            });
            // })

          } else if (response.status === 400) {
            let responseData = response.json();
            responseData.then(function (data) {

              _that.setState({ blocking: false, importDataPopup: true, importFileData: data.data });

              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }

          else {
            let responseData = response.json();
            responseData.then(function (data) {

              _that.setState({ blocking: false });
              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }
        }).catch(function (error) {
          _that.setState({ blocking: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });

      } else {
        Alert.error('<h5>' + Constants.Select_School_First + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }

    } else {
      Alert.error('<h5>' + "Select file first" + '</h5>', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 3000,
        offset: Constants.OffSetValue
      });
    }
  }


  selectState = selectedState => {
    this.setState({ selectedState, schoolData: [], activePage: 1, isMore: false }
      , () => {
        this.userList(selectedState.id);
        this.setState({ currentSchoolId: selectedState.value })
      }
    );
  };

  selectMigrate = selectedMigrate => {
    this.setState({ selectedMigrate, schoolMigrateData: [] }
      , () => {

        this.setState({ currentSchoolMigrateId: selectedMigrate.value })
      }
    );
  };

  handleSelectAuthority(event) {

    if (event.target.id == 'subscribe') {
      this.setState({ selectAuthority: "SCHOOL", userListing: [], selectedState: null, activePage: 1, isMore: false, currentSchoolId: 0 }, () => { this.schoolList() });
    } else if (event.target.id == 'subscribe1') {
      this.setState({ selectAuthority: "ORGANIZATION", userListing: [], selectedState: null, activePage: 1, isMore: false, currentSchoolId: 0 }, () => { this.schoolList() });
    }
    return true;
  }

  handleSelectMigrateAuthority(event) {

    if (event.target.id == 'migrate') {
      this.setState({ selectMigrateAuthority: "SCHOOL", selectedMigrate: null, currentSchoolMigrateId: 0 }, () => { this.schoolMigrateList() });
    } else if (event.target.id == 'migrate1') {
      this.setState({ selectMigrateAuthority: "ORGANIZATION", selectedMigrate: null, currentSchoolMigrateId: 0 }, () => { this.schoolMigrateList() });
    }
    return true;
  }

  /**** function to change form data ****/
  handleChangeForPassword(event) {
    event.preventDefault();

    if (event.target.id === "password") {
      // this.setState({ password: event.target.value });
      if (this.state.confirmPassword == event.target.value && event.target.value.length >= 6) {

        // if(event.target.value > 6){

        this.setState({ password: event.target.value, modalIconStatus: 2 });
        // }
      } else {

        this.setState({ password: event.target.value, modalIconStatus: 0 });
      }
    } else {

      if (this.state.password == event.target.value && event.target.value.length >= 6) {

        // if(event.target.value > 6){

        this.setState({ confirmPassword: event.target.value, modalIconStatus: 2 });
        // }

      } else {

        this.setState({ confirmPassword: event.target.value, modalIconStatus: 1 });
      }

    }
    return true;
  }

  /**** function to change form data ****/
  handleChangeForEditPassword(event) {
    event.preventDefault();

    if (event.target.id === "editPassword") {
      // this.setState({ password: event.target.value });
      if (this.state.editConfirmPassword == event.target.value && event.target.value.length >= 6) {

        // if(event.target.value > 6){

        this.setState({ editPassword: event.target.value, modalIconStatus: 2 });
        // }
      } else {

        this.setState({ editPassword: event.target.value, modalIconStatus: 1 });
      }
    } else {

      if (this.state.editPassword == event.target.value && event.target.value.length >= 6) {

        // if(event.target.value > 6){

        this.setState({ editConfirmPassword: event.target.value, modalIconStatus: 2 });
        // }

      } else {

        this.setState({ editConfirmPassword: event.target.value, modalIconStatus: 1 });
      }

    }
    return true;
  }

  openImportData() {

    this.setState({ importDataPopup: true });
  }

  closeImportData() {
    this.setState({ importDataPopup: false });
  }

  // is more button functionality
  isMoreData() {

    this.setState({ page: this.state.page + 1 }, () => { this.userList() });
  }

  /**** function to search Handle event ****/
  searchHandle(event) {
    if (event.target.id == "search") {
      clearTimeout(this.state.delayTimer);
      this.setState({ search: event.target.value }, () => {
        this.setState({
          delayTimer: setTimeout(function () {
            // this.officerList();
            this.userList(this.state.currentSchoolId);

          }.bind(this), 700),
          activePage: 1
        });
      })
    }
  }

  /**** function to handle change select inputs ****/
  handleChangeSelect(event) {
    if (event.target.id == 'package') {
      this.setState({ selectedValue: event.target.value, activePage: 1 }, () => { this.userList(this.state.currentSchoolId) });
    }
    return true;
  }

  // for pagination
  handlePageChange(pageNumber) {

    // this.setState({activePage: pageNumber});
    this.setState({ activePage: pageNumber }, () => { this.userList(this.state.currentSchoolId) });
  }

  //  /**** function to get date of birth ****/
  // dateChange(date){
  //     console.log("data found>>>>>>>>>",date);
  //   //   alert(date);
  //   let selectedDate = moment(date).format("DD/MM/YYYY");

  //     this.setState({
  //         date: date, selectedDate: selectedDate
  //     }, function(){
  //     let dob = moment(this.state.date).format("DD-MM-YYYY");

  //     // console.log("=====>>>in datechange", selectedDate);
  //       this.setState({day: dob.split("-")[0], month: dob.split("-")[1], year: dob.split("-")[2] });
  //     });
  //   }

  openMigratePopup(userList) {
    this.setState({ showMigratePopup: true, currentUIdMigrate: userList.id });
  }

  closeMigratePopup() {
    this.setState({ showMigratePopup: false, currentUIdMigrate: 0 });
  }


  // Migrate popup submit button
  handleMigrateSubmit(e) {
    e.preventDefault();
    var _that = this;


    if (_that.state.currentSchoolMigrateId != undefined && _that.state.currentSchoolMigrateId != null && _that.state.currentSchoolMigrateId != 0) {


      _that.setState({ blocking: true });

      fetch(Constants.SERVER_URL + "admin/migrate/user", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": _that.state.superAdminToken
        },
        body: JSON.stringify({
          user_id: _that.state.currentUIdMigrate,
          account_id: _that.state.currentSchoolMigrateId,
          account_type: _that.state.selectMigrateAuthority,
          migrateTo: "ACCOUNT"
        }),
      }).then(function (response) {

        if (response.status === 200) {
          let responseData = response.json();
          responseData.then(function (data) {

            _that.setState({ blocking: false, showMigratePopup: false });

            _that.userList(_that.state.currentSchoolId);

            Alert.success('<h5>' + "Migrate successfully" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 4000,
              offset: Constants.OffSetValue
            });

          })

        } else {
          let responseData = response.json();
          responseData.then(function (data) {

            _that.resetAddUser();
            _that.setState({ blocking: false, showMigratePopup: false });

            Alert.error('<h5>' + data.message + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false, showMigratePopup: false });

        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });

    } else {
      Alert.error('<h5>' + "Select School or Organization first" + '</h5>', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 3000,
        offset: Constants.OffSetValue
      });
    }

  }

  // handle checkbox all (Send verification link to all)
  ResendAllCheckbox() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  confirmSendAllUser(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirmpopup custom-ui'>
            <div className="con_header">
              {/* <h1>Confirm to Block tap {tap_number} on plant {this.state.currentPlantName}?</h1> */}
              <h1>User Verification</h1>
            </div>
            <div className="con_body">
              <p>Are you sure you want to send verification link to all users who are not already verified?</p>
            </div>
            <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
              {/* <button className="nobtn" onClick={() => {this.suru();}}>Cancel</button> */}
              <button onClick={() => {
                this.selectAllVerification(id);
                // alert("test");
                onClose()
              }}>send</button>
            </div>
          </div>
        )
      },
      closeOnClickOutside: false
    })
  };

  // API function to send verification link to all (select all)
  selectAllVerification(id) {
    var _that = this;
    // if(id){

    var param = { account_id: _that.state.currentSchoolId }
    _that.setState({ blocking: true });
    fetch(Constants.SERVER_URL + "admin/user/verification-send-all?" + $.param(param), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": _that.state.superAdminToken
      }
    }).then(function (response) {

      if (response.status === 204 || response.status === 200) {
        // let responseData = response.json();
        // responseData.then(function (data) {
        _that.userList(_that.state.currentSchoolId);
        Alert.success('<h5>' + "Successfully send to all user" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });
        // })
      }

      else if (response.status === 401) {
        // let responseData = response.json();
        // responseData.then(function (data) {
        _that.setState({ blocking: false });
        Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });

        // })
      } else {
        // let responseData = response.json();
        // responseData.then(function (data) {
        _that.setState({ blocking: false });
        Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });
        // })
      }
    }).catch(function (error) {

      _that.setState({ blocking: false });
      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });
    // } else{
    //   Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
    //     position: 'top-right',
    //     effect: 'slide',
    //     beep: false,
    //     timeout: 3000,
    //     offset: Constants.OffSetValue
    //   });
    // }
  }


  // IMP code
  // getting user roles
  gettingUserRoles = () => {
    var _that = this;

    var param = { account_id: _that.state.currentSchoolId }
    _that.setState({ blocking: true });
    fetch(Constants.SERVER_URL + "users/role", {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": _that.state.superAdminToken
      }
    }).then(function (response) {

      if (response.status === 204 || response.status === 200) {
        let responseData = response.json();
        responseData.then(function (data) {

          _that.setState({ selectRoleData: data.data.rolesList, blocking: false })

        })
      }

      else if (response.status === 401) {
        // let responseData = response.json();
        // responseData.then(function (data) {
        _that.setState({ blocking: false });
        Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });

        // })
      } else {
        // let responseData = response.json();
        // responseData.then(function (data) {
        _that.setState({ blocking: false });
        Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });
        // })
      }
    }).catch(function (error) {

      _that.setState({ blocking: false });
      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });

  }
  
   // download file api for private users
  downloadUsersFile() {

    var _that = this;

    const { selectedState } = _that.state;

    var param = { type: "USER", account_id: selectedState.id }
 
    _that.setState({ blocking: true });
    fetch(Constants.SERVER_URL + "admin/export-users-data?" + $.param(param), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": _that.state.superAdminToken
      }
    }).then(function (response) {
      if (response.status === 204 || response.status === 200) {
        _that.setState({ blocking: false });
        Alert.success('<h5>' + "Email Sent Successfully" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });
      }

      else if (response.status === 401) {
        _that.setState({ blocking: false });
        Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });
      } else {
        _that.setState({ blocking: false });
        Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });
      }
    }).catch(function (error) {

      _that.setState({ blocking: false });
      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });
  }


  render() {
    const { selectedState, stateOptions, userListing, modalIconStatus, importFileData, genderListData, selectedMigrate, migrateOptions, isChecked, currentSchoolId } = this.state;

    //imp for date picker
    // var date = new Date();

    var getpackage = genderListData.map((list, index) => {
      return (
        <option key={index} value={list.value}>{list.id}</option>
      )
    })

    let modalIcon;
    if (this.state.modalIconStatus == 1) {
      modalIcon = <i className="fa fa-times-circle" aria-hidden="true"></i>;
    } else if (this.state.modalIconStatus == 2) {
      modalIcon = <i className="fa fa-check-circle" aria-hidden="true"></i>
    } else {
      modalIcon = ""
    }


    let isMorebtn;
    if (userListing.length > 0) {
      isMorebtn = <Pagination
        // prevPageText='prev'
        // nextPageText='next'
        // firstPageText='first'
        // lastPageText='last'
        activePage={this.state.activePage}
        itemsCountPerPage={this.state.pageSize}
        totalItemsCount={this.state.finalPagesCount}
        // pageRangeDisplayed={5}
        onChange={this.handlePageChange}
      />
    } else {
      isMorebtn = ""
    }

    const specialChar = (value) => {
      var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      if (!value.match(passw)) {
        return <span className="form-error is-visible">Please add one special character, one uppercase letter, one lowercase letter and one numeric character</span>;
      }
    };

    // const email = (value) => {
    //   if (!isEmail(value)) {
    //     return <span className="form-error is-visible">Please enter a valid Email</span>;
    //   }
    // };

    const required = (value, props) => {
      if (!value || (props.isCheckable && !props.checked)) {
        return <span className="validationForm">Required</span>;
      }
    };

    const minContactNo = (value, props) => {
      if (!isLength(value, { min: 4 })) {
        return <span className="validationForm">Please Enter a Valid Year of Birth</span>;
      }
    };

    const maxContactNo = (value, props) => {
      if (!isLength(value, { max: 4 })) {
        return <span className="validationForm">Please Enter a Valid Year of Birth</span>;
      }
    };

    const minNo = (value, props) => {
      if (!isLength(value, { min: 6 })) {
        return <span className="validationForm">Please Enter Min six character</span>;
      }
    };

    const maxNo = (value, props) => {
      if (!isLength(value, { max: 32 })) {
        return <span className="validationForm">Max password length exceed</span>;
      }
    };

    const number = (value) => {
      if (!isNumeric(value)) {
        return <span className="validationForm">Please Enter a Number</span>;
      }
    };

    const email = (value) => {
      if (!isEmail(value)) {
        return <span className="form-error is-visible">Please enter a valid Email</span>;
      }
    };

    let schoolListing, deletedOn, schoolAlldata, schoolName, gender,registered_at, year_of_birth, userEmail, validationsStatus, timeSpendInApp, schoolId, sendVerificationLink, CompletedTask, userRole, isActive;
    //  subscription_expiry

    schoolListing = userListing.map((school, index) => {

      // if(school.status === 2){
      //   forgetPassStatus = <button type="button" className="btn btn-warning officerCursor">Yes</button>;
      // }else{
      //   forgetPassStatus = <div className="officerStatus">No</div>
      // }

      //   if (school.status == 1) {
      //       orderStatusView = <b className="btn btn-info">Pending</b>;
      //   } else if (school.status == 2) {
      //       orderStatusView = <b className="btn btn-success">Completed</b>;
      //   } else if (school.status == 3) {
      //       orderStatusView = <b className="btn btn-danger">Cancel</b>;
      //   } 

      if (school.id != null) {
        schoolId = school.id;
      } else {
        schoolId = "-";
      }

      if (school.name != null) {
        schoolName = school.name;
      } else {
        schoolName = "-";
      }

      if (school.gender != null && school.gender != "") {
        gender = school.gender;
      } else {
        gender = "-";
      }

      // if(school.subscription_expiry != null && school.subscription_expiry != ""){
      //   subscription_expiry = school.subscription_expiry;
      // } else{
      //     subscription_expiry = "-";
      // }

      if (school.year_of_birth != null) {
        if (school.year_of_birth != 0) {
          year_of_birth = school.year_of_birth;
        } else {
          year_of_birth = "-";
        }
      } else {
        year_of_birth = "-";
      }

      if (school.email != null) {
        userEmail = school.email;
      } else {
        userEmail = "-";
      }

      if (school.registered_at != null) {
        registered_at = moment(Number(school.registered_at)).format("DD/MM/YYYY");
      } else {
        registered_at = "-";
      }

      if (school.expiry_date != null) {
        deletedOn = moment(Number(school.expiry_date)).format("DD/MM/YYYY");
      } else {
        deletedOn = "-";
      }

      if (school.status == 1 || school.status == 3) {
         // Verification Done
        validationsStatus = <Badge  color="success" className="verification-badge">Verified</Badge>;
        sendVerificationLink = "";
      } else if (school.status == 0 ) { // Verify
        validationsStatus =<Badge color="secondary" className="verification-badge not-verified">Not Verified</Badge>;
        sendVerificationLink = <button type="button" title="Send verification link" className="btn btn-secondary"
          onClick={() => { this.varificationRequest(school.id) }}
          data-toggle="modal" data-target="#myModal"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>;

      } else if (school.status == 2 ){ 
        // Requested
        validationsStatus = <Badge color="warning" className="verification-badge requested">Requested</Badge>;
        sendVerificationLink = <button type="button" title="Resend verification link" className="btn btn-secondary"
          onClick={() => { this.varificationRequest(school.id) }}
          data-toggle="modal" data-target="#myModal"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>;

      }

      if (school.timeSpendInApp != null && school.timeSpendInApp != "") {
        timeSpendInApp = school.timeSpendInApp;
      } else {
        timeSpendInApp = "-";
      }

      if (school.task_count != null) {
        CompletedTask = school.task_count;
      } else {
        CompletedTask = "-";
      }

      if (school.user_role != null && school.user_role != undefined) {
        userRole = school.user_role;
      } else {
        userRole = "-";
      }

      if (school.status != null) {
        if (school.status == 3) {
          isActive = <button type="button" class="btn btn-danger" onClick={() => {this.isActiveAlertPopup(school.id, 3)}}>Deactive</button>;
        } else {
          isActive = <button type="button" class="btn btn-success" onClick={() => {this.isActiveAlertPopup(school.id,1)}}>Active</button>;
        }
      } else {
        isActive = "-";
      }

      return (
        <tr key={index} >
          {/* <td><input type="checkbox" className="form-check-input" onChange={this.selectHandleChange} id="singleCheckBox" value={school.email} />Select</td> */}
          <td>{schoolId}</td>
          <td>{schoolName}</td>
          <td>{gender}</td>
          <td>{year_of_birth}</td>
          <td>{userEmail}</td>
          <td>{registered_at}</td>
          <td>{timeSpendInApp}</td>
          <td>{CompletedTask}</td>
          <td>{userRole}</td>
          <td>{validationsStatus}</td>
          <td>{isActive}</td>
          <td>{deletedOn}</td>
          <td><button className="btn btn-danger" title="Delete" onClick={() => { this.confirmDeleteUser(school.id) }}><i className="fa fa-trash" aria-hidden="true"></i></button>
            <button type="button" className="btn btn-info" title="Edit" onClick={() => {
              this.showEditSchool(school);
              this.gettingUserRoles()
            }} data-toggle="modal" data-target="#myModal"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>
            {sendVerificationLink}
            <button type="button" className="btn btn-secondary" title="Move to Private user" onClick={() => { this.confirmMovePrivate(school.id) }}><i className="fa fa-share-square-o" aria-hidden="true"></i></button></td>
          <td><button type="button" className="btn btn-info" onClick={() => { this.openMigratePopup(school); }} data-toggle="modal" data-target="#myModal">Change</button></td>
        </tr>
      )
    })

    if (userListing.length > 0) {
      schoolAlldata = <div className="table-responsive"><table className="table table-striped">
        <thead>
          <tr>
            <th>User Id</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Year Of Birth</th>
            <th>Email</th>
            <th>Registered On</th>
            <th>Spent Time In App <br></br>(HH:MM)</th>
            <th>Completed Task</th>
            <th>Role</th>
            <th>Verification Status</th>
            <th>Status</th>
            <th>Account Delete On</th>

            <th>Actions</th>
            <th>Migrate</th>
            {/*<th>Password</th> */}
          </tr>
        </thead>
        <tbody>
          {schoolListing}
        </tbody>
      </table></div>
    } else {
      schoolAlldata = <h3 className="nodatafound">No data found</h3>
    }

    // table data for import file existing data
    let deviceListingDataListing, field, value, dataMessage;
    deviceListingDataListing = importFileData.map((list, index) => {

      if (list.field != null) {
        field = list.field;
      } else {
        field = "-";
      }

      if (list.value != null && list.value != "") {
        value = list.value;
      } else {
        value = "-";
      }

      if (list.message != null) {
        dataMessage = list.message;
      } else {
        dataMessage = "-";
      }

      return (
        <tr key={index} >
          <td>{field}  </td>
          <td>{value}  </td>
          <td>{dataMessage}  </td>
        </tr>
      )
    })


    // table header for import file existing data
    let deviceListing;
    if (importFileData.length > 0) {
      deviceListing =

        <div className="table-responsive"><table className="table table-striped text-center customScroll">
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
              <th>Message</th>
              {/* <th>OS Version</th>
                      <th>AppView</th> */}

            </tr>
          </thead>
          <tbody>
            {deviceListingDataListing}
          </tbody>
        </table></div>

    } else {
      deviceListing = <h3 className="nodatafound">No data found</h3>
    }


    let selectView;
    if (this.state.selectAuthority == "SCHOOL") {
      selectView = "Select School";
    } else {
      selectView = "Select Organization";
    }

    let selectRole;
    selectRole = this.state.selectRoleData.map((list, index) =>
      (
        <option value={list.id} key={index}>{list.english_translation}</option>
      )
    )

    let allCheckbtn;
    if (isChecked != false) {
      allCheckbtn = <button type="button" className="btn btn-secondary custom_selectAll" onClick={() => { this.confirmSendAllUser() }}>Send</button>;
    } else {
      allCheckbtn = "";
    }

    let allSendView;
    if (currentSchoolId != 0) {
      allSendView = <div className="col-md-4">
        <div>
          {/* <label className="form-check-label">
              <input type="checkbox" className="form-check-input" onChange={this.ResendAllCheckbox} checked={isChecked ? true : false} />All
            </label> */}

          <b className="customMessage">Send verification link to all</b>
          <label className="customContainer">
            <input type="checkbox" className="form-check-input" onChange={this.ResendAllCheckbox} checked={isChecked ? true : false} />All
              <span className="checkmark customCheck"></span>
          </label>
          {allCheckbtn}
        </div>

      </div>
    } else {
      allSendView = ""
    }
    let exportView;
    if (selectedState != null) {
      if (userListing.length > 0) {
        exportView = <div className="col-md-1 text-right"><button type="button" className="btn btn-warning" onClick={() => this.downloadUsersFile()}>Export</button></div>
      }
    }
    else {
      exportView = null;
    }


    return (
      <div className="main_contents">
        <BlockUi blocking={this.state.blocking}>
          {/* <ToastContainer/> */}
          <div className="row titleheader">
            <h1 className="page_title col-md-6">Users</h1>
            <div className="col-md-3 text-right">
              <input type="text" id="search" className="form-control" onChange={this.searchHandle} placeholder="Search.." value={this.state.search} />
            </div>
            <div className="col-md-2 text-right"><button type="button" className="btn btn-dark" onClick={() => {
              this.openAddUser();
              this.gettingUserRoles()
            }}>Add User</button></div>
            {exportView}
          </div>
          <div>
            <div className="row titleheader">
              <div className="col-md-6 header_radiooption">
                <h1 className="page_title">School
                      <input type="radio" id="subscribe" name="gender" onChange={this.handleSelectAuthority} checked={this.state.selectAuthority == "SCHOOL"} />
                  <span className="checkmark"></span>
                </h1>
                <h1 className="page_title"> Organization
                    <input type="radio" id="subscribe1" name="gender" onChange={this.handleSelectAuthority} checked={this.state.selectAuthority == "ORGANIZATION"} />
                  <span className="checkmark"></span>
                </h1>
              </div>
              <div className="col-md-6 uploadcsvbox text-right">
                <p className="powerby">

                  Upload multiple users details in one go: <a href='../../../assets/MTG_Users.xlsx' download>Download Template</a>
                  <span><input type="file" accept=".xls,.xlsx" id="csv" onChange={this.uploadHandleChange} /></span>
                  <button type="button" className="btn btn-dark" onClick={this.bulkUserSubmit}>Import file</button>
                </p>
              </div>
            </div>
          </div>


          <div className="row titleheader">
            {/* <div className="col-md-8 header_radiooption">
                    <h1 className="page_title">School
                      <input type="radio" id="subscribe" name="gender" onChange={this.handleSelectAuthority} checked={this.state.selectAuthority == "SCHOOL"}/>
                      <span className="checkmark"></span>
                      </h1>
                    <h1 className="page_title"> Organization
                    <input type="radio" id="subscribe1" name="gender" onChange={this.handleSelectAuthority} checked={this.state.selectAuthority == "ORGANIZATION"}/> 
                    <span className="checkmark"></span>
                    </h1>
                </div> */}
            <div className="col-md-4">
              <b className="customMessage">Select dropdown to show data</b>
              <Select
                value={selectedState}
                onChange={this.selectState}
                options={stateOptions}
                placeholder={selectView}
              />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              {/* <input type="text" id="search" className="form-control" onChange={this.searchHandle} placeholder="Search.." value={this.state.search} /> */}
              <select
                name="package" className="text form-control" id="package"
                onChange={this.handleChangeSelect}
                value={this.state.selectedValue}
              >
                <option value="">All</option>,
                            {getpackage}
              </select>
            </div>
          </div>

          <div className="row">
            {allSendView}
            {/* <div className="col-md-4">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" onChange={this.ResendAllCheckbox} checked={isChecked ? true : false} />All
                    </label>
                    {allCheckbtn}
                  </div>
                  <b className="customMessage">Send verification link to all</b>
              </div> */}
          </div>

          <Row>
            <Col xs="12" lg="12" className="divright">
              <Card className="mytablesright settingright">
                <CardBody>
                  {schoolAlldata}
                  <div className="loadMoreDiv">
                    {isMorebtn}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ///// */}
          <div className="modal-container">
            <Modal
              show={this.state.showForAddSchool}
              //    onHide={this.close}
              container={this}
              aria-labelledby="contained-modal-title"
            >

              <Form ref={(form) => this.loginFormRef1 = form} className="resetPopup" name="loginForm" onSubmit={this.handleSubmit}>
                <Modal.Header >
                  <Modal.Title id="contained-modal-title">Add User</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="formbox">
                    <label><sup className="required_icon">*</sup> Name </label>
                    <Input type="text" className="form-control" id="addUName" ref="addUName" onChange={this.handleAddOfficerChange} value={this.state.addUName} validations={[required]} />
                  </div>
                  {/* <div className="formbox">
                        <label>UserName </label>
                        <Input type="text" className="form-control" id="addUserName" ref="addUserName" onChange={this.handleAddOfficerChange} value={this.state.addUserName} validations={[required]}/>          
                    </div> */}

                  <div className="formbox">
                    <label>Gender</label>
                    <input type="radio" id="radio1" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == "MALE"} />Male
                            <input type="radio" id="radio2" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == "FEMALE"} />Female
                            <input type="radio" id="radio3" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == "OTHER"} />Other<br></br>

                  </div>

                  <div className="formbox">
                    <label>Year Of Birth</label>
                    <input type="text" className="form-control" id="addUserYOB" ref="addUserYOB" onChange={this.handleAddOfficerChange} value={this.state.addUserYOB} />
                  </div>

                  <div className="formbox">
                    <label>User Role</label>
                    <select className="form-control" id="selectRole" onChange={this.handleAddOfficerChange} value={this.state.currentSelectRole}>
                      <option value={0}>Select</option>,
                            {selectRole}
                    </select>
                  </div>

                  <div className="formbox">
                    <label><sup className="required_icon">*</sup> Email</label>
                    <Input type="text" className="form-control" id="addUserEmail" ref="addUserEmail" onChange={this.handleAddOfficerChange} value={this.state.addUserEmail} validations={[required, email]} />
                  </div>

                  {/* <div className="formbox">
                          <label>Password</label>
                          <Input type="text" className="form-control" id="addUserPassword" ref="addUserPassword" onChange={this.handleAddOfficerChange} value={this.state.addUserPassword} validations={[required, maxNo]}/>
                         </div> */}


                  <div className="formbox">
                    <label><sup className="required_icon">*</sup> Password</label>
                    <Input type="password" className="form-control" id="password" ref="password" onChange={this.handleChangeForPassword} value={this.state.password} validations={[minNo, maxNo, specialChar]} />
                  </div>
                  <div className="formbox">
                    <label><sup className="required_icon">*</sup> Confirm Password</label>
                    <Input type="password" className="form-control" id="confirmPassword" ref="confirmPassword" onChange={this.handleChangeForPassword} value={this.state.confirmPassword} validations={[minNo, maxNo]} />{modalIcon}
                  </div>

                  {/* <div className="formbox">
                            <label>Select Expiry date</label>
                              <DatePicker
                                selected={this.state.date}
                                onChange={this.dateChange}
                                // dateFormat="MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                                // showMonthYearPicker
                                // showMonthDropdown
                                // dropdownMode="select"
                                // showYearDropdown
                                // maxDate={new Date()} //imp
                                // maxDate={(new Date(), 50)}
                                // maxDate={addMonths(new Date(), 1)} //working
                                // dateFormat="MMMM, yyyy"
                                minDate={new Date(date.getFullYear(), date.getMonth(), (date.getDate() + 1))}
                                // maxDate={moment().subtract(1, 'months')}
                                placeholderText="Select a month"
                              />
                        </div> */}

                  {/* <div className="formbox">
                            <label>School Id</label>
                            <Input type="text" className="form-control" id="addUserSchoolId" ref="addUserSchoolId" onChange={this.handleAddOfficerChange} value={this.state.addUserSchoolId} validations={[required]}/>
                        </div> */}
                  {/* <div className="formbox">
                      <label>Officer Designation Id</label>
                            
                    </div>
                    <div className="formbox">
                        <label>Password</label>
                        <Input type="text" className="form-control" id="addUserPassword" ref="addUserPassword" onChange={this.handleAddOfficerChange} value={this.state.addUserPassword} validations={[required, maxNo]}/>
                    </div>
                    
                    <div className="formbox">
                        <label>Username</label>
                        <Input type="text" className="form-control" id="addOfficerUsername" ref="addOfficerUsername" onChange={this.handleAddOfficerChange} value={this.state.addOfficerUsername} validations={[required]}/>
                    </div>
                        <div className="formbox">
                            <label>Active Status :</label>
                            <input type="radio" id="radio1" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == true}/>True
                            <input type="radio" id="radio2" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == false}/>False<br></br>
                        </div> */}
                  {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}


                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
                </Modal.Footer>
              </Form>
              <button className="btn btn-danger" onClick={this.closeForAddUser}>Close</button>
            </Modal>
          </div>
          {/* ////// */}

          <div className="modal-container" style={{ height: 200 }}>
            {/* <Button
                   bsStyle="primary"
                   bsSize="large"
                   onClick={() => this.setState({ show: true })}
               >
                   Launch contained modal
               </Button> */}

            <Modal
              show={this.state.show}
              // onHide={this.close}
              container={this}
              aria-labelledby="contained-modal-title"
            >

              <Form ref={(form) => this.loginFormRef = form} className="resetPopup" name="loginForm" onSubmit={this.handleEditSubmit}>
                <Modal.Header >
                  <Modal.Title id="contained-modal-title">Edit User</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="formbox">
                    <label><sup className="required_icon">*</sup> Name</label>
                    <Input type="text" className="form-control" id="editName" ref="editName" onChange={this.handleChange} value={this.state.editName} validations={[required]} />
                  </div>

                  {/* <div className="formbox">
                        <label>Username</label>
                        <Input type="text" className="form-control" id="editUsername" ref="editUsername" onChange={this.handleChange} value={this.state.editUsername} validations={[required]}/>
                    </div> */}

                  <div className="formbox">
                    <label>Gender</label>
                    <input type="radio" id="editRadio1" name="gender" onChange={this.handleChange} checked={this.state.editRadio == "MALE"} />Male
                            <input type="radio" id="editRadio2" name="gender" onChange={this.handleChange} checked={this.state.editRadio == "FEMALE"} />Female
                            <input type="radio" id="editRadio3" name="gender" onChange={this.handleChange} checked={this.state.editRadio == "OTHER"} />Other<br></br>
                  </div>

                  <div className="formbox">
                    <label>Year Of Birth</label>
                    <input type="text" className="form-control" id="editYOB" ref="editYOB" onChange={this.handleChange} value={this.state.editYOB} />
                  </div>

                  <div className="formbox">
                    <label>User Role</label>
                    <select className="form-control" id="selectRole" onChange={this.handleAddOfficerChange} value={this.state.currentSelectRole}>
                      <option value={0}>Select</option>,
                            {selectRole}
                    </select>
                  </div>

                  <div className="formbox">
                    <label><sup className="required_icon">*</sup> Email</label>
                    <Input type="text" className="form-control" id="editEmail" ref="editEmail" onChange={this.handleChange} value={this.state.editEmail} validations={[required, email]} />
                  </div>


                  {/* <div className="formbox">
                            <label>Select Expiry date</label>
                              <DatePicker
                                selected={this.state.date}
                                onChange={this.dateChange}
                                // dateFormat="MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                                // showMonthYearPicker
                                // showMonthDropdown
                                // dropdownMode="select"
                                // showYearDropdown
                                // maxDate={new Date()} //imp
                                // maxDate={(new Date(), 50)}
                                // maxDate={addMonths(new Date(), 1)} //working
                                // dateFormat="MMMM, yyyy"
                                minDate={new Date(date.getFullYear(), date.getMonth(), (date.getDate() + 1))}
                                // maxDate={moment().subtract(1, 'months')}
                                placeholderText="Select a month"
                              />
                        </div> */}




                  {/* <div className="formbox">
                        <label>Password</label>
                        <Input type="password" className="form-control" id="editPass" ref="editPass" onChange={this.handleChange} value={this.state.editPass} validations={[required]}/>
                    </div> */}

                  {/* IMPORTANT */}
                  {/* <div className="formbox">
                        <label>Password</label>
                        <Input type="text" className="form-control" id="editPassword" ref="password" onChange={this.handleChangeForEditPassword} value={this.state.editPassword} validations={[minNo, maxNo]}/>
                    </div>
                        <div className="formbox">
                            <label>Confirm Password</label>
                            <Input type="text" className="form-control" id="editConfirmPassword" ref="confirmPassword" onChange={this.handleChangeForEditPassword} value={this.state.editConfirmPassword} validations={[minNo, maxNo]}/>{modalIcon}
                        </div> */}

                  {/* <div className="formbox">
                        <label>State Short Name</label>
                        <Input type="text" className="form-control" id="insertStateSN" ref="insertStateSN" onChange={this.handleChange} value={this.state.insertStateSN} validations={[required]}/>
                    </div> */}
                  {/* <div className="formbox">
                        <label>Comfirm Password</label>
                        <Input type="text" className="form-control" id="confirmPassword" ref="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} validations={[minNo, maxNo]}/>{modalIcon}
                    </div> */}
                  {/* <div className="createpassword">
                    <span onClick={this.createPass}>Generate Password:</span>   {this.state.createPass}
                    </div> */}
                  {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
                </Modal.Footer>
              </Form>
              <button className="btn btn-danger" onClick={this.closeEditSchool}>Close</button>
            </Modal>
          </div>

          {/* **** */}
          {/* after import Data error Popup */}
          <div className="modal-container" style={{ height: 200 }}>
            <Modal
              size="lg"
              show={this.state.importDataPopup}
              //    onHide={this.close}
              container={this}
              aria-labelledby="contained-modal-title"
            >

              <Form ref={(form) => this.loginFormRef1 = form} className="resetPopup" name="loginForm" onSubmit={this.closeImportData}>
                <Modal.Header >
                  <Modal.Title id="contained-modal-title">Error</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  {/* <div className="formbox">
                        <label>Name In English</label>
                        <Input type="text" className="form-control" id="englishName" ref="englishName" onChange={this.handleAddOfficerChange} value={this.state.englishName} validations={[required]}/>          
                    </div>
                        <div className="formbox">
                            <label>Name In Hindi</label>
                            <Input type="text" className="form-control" id="hindiName" ref="hindiName" onChange={this.handleAddOfficerChange} value={this.state.hindiName} validations={[required]}/>
                        </div>
                    
                        <div className="formbox">
                            <label>Contact Number</label>
                            <Input type="text" className="form-control" id="contactNo" ref="contactNo" onChange={this.handleAddOfficerChange} value={this.state.contactNo} validations={[required, minContactNo, maxContactNo, number]}/>
                        </div>
                    <div className="formbox">
                      <label>Officer Designation Id</label>
                              <select
                                name="package" className="text form-control" id="packageAdd"
                                ref="packageAdd"
																onChange={this.handleChangeSelectAdd}
                                value={this.state.selectIdAdd}
                                validations={[required]}
															>
																<option value="">Select</option>,
																		{getpackage}
															</select>
                       
                    </div> */}
                  {deviceListing}
                  {/* <div className="formbox">
                        <label>Password</label>
                        <Input type="text" className="form-control" id="addOfficerPassword" ref="addOfficerPassword" onChange={this.handleAddOfficerChange} value={this.state.addOfficerPassword} validations={[required, maxNo]}/>
                    </div>
                    
                    <div className="formbox">
                        <label>Username</label>
                        <Input type="text" className="form-control" id="addOfficerUsername" ref="addOfficerUsername" onChange={this.handleAddOfficerChange} value={this.state.addOfficerUsername} validations={[required]}/>
                    </div>
                        <div className="formbox">
                            <label>Active Status :</label>
                            <input type="radio" id="radio1" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == true}/>True
                            <input type="radio" id="radio2" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == false}/>False<br></br>
                        </div> */}

                  {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}
                </Modal.Body>
                <Modal.Footer>
                  <b className="savebtn btn btn-secondary customeCursor" onClick={this.closeImportData}>Ok</b>
                </Modal.Footer>
              </Form>
              <button className="btn btn-danger" onClick={this.closeImportData}>Close</button>
            </Modal>
          </div>
          {/* /////// */}

          {/* Migrate User popup */}
          <div className="modal-container" style={{ height: 200 }}>
            <Modal
              show={this.state.showMigratePopup}
              // onHide={this.close}
              container={this}
              aria-labelledby="contained-modal-title"
            >

              <Form ref={(form) => this.loginFormRef = form} className="resetPopup" name="loginForm" onSubmit={this.handleMigrateSubmit}>
                <Modal.Header >
                  <Modal.Title id="contained-modal-title">Migrate User</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                  <div className="formbox header_radiooption">
                    <h1 className="page_title">School
                      <input type="radio" id="migrate" name="gender" onChange={this.handleSelectMigrateAuthority} checked={this.state.selectMigrateAuthority == "SCHOOL"} />
                      <span className="checkmark"></span>
                    </h1>
                    <h1 className="page_title"> Organization
                      <input type="radio" id="migrate1" name="gender" onChange={this.handleSelectMigrateAuthority} checked={this.state.selectMigrateAuthority == "ORGANIZATION"} />
                      <span className="checkmark"></span>
                    </h1>
                  </div>

                  <div className="formbox">
                    <Select
                      value={selectedMigrate}
                      onChange={this.selectMigrate}
                      options={migrateOptions}
                      placeholder="Select"
                    />
                  </div>

                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
                </Modal.Footer>
              </Form>
              <button className="btn btn-danger" onClick={this.closeMigratePopup}>Close</button>
            </Modal>
          </div>

          {/* /////// */}
        </BlockUi>
      </div>
    )
  }
}

export default Users;