import React from 'react';
import {
    // Badge,
    ButtonGroup,ButtonToolbar,Card,CardBody,CardTitle,Col,Row,Table,
    // FormGroup,Input,InputGroup,InputGroupAddon
    } from 'reactstrap';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Constants from '../../../config/constants';
import $ from 'jquery'; 

import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';

/* modules for validation */
import { isEmail, isLength, isNumeric, isAlpha } from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'


class Themes extends React.Component{
    constructor(props){
        super(props);
        let superAdminId = localStorage.getItem("superAdminId");
        let superAdminToken = localStorage.getItem("superAdminToken");
        this.state ={
            superAdminId: superAdminId,
            superAdminToken: superAdminToken,
            page: 1,
            pageSize: 10,
            blocking: false,
            showForAddTheme: false,
            addThemeTitle: "",
            radioValue: "",
            addThemeDescription: "",
            addUserEmail: "",
            addUserPassword: "",
            show: false,
            editTitle: "",
            editRadio: "",
            EditDescription: "",
            editEmail: "",
            editPass: "",
            currentSchoolId: 0,
            selectedState: null,
            stateOptions: [],
            themeListing: [],
            currentEditId: 0,
            currentEditThemeId: 0
        }
        // this.schoolList = this.schoolList.bind(this);
        this.openAddThemes = this.openAddThemes.bind(this);
        this.closeForAddOfficer = this.closeForAddOfficer.bind(this);
        this.handleAddOfficerChange = this.handleAddOfficerChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.showEditTheme = this.showEditTheme.bind(this);
        this.closeEditSchool = this.closeEditSchool.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
        this.getThemeListing = this.getThemeListing.bind(this);
        this.resetAddUser = this.resetAddUser.bind(this);
        this.newEditSubmit = this.newEditSubmit.bind(this);
        this.resetEditUser = this.resetEditUser.bind(this);
        
      }


    	/**** Component life cycle method ***/
        componentWillMount() {
            this.getThemeListing(); //get schoolList Details
        }

    // // get school listing
    // schoolList() {
    
    //       var _that = this;
    //       var param = { page_number: 1, page_size: 1000
    //         // , search: _that.state.search.trim(), designationId: _that.state.selectId
    //     }
    //       _that.setState({ blocking: true });
       
    //       fetch(Constants.SERVER_URL + "admin/schools?" + $.param(param), {
    //         method: 'GET', 
    //         headers: {                
    //           "Accept": "application/json",
    //           "Content-type": "application/json",
    //           "Authorization": _that.state.superAdminToken
    //         }
    //       }).then(function (response) {
    
    //         if(response.status === 200){
    //           let responseData = response.json();
    //           responseData.then(function (data) {
    
    //             _that.setState({ blocking: false});
         
    //             // if (data.data.length == _that.state.pageSize) {
    //             //   _that.setState({
    //             //     isMore: true
    //             //   });
    //             // } else {
    //             //   _that.setState({
    //             //     isMore: false
    //             //   });
    //             // }

    //             if (_that.state.page == 1) {
    //                 var resultArray2 = data.data.map(function(obj) {
    //                     var object = obj
    //                     object.label = obj.name;
    //                     object.value = obj.id;
    //                     // delete obj.name;
    //                     // delete obj.id;
    //                     return object
    //                   });
    //               _that.setState({ blocking: false, 
    //                 stateOptions: resultArray2 
    //               });
    
    //             }
    //             else if (data.data.length > 0) {
    //               let oldData = JSON.parse(JSON.stringify(_that.state.stateOptions));
    //               let newData = JSON.parse(JSON.stringify(data.data));
    //               let totalData = oldData.concat(newData);
  
    //               _that.setState({ stateOptions: totalData, blocking: false });
    //             } else if (_that.state.searchExist == false) {
    
    //               let oldData = JSON.parse(JSON.stringify(_that.state.stateOptions));
    //               let newData = JSON.parse(JSON.stringify(data.data));
    //               let totalData = oldData.concat(newData);
  
    //               _that.setState({ stateOptions: totalData, blocking: false });
    //             } else {
    //               _that.setState({ blocking: false, stateOptions: data.data, page: 1 });
    //             }
                
    //           })
            
    //         } else{
    //           let responseData = response.json();
    //           responseData.then(function (data) {
    
    //             _that.setState({ blocking: false});
               
    //             Alert.error('<h5>' + data.message + '</h5>', {
    //               position: 'top-right',
    //               effect: 'slide',
    //               beep: false,
    //               timeout: 3000,
    //               offset: Constants.OffSetValue
    //             });
    //           })
    //         }
    //       }).catch(function (error) {
    //         _that.setState({ blocking: false });
    //         if (navigator.onLine) {
    //           Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
    //             position: 'top-right',
    //             effect: 'slide',
    //             beep: false,
    //             timeout: 3000,
    //             offset: Constants.OffSetValue
    //           });
    //         } else {
    //           Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
    //             position: 'top-right',
    //             effect: 'slide',
    //             beep: false,
    //             timeout: 3000,
    //             offset: Constants.OffSetValue
    //           });
    //         }
    //       });

    // }


     // get user listing
     getThemeListing(school_id) {
        
        var _that = this;
        // var param = { page_number: 1, page_size: 1000, school_id: 0, is_private: true
          // , search: _that.state.search.trim(), designationId: _that.state.selectId
    //   }
        _that.setState({ blocking: true });
     
        fetch(Constants.SERVER_URL + "admin/themes" , {
          method: 'GET', 
          headers: {                
            "Accept": "application/json",
            "Content-type": "application/json",
            "Authorization": _that.state.superAdminToken
          }
        }).then(function (response) {
        
          if(response.status === 200){
            let responseData = response.json();
            responseData.then(function (data) {
            
              _that.setState({ blocking: false});
       
              // if (data.data.length == _that.state.pageSize) {
              //   _that.setState({
              //     isMore: true
              //   });
              // } else {
              //   _that.setState({
              //     isMore: false
              //   });
              // }

              if (_that.state.page == 1) {
                 
                _that.setState({ blocking: false, 
                  themeListing: data.data 
                });
              }
              else if (data.data.length > 0) {
                let oldData = JSON.parse(JSON.stringify(_that.state.themeListing));
                let newData = JSON.parse(JSON.stringify(data.data));
                let totalData = oldData.concat(newData);

                _that.setState({ themeListing: totalData, blocking: false });
              } else if (_that.state.searchExist == false) {
                
                let oldData = JSON.parse(JSON.stringify(_that.state.themeListing));
                let newData = JSON.parse(JSON.stringify(data.data));
                let totalData = oldData.concat(newData);

                _that.setState({ themeListing: totalData, blocking: false });
              } else {
                _that.setState({ blocking: false, themeListing: data.data, page: 1 });
              }
              
            })
          
          } else{
            let responseData = response.json();
            responseData.then(function (data) {
              
              _that.setState({ blocking: false});
             
              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }
        }).catch(function (error) {
          _that.setState({ blocking: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });

  }


    openAddThemes(){
      
      // if(this.state.currentSchoolId != 0){
      this.setState({showForAddTheme: true });
    //   } else {
    //     Alert.error('<h5>' + Constants.Select_School_First + '</h5>', {
    //       position: 'top-right',
    //       effect: 'slide',
    //       beep: false,
    //       timeout: 2000,
    //       offset: Constants.OffSetValue
    //     });
    //   }
    }


    closeForAddOfficer(event){
      
      this.setState({ showForAddTheme: false});
      this.resetAddUser();
    };


     /**** function to handle change for add officer popup ****/
     handleAddOfficerChange(event) {
      
      if (event.target.id == 'addThemeTitle') {
        this.setState({ addThemeTitle: event.target.value });
      } else if (event.target.id == 'radio1') {
        this.setState({ radioValue: "MALE" });
      } else if (event.target.id == 'radio2') {
        this.setState({ radioValue: "FEMALE" });
      } else if (event.target.id == 'addThemeDescription') {
        this.setState({ addThemeDescription: event.target.value });
      } else if (event.target.id == 'addUserEmail') {
        this.setState({ addUserEmail: event.target.value });
      } else if (event.target.id == 'addUserPassword') {
        this.setState({ addUserPassword: event.target.value });
      } 
      // else if (event.target.id == 'addUserSchoolId') {
      //   this.setState({ addUserSchoolId: event.target.value });
      // }
       else {
        
      }
      return true;
    }

    
    resetAddUser(){
      this.setState({
        addThemeTitle: "",
        addThemeDescription: ""
      })
    }


     // Add Users submit button
     handleSubmit(e){
      e.preventDefault();
      var _that = this;
      if (_that.state.addThemeTitle.trim() != undefined && _that.state.addThemeTitle.trim() != null && _that.state.addThemeTitle.trim() != "") {
          if(_that.state.addThemeDescription.trim() != undefined && _that.state.addThemeDescription.trim() != null && _that.state.addThemeDescription.trim() != ""){
      
      
      _that.setState({ blocking: true });
              
      fetch(Constants.SERVER_URL + "admin/theme", {
          method: 'POST', 
          headers: {                
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": _that.state.superAdminToken
          },
          body:JSON.stringify({
            title: _that.state.addThemeTitle.trim(),
            // gender: _that.state.radioValue,
            description: _that.state.addThemeDescription.trim(),
            // email: _that.state.addUserEmail,
            // password: _that.state.addUserPassword.trim(),
            // school_id: 0,
            // is_private: true
          }),
        }).then(function (response) {
        
          if(response.status === 200){
            let responseData = response.json();
            responseData.then(function (data) {
             
              _that.setState({ blocking: false,  showForAddTheme: false});
              _that.resetAddUser();
              _that.getThemeListing();
              
              Alert.success('<h5>' + Constants.SUCESSFULLY_ADD + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 4000,
                offset: Constants.OffSetValue
              });
              
            })
          
          } else{
            let responseData = response.json();
            responseData.then(function (data) {
              
              _that.resetAddUser();
              _that.setState({ blocking: false,  showForAddTheme: false});
             
              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }
        }).catch(function (error) {
          _that.setState({ blocking: false,  showForAddTheme: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });
  
      } else{
          
          Alert.error('<h5>' + "Description Shouldn't be blank" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
  
      } else{
          
          Alert.error('<h5>' + "Title Shouldn't be blank" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
    }


    confirmDeleteUser(id) {
        
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='confirmpopup custom-ui'>
              <div className="con_header">
              {/* <h1>Confirm to Block tap {tap_number} on plant {this.state.currentPlantName}?</h1> */}
                <h1>Delete Theme</h1>
              </div>
              <div className="con_body">
                <p>Are you sure, you want to Delete this Theme?</p>
              </div>
              <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
                {/* <button className="nobtn" onClick={() => {this.suru();}}>Cancel</button> */}
                <button onClick={() => {
                  this.deleteUser(id);
                  // alert("test");
                  onClose()
                }}>Delete</button>
              </div>
            </div>
          )
        },
        closeOnClickOutside: false
      })
    };

    // API function to Delete single School
    deleteUser(id){
    var _that = this;
    if(id){
        _that.setState({ blocking: true });
        fetch(Constants.SERVER_URL + "admin/theme/" + id  , {
          method: 'DELETE',
          headers: {                
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": _that.state.superAdminToken
          }
        }).then(function (response) {
       
          if(response.status === 204){
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.getThemeListing(_that.state.currentSchoolId);
              Alert.success('<h5>' + "Successfully deleted" + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
            // })
          } else if(response.status === 401){
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.setState({ blocking: false});
              Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
              
            // })
          } else{
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.setState({ blocking: false});
              Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
            // })
          }
        }).catch(function (error) {
          
          _that.setState({ blocking: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });
      } else{
        Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
  }



    // show function for edit School popup
    showEditTheme(stateData){
      
      this.setState({show: true, 
        editTitle: stateData.title, EditDescription: stateData.description,
        currentEditThemeId: stateData.id
        // , editRadio: stateData.gender,
        // editEmail: stateData.email, EditDescription: stateData.year_of_birth
      });
    }

    // close function for add state popup
    closeEditSchool(event){
      
      this.setState({ show: false
        // , currentIdForAddState: "" 
      });
      // this.resetModalForm();
      this.resetEditUser();
    };

    handleChange(event) {
      event.preventDefault();
      
      if (event.target.id === "editTitle") {
        this.setState({ editTitle: event.target.value });
      } else if (event.target.id === "editRadio1") {
        this.setState({ editRadio: "MALE" });
      }  else if (event.target.id === "editRadio2") {
        this.setState({ editRadio: "FEMALE" });
      }  else if (event.target.id === "EditDescription") {
        this.setState({ EditDescription: event.target.value });
      }  else if (event.target.id === "editEmail") {
        this.setState({ editEmail: event.target.value });
      } else if (event.target.id === "editPass") {
        this.setState({ editPass: event.target.value });
      } 
      // else if (event.target.id === "insertStateSN") {
      //   this.setState({ insertStateSN: event.target.value });
      // }
      return true;
  } 

  // edit popup submit
  handleEditSubmit(event){
    
    event.preventDefault();
    var _that = this;
    // if (_that.state.editTitle.trim() != undefined && _that.state.editTitle.trim() != null && _that.state.editTitle.trim() != "") {
        // if(_that.state.editRadio != undefined && _that.state.editRadio != null && _that.state.editRadio != ""){
    
    
    _that.setState({ blocking: true });
            
    fetch(Constants.SERVER_URL + "admin/user/" + _that.state.currentEditId, {
        method: 'PUT', 
        headers: {                
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": _that.state.superAdminToken
        },
        body:JSON.stringify({
          user_name: _that.state.editTitle.trim(),
            gender: _that.state.editRadio,
            year_of_birth: Number(_that.state.EditDescription),
            email: _that.state.editEmail,
            password: _that.state.editPass,
            school_id: 0,
            role_id: 0,
            is_private: true
        }),
      }).then(function (response) {
      
        if(response.status === 204){
          // let responseData = response.json();
          // responseData.then(function (data) {
           
            _that.setState({ blocking: false,  show: false});
           
            _that.getThemeListing(_that.state.currentSchoolId);

            Alert.success('<h5>' + "Success" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 2000,
              offset: Constants.OffSetValue
            });
            
          // })
        
        } else{
          // let responseData = response.json();
          // responseData.then(function (data) {
            
            _that.setState({ blocking: false,  show: false});
           
            Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            // });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false,  show: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });

    // } 
    // else{
    
    //     Alert.error('<h5>' + "Address Shouldn't be blank" + '</h5>', {
    //         position: 'top-right',
    //         effect: 'slide',
    //         beep: false,
    //         timeout: 3000,
    //         offset: Constants.OffSetValue
    //     });
    // }

    // } else{
    
    //     Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
    //         position: 'top-right',
    //         effect: 'slide',
    //         beep: false,
    //         timeout: 3000,
    //         offset: Constants.OffSetValue
    //     });
    // }
  }


  resetEditUser(){
    this.setState({
    editTitle: "",
    editRadio: "",
    EditDescription: "",
    editEmail: "",
    editPass: ""});
  }


  newEditSubmit(event){
    
    event.preventDefault();
    var _that = this;
    _that.setState({ blocking: true });
            
    fetch(Constants.SERVER_URL + "admin/theme/" + _that.state.currentEditThemeId, {
        method: 'PUT', 
        headers: {                
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": _that.state.superAdminToken
        },
        body:JSON.stringify({
            title: _that.state.editTitle.trim(),
            description: _that.state.EditDescription.trim()
        }),
      }).then(function (response) {
      
        if(response.status === 204){
          // let responseData = response.json();
          // responseData.then(function (data) {
           
            _that.setState({ blocking: false,  show: false});
            // _that.resetEditUser();
            _that.getThemeListing();

            Alert.success('<h5>' + Constants.SUCESSFULLY_UPDATE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 2000,
              offset: Constants.OffSetValue
            });
            
          // })
        
        } else{
          // let responseData = response.json();
          // responseData.then(function (data) {
            
            _that.setState({ blocking: false,  show: false});
            _that.resetEditUser();
            Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            // });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false,  show: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });

  }

    selectState = selectedState => {
        this.setState({ selectedState }
            , () => { 
                this.getThemeListing(selectedState.id); 
            this.setState({ currentSchoolId: selectedState.value}) 
            }
            );
        
    };

    render(){
      
        const { selectedState, stateOptions, themeListing } = this.state;
        

        const required = (value, props) => {
          if (!value || (props.isCheckable && !props.checked)) {
            return <span className="validationForm">Required</span>;
          }
        };

        const minContactNo = (value, props) => {
          if (!isLength(value, { min: 10 })) {
            return <span className="validationForm">Please Enter a Valid Contact Number</span>;
          }
        };
    
        const maxContactNo = (value, props) => {
            if (!isLength(value, { max: 10 })) {
                return <span className="validationForm">Please Enter a Valid Contact Number</span>;
            }
        };
    
        const minNo = (value, props) => {
            if (!isLength(value, { min: 6 })) {
              return <span className="validationForm">Please Enter Min six character</span>;
            }
        };
    
        const maxNo = (value, props) => {
            if (!isLength(value, { max: 32 })) {
                return <span className="validationForm">Max password length exceed</span>;
            }
        };
    
        const number = (value) => {
          if (!isNumeric(value)) {
            return <span className="validationForm">Please Enter a Number</span>;
          }
        };

        const email = (value) => {
          if (!isEmail(value)) {
            return <span className="form-error is-visible">Please enter a valid Email</span>;
          }
        };

        let schoolListing, schoolAlldata, listTitle, listDescription, year_of_birth, userEmail;
        schoolListing = themeListing.map((list, index) => {

            // if(list.status === 2){
            //   forgetPassStatus = <button type="button" className="btn btn-warning officerCursor">Yes</button>;
            // }else{
            //   forgetPassStatus = <div className="officerStatus">No</div>
            // }
  
                    //   if (list.status == 1) {
                    //       orderStatusView = <b className="btn btn-info">Pending</b>;
                    //   } else if (list.status == 2) {
                    //       orderStatusView = <b className="btn btn-success">Completed</b>;
                    //   } else if (list.status == 3) {
                    //       orderStatusView = <b className="btn btn-danger">Cancel</b>;
                    //   } 
                      
                      if(list.title != null){
                        listTitle = list.title;
                      } else{
                        listTitle = "-";
                      }
                      
                      if(list.description != null){
                        listDescription = list.description;
                      } else{
                          listDescription = "-";
                      }
  
                    //   if(list.year_of_birth != null){
                    //       year_of_birth = list.year_of_birth;
                    //   }else{
                    //       year_of_birth = "-";
                    //   }

                    //     if(list.email != null){
                    //         userEmail = list.email;
                    //     }else{
                    //         userEmail = "-";
                    //     }

                      return (
                          <tr key={index} >
                              <td>{listTitle}</td>
                              <td>{listDescription}</td>
                              {/* <td>{year_of_birth}</td>
                              <td>{userEmail}</td> */}
                            {/* <td>{forgetPassStatus}</td>*/}
                            <td><button className="btn btn-danger" onClick={() => {this.confirmDeleteUser(list.id)}}>Delete</button>
                            <button type="button" className="btn btn-info" onClick={() => {this.showEditTheme(list)}} data-toggle="modal" data-target="#myModal">Edit</button></td> 
                          </tr>
                      )
                  })

          if(themeListing.length > 0){
              schoolAlldata =	<div className="table-responsive"><table className="table table-striped">
              <thead>
                  <tr>
                      <th>Title</th>
                      <th>Description</th>
                      {/* <th>Year Of Birth</th>
                      <th>Email</th> */}
                      <th>Actions</th>
                        {/* <th>Forget Password Request</th> 
                        <th>Password</th> */}
                  </tr>
              </thead>
              <tbody>
              {schoolListing}
              </tbody>
            </table></div>
          } else{
              schoolAlldata = <h3 className="nodatafound">No data found</h3>
          }
        return(
            <div className="main_contents">
              <div className="row titleheader">
                <h1 className="page_title col-md-6">Themes</h1>
                {/* <div className="col-md-4">
                <Select
                    value={selectedState}
                    onChange={this.selectState}
                    options={stateOptions}
                    placeholder="Select School"
                />
                </div> */}

                <div className="col-md-6 text-right"><button type="button" className="btn btn-dark" onClick = {this.openAddThemes}>Add Themes</button></div>
              </div>
            
            <Row>
              <Col xs="12" lg="12" className="divright">
                <Card className="mytablesright settingright">
                  <CardBody>
                    {schoolAlldata}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* ///// */}
            <div className="modal-container" style={{ height: 200 }}>
               <Modal
                   show={this.state.showForAddTheme}
                //    onHide={this.close}
                   container={this}
                   aria-labelledby="contained-modal-title"
               >
               
            
                    <Form ref={(form) => this.loginFormRef1 = form} className="resetPopup" name="loginForm" onSubmit={this.handleSubmit}>
                   <Modal.Header >
                       <Modal.Title id="contained-modal-title">Add Theme</Modal.Title>
                   </Modal.Header>

                   <Modal.Body>
                    <div className="formbox">
                        <label>Title</label>
                        <Input type="text" className="form-control" id="addThemeTitle" ref="addThemeTitle" onChange={this.handleAddOfficerChange} value={this.state.addThemeTitle} validations={[required]}/>          
                    </div>

                        {/* <div className="formbox">
                            <label>Gender</label>
                            <input type="radio" id="radio1" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == "MALE"}/>Male
                            <input type="radio" id="radio2" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == "FEMALE"}/>Female<br></br>
                        </div> */}

                        <div className="formbox">
                            <label>Description</label>
                            <Input type="text" className="form-control" id="addThemeDescription" ref="addThemeDescription" onChange={this.handleAddOfficerChange} value={this.state.addThemeDescription} validations={[required]}/>
                        </div>
                    
                        {/* <div className="formbox">
                            <label>Email</label>
                            <Input type="text" className="form-control" id="addUserEmail" ref="addUserEmail" onChange={this.handleAddOfficerChange} value={this.state.addUserEmail} validations={[required, email]}/>
                        </div>

                        <div className="formbox">
                          <label>Password</label>
                          <Input type="text" className="form-control" id="addUserPassword" ref="addUserPassword" onChange={this.handleAddOfficerChange} value={this.state.addUserPassword} validations={[required, maxNo]}/>
                         </div> */}

                        {/* <div className="formbox">
                            <label>School Id</label>
                            <Input type="text" className="form-control" id="addUserSchoolId" ref="addUserSchoolId" onChange={this.handleAddOfficerChange} value={this.state.addUserSchoolId} validations={[required]}/>
                        </div> */}
                    {/* <div className="formbox">
                      <label>Officer Designation Id</label>
                            
                    </div>
                    <div className="formbox">
                        <label>Password</label>
                        <Input type="text" className="form-control" id="addUserPassword" ref="addUserPassword" onChange={this.handleAddOfficerChange} value={this.state.addUserPassword} validations={[required, maxNo]}/>
                    </div>
                    
                    <div className="formbox">
                        <label>Username</label>
                        <Input type="text" className="form-control" id="addOfficerUsername" ref="addOfficerUsername" onChange={this.handleAddOfficerChange} value={this.state.addOfficerUsername} validations={[required]}/>
                    </div>
                        <div className="formbox">
                            <label>Active Status :</label>
                            <input type="radio" id="radio1" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == true}/>True
                            <input type="radio" id="radio2" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == false}/>False<br></br>
                        </div> */}
                    {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}
                   </Modal.Body>
                   <Modal.Footer>
                      <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
                  </Modal.Footer>
                  </Form>
                  <button className="btn btn-danger" onClick={this.closeForAddOfficer}>Close</button>
               </Modal>
           </div>
            {/* ////// */}

            <div className="modal-container" style={{ height: 200 }}>
               {/* <Button
                   bsStyle="primary"
                   bsSize="large"
                   onClick={() => this.setState({ show: true })}
               >
                   Launch contained modal
               </Button> */}

               <Modal
                   show={this.state.show}
                //    onHide={this.close}
                   container={this}
                   aria-labelledby="contained-modal-title"
               >
               
                    <Form ref={(form) => this.loginFormRef = form} className="resetPopup" name="loginForm" onSubmit={this.newEditSubmit}>
                   <Modal.Header >
                       <Modal.Title id="contained-modal-title">Edit Theme</Modal.Title>
                   </Modal.Header>

                   <Modal.Body>
                    <div className="formbox">
                        <label>Title</label>
                        <Input type="text" className="form-control" id="editTitle" ref="editTitle" onChange={this.handleChange} value={this.state.editTitle} validations={[required]}/>
                    </div>

                    {/* <div className="formbox">
                            <label>Gender</label>
                            <input type="radio" id="editRadio1" name="gender" onChange={this.handleChange} checked={this.state.editRadio == "MALE"}/>Male
                            <input type="radio" id="editRadio2" name="gender" onChange={this.handleChange} checked={this.state.editRadio == "FEMALE"}/>Female<br></br>
                    </div> */}
                    
                    <div className="formbox">
                        <label>Description</label>
                        <Input type="text" className="form-control" id="EditDescription" ref="EditDescription" onChange={this.handleChange} value={this.state.EditDescription} validations={[required]}/>
                    </div>
                    {/* <div className="formbox">
                        <label>Email</label>
                        <Input type="text" className="form-control" id="editEmail" ref="editEmail" onChange={this.handleChange} value={this.state.editEmail} validations={[required]}/>
                    </div>
                    <div className="formbox">
                        <label>Password</label>
                        <Input type="password" className="form-control" id="editPass" ref="editPass" onChange={this.handleChange} value={this.state.editPass} validations={[required]}/>
                    </div> */}
                    
                    {/* <div className="formbox">
                        <label>State Short Name</label>
                        <Input type="text" className="form-control" id="insertStateSN" ref="insertStateSN" onChange={this.handleChange} value={this.state.insertStateSN} validations={[required]}/>
                    </div> */}
                        {/* <div className="formbox">
                            <label>Comfirm Password</label>
                            <Input type="text" className="form-control" id="confirmPassword" ref="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} validations={[minNo, maxNo]}/>{modalIcon}
                        </div> */}

                    {/* <div className="createpassword">
                    <span onClick={this.createPass}>Generate Password:</span>   {this.state.createPass}
                    </div> */}
                    {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}
                   </Modal.Body>

                   <Modal.Footer>
                      
                   
                      <button type="submit" className="savebtn btn btn-secondary">Save</button>
                  </Modal.Footer>
                  </Form>
                  <button className="btn btn-danger" onClick={this.closeEditSchool}>Close</button>
               </Modal>
           </div>



              
          </div>
        )
    }
}

export default Themes;