import React from 'react';
import {
    // Badge,
    ButtonGroup,ButtonToolbar,Card,CardBody,CardTitle,Col,Row,Table,
    // FormGroup,Input,InputGroup,InputGroupAddon
    } from 'reactstrap';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Constants from '../../../config/constants';
import $ from 'jquery'; 
import BlockUi from 'react-block-ui';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';

/* modules for validation */
import { isEmail, isLength, isNumeric, isAlpha } from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

class Task extends React.Component{
    constructor(props){
        super(props);
        let superAdminId = localStorage.getItem("superAdminId");
        let superAdminToken = localStorage.getItem("superAdminToken");

        this.state ={
            superAdminId: superAdminId,
            superAdminToken: superAdminToken,
            page: 1,
            search: "",
            delayTimer: 0,
            isMore: false,
            searchExist: false,
            blocking: false,
            showForAddSchool: false,
            addUserName: "",
            radioValue: "",
            addUserYOB: "",
            addUserEmail: "",
            addUserPassword: "",
            show: false,
            editName: "",
            editRadio: "",
            editYOB: "",
            editEmail: "",
            editPass: "",
            currentSchoolId: 0,
            selectedTheme: null,
            themeOptions: [],
            userListing: [],
            currentEditId: 0,
            uploadFile: "",
            selectedProgram: null,
            programOptions: [],
            currentProgramId: 0,

            activePage: 1,
            pageSize: 10,
            finalPagesCount: 1
        }
        this.themeList = this.themeList.bind(this);
        this.openAddOfficer = this.openAddOfficer.bind(this);
        this.closeForAddOfficer = this.closeForAddOfficer.bind(this);
        this.handleAddOfficerChange = this.handleAddOfficerChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.showEditSchool = this.showEditSchool.bind(this);
        this.closeEditSchool = this.closeEditSchool.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
        this.userList = this.userList.bind(this);
        this.resetAddUser = this.resetAddUser.bind(this);
        this.resetEditUser = this.resetEditUser.bind(this);
        this.uploadHandleChange = this.uploadHandleChange.bind(this);
        this.getProgramListing = this.getProgramListing.bind(this);
        // this.isMoreData = this.isMoreData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
      }


    /**** Component life cycle method ***/
    componentWillMount() {
        this.userList(); //get themeList Details
    }

    // componentDidMount() {
    //   window.scrollTo(0, 0)
    // }

      // get user listing
      userList(school_id) {
        
        var _that = this;
        var param = { page_number: _that.state.activePage, page_size: _that.state.pageSize, search_text: _that.state.search.trim()
          // account_type: "SCHOOL"
          // , search: _that.state.search.trim(), designationId: _that.state.selectId
      }
        _that.setState({ blocking: true });
     
        fetch(Constants.SERVER_URL + "admin/task?" + $.param(param), {
          method: 'GET', 
          headers: {                
            "Accept": "application/json",
            "Content-type": "application/json",
            "Authorization": _that.state.superAdminToken
          }
        }).then(function (response) {
        
          if(response.status === 200){
            let responseData = response.json();
            responseData.then(function (data) {
            
              _that.setState({ blocking: false});
       
              // if (data.data.length == _that.state.pageSize) {
              //   _that.setState({
              //     isMore: true
              //   });
              // } else {
              //   _that.setState({
              //     isMore: false
              //   });
              // }

              // if (_that.state.page == 1) {
                _that.setState({ blocking: false, 
                  userListing: data.data, finalPagesCount: data.totalTasks  
                });
              // }
              // else if (data.data.length > 0) {
              //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
              //   let newData = JSON.parse(JSON.stringify(data.data));
              //   let totalData = oldData.concat(newData);

              //   _that.setState({ userListing: totalData, blocking: false });
              // } else if (_that.state.searchExist == false) {
                
              //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
              //   let newData = JSON.parse(JSON.stringify(data.data));
              //   let totalData = oldData.concat(newData);

              //   _that.setState({ userListing: totalData, blocking: false });
              // } else {
              //   _that.setState({ blocking: false, userListing: data.data, page: 1 });
              // }
              
            })
          
          } else{
            let responseData = response.json();
            responseData.then(function (data) {
              
              _that.setState({ blocking: false});
             
              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }
        }).catch(function (error) {
          _that.setState({ blocking: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });

  }

    // get theme listing
    themeList() {
          var _that = this;
          // var param = { page_number: 1, page_size: 1000, account_type: "SCHOOL"
            // , search: _that.state.search.trim(), designationId: _that.state.selectId
          // }
          _that.setState({ blocking: true });
       
          fetch(Constants.SERVER_URL + "admin/themes", {
            method: 'GET', 
            headers: {                
              "Accept": "application/json",
              "Content-type": "application/json",
              "Authorization": _that.state.superAdminToken
            }
          }).then(function (response) {
            
            if(response.status === 200){
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
         
                // if (data.data.length == _that.state.pageSize) {
                //   _that.setState({
                //     isMore: true
                //   });
                // } else {
                //   _that.setState({
                //     isMore: false
                //   });
                // }

                if (_that.state.page == 1) {
                    var resultArray2 = data.data.map(function(obj) {
                        var object = obj
                        object.label = obj.title;
                        object.value = obj.id;
                        // delete obj.name;
                        // delete obj.id;
                        return object
                      });
                  _that.setState({ blocking: false, 
                    themeOptions: resultArray2 
                  });
                  
                }
                else if (data.data.length > 0) {
                  let oldData = JSON.parse(JSON.stringify(_that.state.themeOptions));
                  let newData = JSON.parse(JSON.stringify(data.data));
                  let totalData = oldData.concat(newData);
  
                  _that.setState({ themeOptions: totalData, blocking: false });
                } else if (_that.state.searchExist == false) {
                  
                  let oldData = JSON.parse(JSON.stringify(_that.state.themeOptions));
                  let newData = JSON.parse(JSON.stringify(data.data));
                  let totalData = oldData.concat(newData);
  
                  _that.setState({ themeOptions: totalData, blocking: false });
                } else {
                  _that.setState({ blocking: false, themeOptions: data.data, page: 1 });
                }
                
              })
            
            } else{
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
               
                Alert.error('<h5>' + data.message + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }
          }).catch(function (error) {
            _that.setState({ blocking: false });
            if (navigator.onLine) {
              Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            } else {
              Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            }
          });
    }

 
  // get Program listing
  getProgramListing(selectedThemeId) {
    
    var _that = this;
    var param = { page_number: _that.state.page, page_size: _that.state.pageSize, theme_id: selectedThemeId
      // , search: _that.state.search.trim(), designationId: _that.state.selectId
    }
    _that.setState({ blocking: true });
 
    fetch(Constants.SERVER_URL + "admin/programs?" + $.param(param) , {
      method: 'GET', 
      headers: {                
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": _that.state.superAdminToken
      }
    }).then(function (response) {
    
      if(response.status === 200){
        let responseData = response.json();
        responseData.then(function (data) {
        
          _that.setState({ blocking: false});
   



          var resultArray2 = data.data.map(function(obj) {
            var object = obj
            object.label = obj.title;
            object.value = obj.id;
            // delete obj.name;
            // delete obj.id;
            return object
          });
          _that.setState({ blocking: false, 
            programOptions: resultArray2 
          });





          // if (_that.state.page == 1) {
          //   _that.setState({ blocking: false, 
          //     programListing: data.data 
          //   });
          // }
          // else if (data.data.length > 0) {
          //   let oldData = JSON.parse(JSON.stringify(_that.state.programListing));
          //   let newData = JSON.parse(JSON.stringify(data.data));
          //   let totalData = oldData.concat(newData);
          //   _that.setState({ programListing: totalData, blocking: false });
          // } else if (_that.state.searchExist == false) {
          
          //   let oldData = JSON.parse(JSON.stringify(_that.state.programListing));
          //   let newData = JSON.parse(JSON.stringify(data.data));
          //   let totalData = oldData.concat(newData);
          //   _that.setState({ programListing: totalData, blocking: false });
          // } else {
          //   _that.setState({ blocking: false, themeOptions: data.data, page: 1 });
          // }
          
        })
      
      } else{
        let responseData = response.json();
        responseData.then(function (data) {
          
          _that.setState({ blocking: false});
         
          Alert.error('<h5>' + data.message + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        })
      }
    }).catch(function (error) {
      _that.setState({ blocking: false });
      if (navigator.onLine) {
        Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      } else {
        Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
    });
}

    openAddOfficer(){
      
      if(this.state.currentSchoolId != 0){
      this.setState({showForAddSchool: true });
      } else {
        Alert.error('<h5>' + Constants.Select_School_First + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 2000,
          offset: Constants.OffSetValue
        });
      }
    }

    closeForAddOfficer(event){
      
      this.setState({ showForAddSchool: false});
      this.resetAddUser();
    };

     /**** function to handle change for add officer popup ****/
     handleAddOfficerChange(event) {
      
      if (event.target.id == 'addUserName') {
        this.setState({ addUserName: event.target.value });
      } else if (event.target.id == 'radio1') {
        this.setState({ radioValue: "MALE" });
      } else if (event.target.id == 'radio2') {
        this.setState({ radioValue: "FEMALE" });
      } else if (event.target.id == 'addUserYOB') {
        this.setState({ addUserYOB: event.target.value });
      } else if (event.target.id == 'addUserEmail') {
        this.setState({ addUserEmail: event.target.value });
      } else if (event.target.id == 'addUserPassword') {
        this.setState({ addUserPassword: event.target.value });
      } 
      // else if (event.target.id == 'addUserSchoolId') {
      //   this.setState({ addUserSchoolId: event.target.value });
      // }
       else {
        
      }
      return true;
    }

    resetAddUser(){
      this.setState({
        addUserName: "",
        radioValue: "",
        addUserYOB: "",
        addUserEmail: "",
        addUserPassword: "",
      })
    }

     // Add Users submit button
     handleSubmit(e){
      e.preventDefault();
      var _that = this;
      if (_that.state.addUserName.trim() != undefined && _that.state.addUserName.trim() != null && _that.state.addUserName.trim() != "") {
          if(_that.state.radioValue.trim() != undefined && _that.state.radioValue.trim() != null && _that.state.radioValue.trim() != ""){
      
      
      _that.setState({ blocking: true });
              
      fetch(Constants.SERVER_URL + "admin/user", {
          method: 'POST', 
          headers: {                
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": _that.state.superAdminToken
          },
          body:JSON.stringify({
            user_name: _that.state.addUserName.trim(),
            gender: _that.state.radioValue,
            year_of_birth: Number(_that.state.addUserYOB.trim()),
            email: _that.state.addUserEmail,
            password: _that.state.addUserPassword.trim(),
            school_id: _that.state.currentSchoolId
          }),
        }).then(function (response) {
        
          if(response.status === 200){
            let responseData = response.json();
            responseData.then(function (data) {
             
              _that.setState({ blocking: false,  showForAddSchool: false});
              _that.resetAddUser();
              // _that.userList(_that.state.currentSchoolId);
              
              Alert.success('<h5>' + "Verification mail has been sent to registered Email" + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 4000,
                offset: Constants.OffSetValue
              });
              
            })
          
          } else{
            let responseData = response.json();
            responseData.then(function (data) {
              
              _that.resetAddUser();
              _that.setState({ blocking: false,  showForAddSchool: false});
             
              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }
        }).catch(function (error) {
          _that.setState({ blocking: false,  showForAddSchool: false });
          _that.resetAddUser();
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });
      } else{
          
          Alert.error('<h5>' + "Please Select Gender" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
  
      } else{
          
          Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
    }


    confirmDeleteUser(id) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='confirmpopup custom-ui'>
              <div className="con_header">
              {/* <h1>Confirm to Block tap {tap_number} on plant {this.state.currentPlantName}?</h1> */}
                <h1>Delete User</h1>
              </div>
              <div className="con_body">
                <p>Are you sure, you want to Delete User?</p>
              </div>
              <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
                {/* <button className="nobtn" onClick={() => {this.suru();}}>Cancel</button> */}
                <button onClick={() => {
                  this.deleteUser(id);
                  // alert("test");
                  onClose()
                }}>Delete</button>
              </div>
            </div>
          )
        },
        closeOnClickOutside: false
      })
    };

    // API function to Delete single School
    deleteUser(id){
    var _that = this;
    if(id){
        _that.setState({ blocking: true });
        fetch(Constants.SERVER_URL + "admin/user/" + id  , {
          method: 'DELETE',
          headers: {                
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": _that.state.superAdminToken
          }
        }).then(function (response) {
       
          if(response.status === 204){
            // let responseData = response.json();
            // responseData.then(function (data) {
              // _that.userList(_that.state.currentSchoolId);
              Alert.success('<h5>' + "Successfully deleted" + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
            // })
          } else if(response.status === 401){
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.setState({ blocking: false});
              Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
              
            // })
          } else{
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.setState({ blocking: false});
              Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
            // })
          }
        }).catch(function (error) {
          
          _that.setState({ blocking: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });
      } else{
        Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
  }

    // show function for edit School popup
    showEditSchool(stateData){
      
      this.setState({show: true, 
        currentEditId: stateData.id, editName: stateData.user_name, editRadio: stateData.gender,
        editEmail: stateData.email, editYOB: stateData.year_of_birth
      });
    }

    // close function for add state popup
    closeEditSchool(event){
      
      this.setState({ show: false
        // , currentIdForAddState: "" 
      });
      this.resetEditUser();
    };

    handleChange(event) {
      event.preventDefault();
      
      if (event.target.id === "editName") {
        this.setState({ editName: event.target.value });
      } else if (event.target.id === "editRadio1") {
        this.setState({ editRadio: "MALE" });
      }  else if (event.target.id === "editRadio2") {
        this.setState({ editRadio: "FEMALE" });
      }  else if (event.target.id === "editYOB") {
        this.setState({ editYOB: event.target.value });
      }  else if (event.target.id === "editEmail") {
        this.setState({ editEmail: event.target.value });
      } else if (event.target.id === "editPass") {
        this.setState({ editPass: event.target.value });
      } 
      // else if (event.target.id === "insertStateSN") {
      //   this.setState({ insertStateSN: event.target.value });
      // }
      return true;
  } 

  resetEditUser(){
    this.setState({
    editName: "",
    editRadio: "",
    editYOB: "",
    editEmail: "",
    editPass: ""});
  }

  // edit popup submit
  handleEditSubmit(e){
    e.preventDefault();
    var _that = this;
    if (_that.state.editName.trim() != undefined && _that.state.editName.trim() != null && _that.state.editName.trim() != "") {
        if(_that.state.editRadio.trim() != undefined && _that.state.editRadio.trim() != null && _that.state.editRadio.trim() != ""){
    
    
    _that.setState({ blocking: true });
            
    fetch(Constants.SERVER_URL + "admin/user/" + _that.state.currentEditId, {
        method: 'PUT', 
        headers: {                
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": _that.state.superAdminToken
        },
        body:JSON.stringify({
          user_name: _that.state.editName.trim(),
            gender: _that.state.editRadio,
            year_of_birth: Number(_that.state.editYOB),
            email: _that.state.editEmail,
            password: _that.state.editPass,
            school_id: _that.state.currentSchoolId
        }),
      }).then(function (response) {
      
        if(response.status === 204){
          // let responseData = response.json();
          // responseData.then(function (data) {
            _that.setState({ blocking: false,  show: false});
            // _that.userList(_that.state.currentSchoolId);
            _that.resetEditUser();
            Alert.success('<h5>' + "Success" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 2000,
              offset: Constants.OffSetValue
            });
            
          // })
        
        } else{
          // let responseData = response.json();
          // responseData.then(function (data) {
            
            _that.setState({ blocking: false,  show: false});
            _that.resetEditUser();
            Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            // });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false,  show: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });

    } else{
        
        Alert.error('<h5>' + "Address Shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
    }

    } else{
        
        Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
    }
  }

    selectState = selectedTheme => {
        this.setState({ selectedTheme }
            , () => { 
                this.getProgramListing(selectedTheme.id); 
            this.setState({ currentSchoolId: selectedTheme.value}) 
            }
            );
        
    };

    selectProgram = selectedProgram => {
        this.setState({ selectedProgram }
            , () => { 
                // this.userList(selectedProgram.id); 
        this.setState({ currentProgramId: selectedProgram.value}) 
        }
        );
        
    };

    // function for upload user file
    uploadHandleChange(event) {
      // event.preventDefault();
      
      let reader = new FileReader();
      var targetFile = event.target.files[0];
  
      this.setState({ uploadFile: targetFile });
    }


    // is more button functionality
    // isMoreData(){
      
    //   this.setState({page: this.state.page + 1},() => {this.userList()});
    // }

    // for pagination
    handlePageChange(pageNumber) {
      
      // this.setState({activePage: pageNumber});
      this.setState({activePage: pageNumber},() => {this.userList()});
    }
  

     /**** function to search Handle event ****/
      searchHandle = (event) => {
        if (event.target.id == "search") {
          clearTimeout(this.state.delayTimer);
          this.setState({ search: event.target.value }, () => {
            this.setState({
              delayTimer: setTimeout(function () {
                this.userList();
              }.bind(this), 700),
              activePage: 1
            });
          })
        }
      }

    // render view
    render(){
      
        const { selectedTheme, themeOptions, userListing, selectedProgram, programOptions } = this.state;
        


        let isMorebtn;
        if(userListing.length > 0){
            isMorebtn =  <Pagination
            // prevPageText='prev'
            // nextPageText='next'
            // firstPageText='first'
            // lastPageText='last'
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageSize}
            totalItemsCount={this.state.finalPagesCount}
            // pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        } else{
            isMorebtn = ""
        }


        const required = (value, props) => {
          if (!value || (props.isCheckable && !props.checked)) {
            return <span className="validationForm">Required</span>;
          }
        };

        const minContactNo = (value, props) => {
          if (!isLength(value, { min: 10 })) {
            return <span className="validationForm">Please Enter a Valid Contact Number</span>;
          }
        };
    
        const maxContactNo = (value, props) => {
            if (!isLength(value, { max: 10 })) {
                return <span className="validationForm">Please Enter a Valid Contact Number</span>;
            }
        };
    
        const minNo = (value, props) => {
            if (!isLength(value, { min: 6 })) {
              return <span className="validationForm">Please Enter Min six character</span>;
            }
        };
    
        const maxNo = (value, props) => {
            if (!isLength(value, { max: 32 })) {
                return <span className="validationForm">Max password length exceed</span>;
            }
        };
    
        const number = (value) => {
          if (!isNumeric(value)) {
            return <span className="validationForm">Please Enter a Number</span>;
          }
        };

        const email = (value) => {
          if (!isEmail(value)) {
            return <span className="form-error is-visible">Please enter a valid Email</span>;
          }
        };

        let schoolListing, schoolAlldata, schoolName, task_id, year_of_birth, userEmail, orderStatusView, schoolId;
        schoolListing = userListing.map((school, index) => {

            // if(school.status === 2){
            //   forgetPassStatus = <button type="button" className="btn btn-warning officerCursor">Yes</button>;
            // }else{
            //   forgetPassStatus = <div className="officerStatus">No</div>
            // }
  
                      if (school.status == 1) {
                          orderStatusView = <b className="btn btn-info labelbtns">Incompleted</b>;
                      } else if (school.status == 2) {
                          orderStatusView = <b className="btn btn-success labelbtns">Completed</b>;
                      } else if (school.status == 3) {
                          orderStatusView = <b className="btn btn-danger labelbtns">Cancel</b>;
                      } 

                      if(school.id != null){
                        schoolId = school.id;
                      } else{
                        schoolId = "-";
                      }
                      
                      if(school.name != null){
                        schoolName = school.name;
                      } else{
                        schoolName = "-";
                      }

                      if(school.email != null){
                        userEmail = school.email;
                      }else{
                          userEmail = "-";
                      }
                      
                      if(school.task_id != null){
                        task_id = school.task_id;
                      } else{
                          task_id = "-";
                      }
  
                      if(school.year_of_birth != null){
                          year_of_birth = school.year_of_birth;
                      }else{
                          year_of_birth = "-";
                      }
                        
                      return (
                          <tr key={index} >
                              {/* <td><input type="checkbox" className="form-check-input" onChange={this.selectHandleChange} id="singleCheckBox" value={school.email} />Select</td> */}
                              <td>{schoolId}</td>
                              <td>{schoolName}</td>
                              <td>{userEmail}</td>
                              <td>{task_id}</td>
                              <td>{orderStatusView}</td>
                              
                            {/* <td>{forgetPassStatus}</td>*/}
                            {/* <td><button className="btn btn-info" onClick={() => {this.confirmDeleteUser(school.id)}}>Delete</button>
                            <button type="button" className="btn btn-secondary" onClick={() => {this.showEditSchool(school)}} data-toggle="modal" data-target="#myModal">Edit</button></td>  */}
                          </tr>
                      )
                  })

          if(userListing.length > 0){
              schoolAlldata =	<div className="table-responsive"><table className="table table-striped">
              <thead>
                  <tr>
                      <th>User Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Task Id</th>
                      <th>Status</th>
                     
                      {/* <th>Actions</th> */}
                      {/* <th>Forget Password Request</th> 
                      <th>Password</th> */}
                  </tr>
              </thead>
              <tbody>
                 {schoolListing}
              </tbody>
            </table></div>
          } else{
              schoolAlldata = <h3 className="nodatafound">No data found</h3>
          }
        return(
            <div className="main_contents">
              <BlockUi blocking={this.state.blocking}>
              <div className="row titleheader">
                <h1 className="page_title col-md-9">Task Management</h1>

                <div className="col-md-3 text-right">
              <input type="text" id="search" className="form-control" onChange={this.searchHandle} placeholder="Search.." value={this.state.search} />
            </div>
                
                {/* <div className="col-md-3">
                <Select
                    value={selectedTheme}
                    onChange={this.selectState}
                    options={themeOptions}
                    placeholder="Select Themes"
                />
                </div>

                <div className="col-md-3">
                <Select   
                    value={selectedProgram}
                    onChange={this.selectProgram}
                    options={programOptions}
                    placeholder="Select Programs"
                />
                </div> */}

                {/* <div className="col-md-2 text-right"><button type="button" className="btn btn-dark" onClick = {this.openAddOfficer}>Add User</button></div> */}
              </div>
            
            <Row>
              <Col xs="12" lg="12" className="divright">
                <Card className="mytablesright settingright">
                  <CardBody>
                    {schoolAlldata}
                    <div className="loadMoreDiv">
                        {isMorebtn}
                        {/* <Pagination
                          // prevPageText='prev'
                          // nextPageText='next'
                          // firstPageText='first'
                          // lastPageText='last'
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.pageSize}
                          totalItemsCount={450}
                          // pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        /> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* ///// */}
            <div className="modal-container" style={{ height: 200 }}>
               <Modal
                   show={this.state.showForAddSchool}
                //    onHide={this.close}
                   container={this}
                   aria-labelledby="contained-modal-title"
               >
               
            
                    <Form ref={(form) => this.loginFormRef1 = form} className="resetPopup" name="loginForm" onSubmit={this.handleSubmit}>
                   <Modal.Header >
                       <Modal.Title id="contained-modal-title">Add User</Modal.Title>
                   </Modal.Header>

                   <Modal.Body>
                    <div className="formbox">
                        <label>Name </label>
                        <Input type="text" className="form-control" id="addUserName" ref="addUserName" onChange={this.handleAddOfficerChange} value={this.state.addUserName} validations={[required]}/>          
                    </div>

                        <div className="formbox">
                            <label>Gender</label>
                            <input type="radio" id="radio1" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == "MALE"}/>Male
                            <input type="radio" id="radio2" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == "FEMALE"}/>Female<br></br>
                        </div>

                        <div className="formbox">
                            <label>Year Of Birth</label>
                            <Input type="text" className="form-control" id="addUserYOB" ref="addUserYOB" onChange={this.handleAddOfficerChange} value={this.state.addUserYOB} validations={[required]}/>
                        </div>
                    
                        <div className="formbox">
                            <label>Email</label>
                            <Input type="text" className="form-control" id="addUserEmail" ref="addUserEmail" onChange={this.handleAddOfficerChange} value={this.state.addUserEmail} validations={[required, email]}/>
                        </div>

                        <div className="formbox">
                          <label>Password</label>
                          <Input type="text" className="form-control" id="addUserPassword" ref="addUserPassword" onChange={this.handleAddOfficerChange} value={this.state.addUserPassword} validations={[required, maxNo]}/>
                         </div>

                        {/* <div className="formbox">
                            <label>School Id</label>
                            <Input type="text" className="form-control" id="addUserSchoolId" ref="addUserSchoolId" onChange={this.handleAddOfficerChange} value={this.state.addUserSchoolId} validations={[required]}/>
                        </div> */}
                    {/* <div className="formbox">
                      <label>Officer Designation Id</label>
                            
                    </div>
                    <div className="formbox">
                        <label>Password</label>
                        <Input type="text" className="form-control" id="addUserPassword" ref="addUserPassword" onChange={this.handleAddOfficerChange} value={this.state.addUserPassword} validations={[required, maxNo]}/>
                    </div>
                    
                    <div className="formbox">
                        <label>Username</label>
                        <Input type="text" className="form-control" id="addOfficerUsername" ref="addOfficerUsername" onChange={this.handleAddOfficerChange} value={this.state.addOfficerUsername} validations={[required]}/>
                    </div>
                        <div className="formbox">
                            <label>Active Status :</label>
                            <input type="radio" id="radio1" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == true}/>True
                            <input type="radio" id="radio2" name="gender" onChange={this.handleAddOfficerChange} checked={this.state.radioValue == false}/>False<br></br>
                        </div> */}
                    {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}
                   </Modal.Body>
                   <Modal.Footer>
                      <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
                  </Modal.Footer>
                  </Form>
                  <button className="btn btn-danger" onClick={this.closeForAddOfficer}>Close</button>
               </Modal>
           </div>
            {/* ////// */}

            <div className="modal-container" style={{ height: 200 }}>
               {/* <Button
                   bsStyle="primary"
                   bsSize="large"
                   onClick={() => this.setState({ show: true })}
               >
                   Launch contained modal
               </Button> */}

               <Modal
                   show={this.state.show}
                //    onHide={this.close}
                   container={this}
                   aria-labelledby="contained-modal-title"
               >
               
                    <Form ref={(form) => this.loginFormRef = form} className="resetPopup" name="loginForm" onSubmit={this.handleEditSubmit}>
                   <Modal.Header >
                       <Modal.Title id="contained-modal-title">Edit User</Modal.Title>
                   </Modal.Header>

                   <Modal.Body>
                    <div className="formbox">
                        <label>Name</label>
                        <Input type="text" className="form-control" id="editName" ref="editName" onChange={this.handleChange} value={this.state.editName} validations={[required]}/>
                    </div>

                    <div className="formbox">
                          <label>Gender</label>
                          <input type="radio" id="editRadio1" name="gender" onChange={this.handleChange} checked={this.state.editRadio == "MALE"}/>Male
                          <input type="radio" id="editRadio2" name="gender" onChange={this.handleChange} checked={this.state.editRadio == "FEMALE"}/>Female<br></br>
                    </div>
                    
                    <div className="formbox">
                        <label>Year Of Birth</label>
                        <Input type="text" className="form-control" id="editYOB" ref="editYOB" onChange={this.handleChange} value={this.state.editYOB} validations={[required]}/>
                    </div>
                    <div className="formbox">
                        <label>Email</label>
                        <Input type="text" className="form-control" id="editEmail" ref="editEmail" onChange={this.handleChange} value={this.state.editEmail} validations={[required]}/>
                    </div>
                    <div className="formbox">
                        <label>Password</label>
                        <Input type="password" className="form-control" id="editPass" ref="editPass" onChange={this.handleChange} value={this.state.editPass} validations={[required]}/>
                    </div>
                    
                    {/* <div className="formbox">
                        <label>State Short Name</label>
                        <Input type="text" className="form-control" id="insertStateSN" ref="insertStateSN" onChange={this.handleChange} value={this.state.insertStateSN} validations={[required]}/>
                    </div> */}
                    {/* <div className="formbox">
                        <label>Comfirm Password</label>
                        <Input type="text" className="form-control" id="confirmPassword" ref="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} validations={[minNo, maxNo]}/>{modalIcon}
                    </div> */}
                    {/* <div className="createpassword">
                    <span onClick={this.createPass}>Generate Password:</span>   {this.state.createPass}
                    </div> */}
                    {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}
                   </Modal.Body>
                   <Modal.Footer>
                      <button type="submit" className="savebtn btn btn-secondary">Save</button>
                  </Modal.Footer>
                  </Form>
                  <button className="btn btn-danger" onClick={this.closeEditSchool}>Close</button>
               </Modal>
           </div>
           </BlockUi>
          </div>
        )
    }
}

export default Task;