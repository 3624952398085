import React from 'react';
import {
// Badge,
ButtonGroup,ButtonToolbar,Card,CardBody,CardTitle,Col,Row,Table,
// FormGroup,Input,InputGroup,InputGroupAddon
} from 'reactstrap';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Constants from '../../../config/constants';
import $ from 'jquery'; 
import BlockUi from 'react-block-ui';
import Modal from 'react-bootstrap/Modal';

/* modules for validation */
import { isEmail, isLength, isNumeric, isAlpha } from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Pagination from "react-js-pagination";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Organizational extends React.Component{
    constructor(props){
        super(props);
        let superAdminId = localStorage.getItem("superAdminId");
        let superAdminToken = localStorage.getItem("superAdminToken");
        
        this.state ={
            superAdminId: superAdminId,
            superAdminToken: superAdminToken,
            schoolData: [],
            page: 1,
            
            blocking: false,
            showForAddSchool: false,
            englishName: "",
            schoolCode: "",
            schoolAddress: "",
            show: false,
            editName: "",
            editAddrees: "",
            editSchoolCode: "",

            editPostalCode: "",
            editCity: "",
            editCountry: "",
            editInAdd: "",
            editInPostalCode: "",
            editInCity: "",
            editInCountry: "",

            currentSchoolId: 0,
            selectAuthority: "SCHOOL",
            visit_street_address: "",
            visit_postal_Code: "",
            visit_city: "",
            visit_country: "",
            invoice_street_address: "",
            invoice_postal_Code: "",
            invoice_city: "",
            invoice_country: "",

            addSchoolDynamicAdd: false,
            editSchoolDynamicAdd: false,

            isMore: false,
            searchExist: false,
            activePage: 1,
            pageSize: 10,

            finalPagesCount: 1,

            date: "",
            dob: {},
            day: 0,
            month: 0,
            year: 0,
            selectedDate: ""
        }
        this.schoolList = this.schoolList.bind(this);
        this.openAddOfficer = this.openAddOfficer.bind(this);
        this.closeForAddOfficer = this.closeForAddOfficer.bind(this);
        this.handleAddOfficerChange = this.handleAddOfficerChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmDeleteSchool = this.confirmDeleteSchool.bind(this);
        this.deleteSchool = this.deleteSchool.bind(this);
        this.showEditSchool = this.showEditSchool.bind(this);
        this.closeEditSchool = this.closeEditSchool.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
        this.resetEditSchool = this.resetEditSchool.bind(this);
        this.resetAddSchool = this.resetAddSchool.bind(this);
        this.handleSelectAuthority = this.handleSelectAuthority.bind(this);
        this.editCheckbox = this.editCheckbox.bind(this);
        this.isMoreData = this.isMoreData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
      }


    /**** Component life cycle method ***/
    componentWillMount() {
        this.schoolList(); //get schoolList Details
    }

    // get school listing
    schoolList() {
          var _that = this;
          var param = { page_number: _that.state.activePage, page_size: _that.state.pageSize,
            account_type: _that.state.selectAuthority
            // , search: _that.state.search.trim(), designationId: _that.state.selectId
          }
          _that.setState({ blocking: true });
       
          fetch(Constants.SERVER_URL + "admin/accounts?" + $.param(param), {
            method: 'GET', 
            headers: {                
              "Accept": "application/json",
              "Content-type": "application/json",
              "Authorization": _that.state.superAdminToken
            }
          }).then(function (response) {
            if(response.status === 200){
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});


                // if (data.data.length == _that.state.pageSize) {
                //   _that.setState({
                //     isMore: true
                //   });
                // } else {
                //   _that.setState({
                //     isMore: false
                //   });
                // }

         
                // if (_that.state.page == 1) {
                  _that.setState({ blocking: false, 
                    schoolData: data.data , finalPagesCount: data.totalAccounts  
                  });
                // }
                // else if (data.data.length > 0) {
                //   let oldData = JSON.parse(JSON.stringify(_that.state.schoolData));
                //   let newData = JSON.parse(JSON.stringify(data.data));
                //   let totalData = oldData.concat(newData);
  
                //   _that.setState({ schoolData: totalData, blocking: false });
                // } else if (_that.state.searchExist == false) {
                  
                //   let oldData = JSON.parse(JSON.stringify(_that.state.schoolData));
                //   let newData = JSON.parse(JSON.stringify(data.data));
                //   let totalData = oldData.concat(newData);
  
                //   _that.setState({ schoolData: totalData, blocking: false });
                // } else {
                //   _that.setState({ blocking: false, schoolData: data.data, page: 1 });
                // }
                
              })
            
            } else{
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
                Alert.error('<h5>' + data.message + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }
          }).catch(function (error) {
            _that.setState({ blocking: false });
            if (navigator.onLine) {
              Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            } else {
              Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            }
          });
    }

    openAddOfficer(){
      
      this.setState({showForAddSchool: true });
    }

    closeForAddOfficer(event){
      
      this.setState({ showForAddSchool: false});
      this.resetAddSchool();
    };

     /**** function to handle change for edit popup ****/
     handleAddOfficerChange(event) {
      
      if (event.target.id == 'englishName') {
        this.setState({ englishName: event.target.value });
      } else if (event.target.id == 'schoolCode') {
        this.setState({ schoolCode: event.target.value });
      } else if (event.target.id == 'schoolAddress') {
        this.setState({ schoolAddress: event.target.value });
      } 
      
      
      else if (event.target.id == 'visit_street_address') {
        this.setState({ visit_street_address: event.target.value });
      } else if (event.target.id == 'visit_postal_Code') {
        this.setState({ visit_postal_Code: event.target.value });
      }else if (event.target.id == 'visit_city') {
        this.setState({ visit_city: event.target.value });
      } else if (event.target.id == 'visit_country') {
        this.setState({ visit_country: event.target.value });
      }else if (event.target.id == 'invoice_street_address') {
        this.setState({ invoice_street_address: event.target.value });
      } else if (event.target.id == 'invoice_postal_Code') {
        this.setState({ invoice_postal_Code: event.target.value });
      }else if (event.target.id == 'invoice_city') {
        this.setState({ invoice_city: event.target.value });
      } else if (event.target.id == 'invoice_country') {
        this.setState({ invoice_country: event.target.value });
      } 
      
      else if (event.target.id == 'checkbox_address') {
        this.setState({ addSchoolDynamicAdd: !this.state.addSchoolDynamicAdd });
      } 
      else {
        
      }
      return true;
    }

    resetAddSchool(){
      this.setState({
      englishName : "",
      schoolCode: "", schoolAddress: "",
      visit_street_address: "",
      visit_postal_Code: "",
      visit_city: "",
      visit_country: "",
      invoice_street_address: "",
      invoice_postal_Code: "",
      invoice_city: "",
      invoice_country: "",
      addSchoolDynamicAdd: false,
      selectedDate: ""
      });
    }

     // Add officer submit button
     handleSubmit(e){
      e.preventDefault();
      var _that = this;

      let invoiceStreetAdd, invoicePostalCode, invoiceCity, invoiceCountry;

      if(_that.state.addSchoolDynamicAdd != false){
        invoiceStreetAdd = _that.state.visit_street_address.trim()
        invoicePostalCode = _that.state.visit_postal_Code.trim()
        invoiceCity = _that.state.visit_city.trim()
        invoiceCountry = _that.state.visit_country.trim()
      } else {
        invoiceStreetAdd = _that.state.invoice_street_address.trim()
        invoicePostalCode = _that.state.invoice_postal_Code.trim()
        invoiceCity = _that.state.invoice_city.trim()
        invoiceCountry = _that.state.invoice_country.trim()
      }

      if (_that.state.englishName.trim() != undefined && _that.state.englishName.trim() != null && _that.state.englishName.trim() != "") {
        if(_that.state.schoolCode.trim() != undefined && _that.state.schoolCode.trim() != null && _that.state.schoolCode.trim() != ""){
        if(_that.state.visit_street_address.trim() != undefined && _that.state.visit_street_address.trim() != null && _that.state.visit_street_address.trim() != ""){
        if(_that.state.visit_postal_Code.trim() != undefined && _that.state.visit_postal_Code.trim() != null && _that.state.visit_postal_Code.trim() != ""){
        if(_that.state.visit_city.trim() != undefined && _that.state.visit_city.trim() != null && _that.state.visit_city.trim() != ""){
        if(_that.state.visit_country.trim() != undefined && _that.state.visit_country.trim() != null && _that.state.visit_country.trim() != ""){

        if(invoiceStreetAdd != undefined && invoiceStreetAdd != null && invoiceStreetAdd != ""){
          if(invoicePostalCode != undefined && invoicePostalCode != null && invoicePostalCode != ""){
            if(invoiceCity != undefined && invoiceCity != null && invoiceCity != ""){
              if(invoiceCountry != undefined && invoiceCountry != null && invoiceCountry != ""){
      if (_that.state.selectedDate != undefined && _that.state.selectedDate != null && _that.state.selectedDate != "") {



      _that.setState({ blocking: true });

      
      fetch(Constants.SERVER_URL + "admin/account", {
          method: 'POST', 
          headers: {                
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": _that.state.superAdminToken
          },
          body:JSON.stringify({
              account_type: _that.state.selectAuthority,
              name: _that.state.englishName.trim(),
              account_code: _that.state.schoolCode.trim(),
              visit_street_address: _that.state.visit_street_address.trim(),

              visit_postal_Code: _that.state.visit_postal_Code.trim(),
              visit_city: _that.state.visit_city.trim(),
              visit_country: _that.state.visit_country.trim(),

              invoice_street_address: invoiceStreetAdd,
              invoice_postal_Code: invoicePostalCode,
              invoice_city: invoiceCity,
              invoice_country: invoiceCountry,
              subscription_expiry: _that.state.selectedDate
          }),
        }).then(function (response) {
        
          if(response.status === 200){
            let responseData = response.json();
            responseData.then(function (data) {
             
              _that.setState({ blocking: false,  showForAddSchool: false});
              _that.resetAddSchool();
              _that.schoolList();
  
              Alert.success('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
              
            })
          
          } else{
            let responseData = response.json();
            responseData.then(function (data) {
              
              _that.setState({ blocking: false,  showForAddSchool: false});
              _that.resetAddSchool();
              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }
        }).catch(function (error) {
          _that.setState({ blocking: false,  showForAddSchool: false });
          _that.resetAddSchool();
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });


      } else{
        Alert.error('<h5>' + "Please Select Expiry date" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
    }


      } else{
        Alert.error('<h5>' + "Invoice Country shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }

      } else{
        Alert.error('<h5>' + "Invoice City shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }


      } else{
        Alert.error('<h5>' + "Invoice Postal Code shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }


      } else{
        Alert.error('<h5>' + "Invoice Address shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }

      } else{
        
        Alert.error('<h5>' + "Country shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }

      } else{
        
        Alert.error('<h5>' + "City shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }


      } else{
        
        Alert.error('<h5>' + "Postal Code shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }

      } else{
        
        Alert.error('<h5>' + "Street Address shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
      }

  
      } else{
          
          Alert.error('<h5>' + "Organizational Code shouldn't be blank" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
  
      } else{
          
          Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
    }


    confirmDeleteSchool(id) {
      let title;
      if(this.state.selectAuthority == "SCHOOL"){
        title= "School";
      }else{
        title= "Organization";
      }
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='confirmpopup custom-ui'>
              <div className="con_header">
                <h1>Delete {title}</h1>
              </div>
              <div className="con_body">
                {/* <p>Are you sure, you want to {title}?</p> */}
                <p> On deleting this {title}, you may loose the list of users associated with this {title}. Are you sure?</p>
              </div>
              <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
                <button onClick={() => {
                  this.deleteSchool(id);
                  onClose()
                }}>Delete</button>
              </div>
            </div>
          )
        },
        closeOnClickOutside: false
      })
    };

    // API function to Delete single School
    deleteSchool(id){
    var _that = this;
    if(id){
        _that.setState({ blocking: true });
        fetch(Constants.SERVER_URL + "admin/account/" + id  , {
          method: 'DELETE',
          headers: {                
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": _that.state.superAdminToken
          }
        }).then(function (response) {
       
          if(response.status === 204 || response.status === 200){
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.schoolList();
              Alert.success('<h5>' + "Successfully deleted" + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
            // })
          } else if(response.status === 401){
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.setState({ blocking: false});
              Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
              
            // })
          } else{
            // let responseData = response.json();
            // responseData.then(function (data) {
              _that.setState({ blocking: false});
              Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 2000,
                offset: Constants.OffSetValue
              });
            // })
          }
        }).catch(function (error) {
          
          _that.setState({ blocking: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });
      } else{
        Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
        });
      }
  }

    // show function for edit School popup
    showEditSchool(stateData){
      // console.log("show edit school");
      let editedDate, StartDate, selectedDate;
      if(stateData.subscription_expiry != null){
        editedDate = stateData.subscription_expiry;

        StartDate = moment(editedDate, "DD/MM/YYYY").valueOf();
        selectedDate = moment(StartDate).format("DD/MM/YYYY");
        
        // let newDate = new Date((StartDate/1000));
      }else{
        StartDate = ""
        selectedDate = ""
      }
      
      this.setState({show: true, 
        currentSchoolId: stateData.id, editName: stateData.name, 
        editSchoolCode: stateData.account_code,
        editAddrees: stateData.visit_street_address,

        editPostalCode: stateData.visit_postal_Code,
        editCity: stateData.visit_city,
        editCountry: stateData.visit_country,

        editInAdd: stateData.invoice_street_address,
        editInPostalCode: stateData.invoice_postal_Code,
        editInCity: stateData.invoice_city,
        editInCountry: stateData.invoice_country,
        
        date : StartDate,
        selectedDate: selectedDate

      });
    }

    // close function for add state popup
    closeEditSchool(event){
      
      this.setState({ show: false
        // , currentIdForAddState: "" 
      });
      this.resetEditSchool();
    };

    handleChange(event) {
      event.preventDefault();
      
      if (event.target.id === "editName") {
        this.setState({ editName: event.target.value });
      } else if (event.target.id === "editSchoolCode") {
        this.setState({ editSchoolCode: event.target.value });
      } else if (event.target.id === "editAddrees") {
        this.setState({ editAddrees: event.target.value });
      } 

      
      else if (event.target.id === "editPostalCode") {
        this.setState({ editPostalCode: event.target.value });
      } else if (event.target.id === "editCity") {
        this.setState({ editCity: event.target.value });
      } else if (event.target.id === "editCountry") {
        this.setState({ editCountry: event.target.value });
      } else if (event.target.id === "editInAdd") {
        this.setState({ editInAdd: event.target.value });
      } else if (event.target.id === "editInPostalCode") {
        this.setState({ editInPostalCode: event.target.value });
      } else if (event.target.id === "editInCity") {
        this.setState({ editInCity: event.target.value });
      } else if (event.target.id === "editInCountry") {
        this.setState({ editInCountry: event.target.value });
      } else if (event.target.id == 'checkbox_Invoiceaddress') {
        this.setState({ editSchoolDynamicAdd: !this.state.editSchoolDynamicAdd });
      } 
      
      // else if (event.target.id === "insertStateSN") {
      //   this.setState({ insertStateSN: event.target.value });
      // }
      return true;
  } 

  editCheckbox(){
    this.setState({ editSchoolDynamicAdd: !this.state.editSchoolDynamicAdd });

  }

  resetEditSchool(){
    this.setState({editName: "",
    editAddrees: "",
    editSchoolCode: "",
    editSchoolDynamicAdd: false,
    selectedDate: "",
    date: ""    
  });
  }

  // edit submit
  handleEditSubmit(e){
    e.preventDefault();
    var _that = this;
    let invoiceStreetAdd, invoicePostalCode, invoiceCity, invoiceCountry;

      if(_that.state.editSchoolDynamicAdd != false){
        invoiceStreetAdd = _that.state.editAddrees.trim()
        invoicePostalCode = _that.state.editPostalCode.trim()
        invoiceCity = _that.state.editCity.trim()
        invoiceCountry = _that.state.editCountry.trim()
      } else{
        invoiceStreetAdd = _that.state.editInAdd.trim()
        invoicePostalCode = _that.state.editInPostalCode.trim()
        invoiceCity = _that.state.editInCity.trim()
        invoiceCountry = _that.state.editInCountry.trim()
      }

  if (_that.state.editName.trim() != undefined && _that.state.editName.trim() != null && _that.state.editName.trim() != "") {
    if(_that.state.editSchoolCode.trim() != undefined && _that.state.editSchoolCode.trim() != null && _that.state.editSchoolCode.trim() != ""){
      if(_that.state.editAddrees.trim() != undefined && _that.state.editAddrees.trim() != null && _that.state.editAddrees.trim() != ""){
        if(_that.state.editPostalCode.trim() != undefined && _that.state.editPostalCode.trim() != null && _that.state.editPostalCode.trim() != ""){
        if(_that.state.editCity.trim() != undefined && _that.state.editCity.trim() != null && _that.state.editCity.trim() != ""){
        if(_that.state.editCountry.trim() != undefined && _that.state.editCountry.trim() != null && _that.state.editCountry.trim() != ""){

          if(invoiceStreetAdd != undefined && invoiceStreetAdd != null && invoiceStreetAdd != ""){
            if(invoicePostalCode != undefined && invoicePostalCode != null && invoicePostalCode != ""){
              if(invoiceCity != undefined && invoiceCity != null && invoiceCity != ""){
                if(invoiceCountry != undefined && invoiceCountry != null && invoiceCountry != ""){
      if (_that.state.selectedDate != undefined && _that.state.selectedDate != null && _that.state.selectedDate != "") {
          

    _that.setState({ blocking: true });

            
    fetch(Constants.SERVER_URL + "admin/account/" + _that.state.currentSchoolId, {
        method: 'PUT', 
        headers: {                
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": _that.state.superAdminToken
        },
        body:JSON.stringify({
            account_type: _that.state.selectAuthority,
            name: _that.state.editName.trim(),
            visit_street_address: _that.state.editAddrees.trim(),
            account_code: _that.state.editSchoolCode,

            
            visit_postal_Code: _that.state.editPostalCode,
            visit_city: _that.state.editCity,
            visit_country: _that.state.editCountry,
            
            invoice_street_address: invoiceStreetAdd,
            invoice_postal_Code: invoicePostalCode,
            invoice_city: invoiceCity,
            invoice_country: invoiceCountry,
            subscription_expiry: _that.state.selectedDate

        }),
      }).then(function (response) {
      
        if(response.status === 204){
          // let responseData = response.json();
          // responseData.then(function (data) {
           
            _that.setState({ blocking: false,  show: false});
           _that.resetEditSchool();
            _that.schoolList();

            Alert.success('<h5>' + "Success" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 2000,
              offset: Constants.OffSetValue
            });
            
          // })
        
        } else{
          // let responseData = response.json();
          // responseData.then(function (data) {
            
            _that.resetEditSchool();
            _that.setState({ blocking: false,  show: false});
           
            Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            // });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false,  showForAddSchool: false });
        _that.resetEditSchool();

        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });


    } else{
      Alert.error('<h5>' + "Please Select Expiry date" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
  }


    } else{
      Alert.error('<h5>' + "Invoice Country shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }

    } else{
      Alert.error('<h5>' + "Invoice City shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }


    } else{
      Alert.error('<h5>' + "Invoice Postal Code shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }


    } else{
      Alert.error('<h5>' + "Invoice Address shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }

    } else{
      
      Alert.error('<h5>' + "Country shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }

    } else{
      
      Alert.error('<h5>' + "City shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }


    } else{
      
      Alert.error('<h5>' + "Postal Code shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }

    } else{
      
      Alert.error('<h5>' + "Street Address shouldn't be blank" + '</h5>', {
          position: 'top-right',
          effect: 'slide',
          beep: false,
          timeout: 3000,
          offset: Constants.OffSetValue
      });
    }


    } else{
        
      // _that.resetEditSchool();
        Alert.error('<h5>' + "Organizational Code Shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
    }

    } else{
        
        Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
        });
    }
  }

  handleSelectAuthority(event){
    
    if (event.target.id == 'subscribe') {
        this.setState({ selectAuthority: "SCHOOL", schoolData: [], activePage: 1, isMore: false  }, () => {this.schoolList()});
      } else if (event.target.id == 'subscribe1') {
        this.setState({ selectAuthority: "ORGANIZATION", schoolData: [], activePage: 1, isMore: false }, () => {this.schoolList()});
      } 
      return true;
  }

    // is more button functionality
    isMoreData(){
      
      this.setState({page: this.state.page + 1},() => {this.schoolList()});
    }

    // for pagination
    handlePageChange(pageNumber) {
      
      // this.setState({activePage: pageNumber});
      this.setState({activePage: pageNumber},() => {this.schoolList()});
    }

    /**** function to get date of birth ****/
  	dateChange(date){
      // console.log("data found>>>>>>>>>",date);
    //   alert(date);
    let selectedDate = moment(date).format("DD/MM/YYYY");

      this.setState({
          date: date, selectedDate: selectedDate
      }, function(){
      let dob = moment(this.state.date).format("DD-MM-YYYY");
      
      // console.log("=====>>>in datechange", selectedDate);
        this.setState({day: dob.split("-")[0], month: dob.split("-")[1], year: dob.split("-")[2] });
      });
    }

    render(){

      

        //imp for date picker
        var date = new Date();
        
        const { schoolData, selectAuthority } = this.state;

        let isMorebtn;
        if(schoolData.length > 0){
            isMorebtn =  <Pagination
            // prevPageText='prev'
            // nextPageText='next'
            // firstPageText='first'
            // lastPageText='last'
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageSize}
            totalItemsCount={this.state.finalPagesCount}
            // pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        } else{
            isMorebtn = ""
        }

        const required = (value, props) => {
          if (!value || (props.isCheckable && !props.checked)) {
            return <span className="validationForm">Required</span>;
          }
        };

        const minContactNo = (value, props) => {
          if (!isLength(value, { min: 10 })) {
            return <span className="validationForm">Please Enter a Valid Contact Number</span>;
          }
        };
    
        const maxContactNo = (value, props) => {
            if (!isLength(value, { max: 10 })) {
                return <span className="validationForm">Please Enter a Valid Contact Number</span>;
            }
        };
    
        const minNo = (value, props) => {
            if (!isLength(value, { min: 6 })) {
              return <span className="validationForm">Please Enter Min six character</span>;
            }
        };
    
        const maxNo = (value, props) => {
            if (!isLength(value, { max: 32 })) {
                return <span className="validationForm">Max password length exceed</span>;
            }
        };
    
        const number = (value) => {
          if (!isNumeric(value)) {
            return <span className="validationForm">Please Enter a Number</span>;
          }
        };

        let schoolListing, schoolAlldata, schoolName, school_code, address, invoice_address, schoolId, subscription_expiry;
        schoolListing = schoolData.map((school, index) => {

            // if(school.status === 2){
            //   forgetPassStatus = <button type="button" className="btn btn-warning officerCursor">Yes</button>;
            // }else{
            //   forgetPassStatus = <div className="officerStatus">No</div>
            // }
  
                    //   if (school.status == 1) {
                    //       orderStatusView = <b className="btn btn-info">Pending</b>;
                    //   } else if (school.status == 2) {
                    //       orderStatusView = <b className="btn btn-success">Completed</b>;
                    //   } else if (school.status == 3) {
                    //       orderStatusView = <b className="btn btn-danger">Cancel</b>;
                    //   } 

                      if(school.id != null){
                        schoolId = school.id;
                      } else{
                        schoolId = "-";
                      }
                      
                      if(school.name != null){
                        schoolName = school.name;
                      } else{
                        schoolName = "-";
                      }
                      
                      if(school.account_type != null){
                        school_code = school.account_code;
                      } else{
                          school_code = "-";
                      }
  
                      if(school.visit_street_address != null){
                          address = school.visit_street_address;
                      }else{
                          address = "-";
                      }

                      if(school.invoice_street_address != null){
                        invoice_address = school.invoice_street_address;
                      }else{
                          invoice_address = "-";
                      }

                      if(school.subscription_expiry != null && school.subscription_expiry != ""){
                        subscription_expiry = school.subscription_expiry;
                      } else{
                          subscription_expiry = "-";
                      }
                      return (
                          <tr key={index} >
                              {/* <td><input type="checkbox" className="form-check-input" onChange={this.selectHandleChange} id="singleCheckBox" value={school.email} />Select</td> */}
                              <td>{schoolId}</td>
                              <td>{schoolName}</td>
                              <td>{school_code}</td>
                              <td>{address}</td>
                              <td>{invoice_address}</td>
                              <td>{subscription_expiry}</td>
                            {/* <td>{forgetPassStatus}</td>*/}
                            <td><button className="btn btn-danger" title="Delete" onClick={() => {this.confirmDeleteSchool(school.id)}}><i className="fa fa-trash" aria-hidden="true"></i></button>
                            <button type="button" className="btn btn-info" title="Edit" onClick={() => {this.showEditSchool(school)}} data-toggle="modal" data-target="#myModal"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button></td> 
                          </tr>
                      )
                  })

          if(schoolData.length > 0){
              schoolAlldata =	<div className="table-responsive"><table className="table table-striped">
              <thead>
                  <tr>
                      <th>Organizational Id</th>
                      <th>Name</th>
                      <th>Organizational Code</th>
                      <th>Visit Address</th>
                      <th>Invoice Address</th>
                      <th>Subscription Expiry Time</th> 
                      <th>Actions</th>
                       {/*<th>Password</th> */}
                  </tr>
              </thead>
              <tbody>
              {schoolListing}
              </tbody>
            </table></div>
          } else{
              schoolAlldata = <h3 className="nodatafound">No data found</h3>
          }

          let addSchoolTitle, modalTitle;
          if(selectAuthority == "SCHOOL"){
            addSchoolTitle = <div className="col-sm-4 text-right"><button type="button" className="btn btn-dark" onClick = {this.openAddOfficer}>Add School</button></div>

            modalTitle =  "School"
        //     schoolContent = 
        //     <div>
        //     <Row>
        //     <Col xs="12" lg="12" className="divright">
        //       <Card className="mytablesright settingright">
        //         <CardBody>
        //           {schoolAlldata}
        //         </CardBody>
        //       </Card>
        //     </Col>
        //   </Row>

          
        //   <div className="modal-container" style={{ height: 200 }}>
        //      <Modal
        //          show={this.state.showForAddSchool}
        //          container={this}
        //          aria-labelledby="contained-modal-title"
        //      >
        //           <Form ref={(form) => this.loginFormRef1 = form} className="resetPopup" name="loginForm" onSubmit={this.handleSubmit}>
        //          <Modal.Header >
        //              <Modal.Title id="contained-modal-title">Add School</Modal.Title>
        //          </Modal.Header>
        //          <Modal.Body>
        //           <div className="formbox">
        //               <label>Name </label>
        //               <Input type="text" className="form-control" id="englishName" ref="englishName" onChange={this.handleAddOfficerChange} value={this.state.englishName} validations={[required]}/>          
        //           </div>
        //               <div className="formbox">
        //                   <label>School Code</label>
        //                   <Input type="text" className="form-control" id="schoolCode" ref="schoolCode" onChange={this.handleAddOfficerChange} value={this.state.schoolCode} validations={[required]}/>
        //               </div>
        //               <div className="formbox">
        //                   <label>Address</label>
        //                   <Input type="text" className="form-control" id="schoolAddress" ref="schoolAddress" onChange={this.handleAddOfficerChange} value={this.state.schoolAddress} validations={[required]}/>
        //               </div>
        //          </Modal.Body>
        //          <Modal.Footer>
        //             <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
        //         </Modal.Footer>
        //         </Form>
        //         <button className="btn btn-danger" onClick={this.closeForAddOfficer}>Close</button>
        //      </Modal>
        //  </div>
          

        //       <div className="modal-container" style={{ height: 200 }}>
        //             <Modal
        //             show={this.state.show}
        //             container={this}
        //             aria-labelledby="contained-modal-title">
                
        //             <Form ref={(form) => this.loginFormRef = form} className="resetPopup" name="loginForm" onSubmit={this.handleEditSubmit}>
        //             <Modal.Header >
        //                 <Modal.Title id="contained-modal-title">Edit School</Modal.Title>
        //             </Modal.Header>
        //             <Modal.Body>
        //               <div className="formbox">
        //                   <label>Name</label>
        //                   <Input type="text" className="form-control" id="editName" ref="editName" onChange={this.handleChange} value={this.state.editName} validations={[required]}/>
        //               </div>
        //               <div className="formbox">
        //                   <label>School Code</label>
        //                   <Input type="text" className="form-control" id="editSchoolCode" ref="editSchoolCode" onChange={this.handleAddOfficerChange} value={this.state.editSchoolCode} validations={[required]}/>
        //               </div>
        //               <div className="formbox">
        //                   <label>Address</label>
        //                   <Input type="text" className="form-control" id="editAddrees" ref="editAddrees" onChange={this.handleChange} value={this.state.editAddrees} validations={[required]}/>
        //               </div>
        //             </Modal.Body>
        //             <Modal.Footer>
        //                 <button type="submit" className="savebtn btn btn-secondary">Save</button>
        //             </Modal.Footer>
        //             </Form>
        //             <button className="btn btn-danger" onClick={this.closeEditSchool}>Close</button>
        //         </Modal>
        //     </div>
        //     </div>

          } else{
            addSchoolTitle = <div className="col-sm-4 text-right"><button type="button" className="btn btn-dark"  onClick = {this.openAddOfficer}>Add Organization</button></div>

            modalTitle =  "Organization"
                       // schoolContent = ""
          }
        return(
            <div className="main_contents">
              <BlockUi blocking={this.state.blocking}>
              {/* <ToastContainer/> */}
              <div className="row titleheader">
                <div className="col-sm-8 header_radiooption">
                    <h1 className="page_title">School
                      <input type="radio" id="subscribe" name="gender" onChange={this.handleSelectAuthority} checked={this.state.selectAuthority == "SCHOOL"}/>
                      <span className="checkmark"></span>
                      </h1>
                    <h1 className="page_title"> Organization
                    <input type="radio" id="subscribe1" name="gender" onChange={this.handleSelectAuthority} checked={this.state.selectAuthority == "ORGANIZATION"}/> 
                    <span className="checkmark"></span>
                    </h1>
                </div>
                            
              {/* <div className="col-sm-4 text-right"><button type="button" className="btn btn-dark" onClick = {this.openAddOfficer}>Add School</button></div> */}
              
              {addSchoolTitle}
            </div>
            
            {/* {schoolContent} */}
            <Row>
              <Col xs="12" lg="12" className="divright">
                <Card className="mytablesright settingright">
                  <CardBody>
                    {schoolAlldata}
                    <div className="loadMoreDiv">
                        {isMorebtn}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            
            <div className="modal-container" style={{ height: 200 }}>
               <Modal
                   show={this.state.showForAddSchool}
                   container={this}
                   aria-labelledby="contained-modal-title"
               >
                    <Form ref={(form) => this.loginFormRef1 = form} className="resetPopup" name="loginForm" onSubmit={this.handleSubmit}>
                   <Modal.Header >
                       <Modal.Title id="contained-modal-title">Add {modalTitle}</Modal.Title>
                       
                   </Modal.Header>
                   <Modal.Body>
                    <div className="formbox">
                        <label>Name </label>
                        <Input type="text" className="form-control" id="englishName" ref="englishName" onChange={this.handleAddOfficerChange} value={this.state.englishName} validations={[required]}/>          
                    </div>
                        <div className="formbox">
                            <label>Organizational Code</label>
                            <Input type="text" className="form-control" id="schoolCode" ref="schoolCode" onChange={this.handleAddOfficerChange} value={this.state.schoolCode} validations={[required]}/>
                        </div>
                        {/* <div className="formbox">
                            <label>Address</label>
                            <Input type="text" className="form-control" id="schoolAddress" ref="schoolAddress" onChange={this.handleAddOfficerChange} value={this.state.schoolAddress} validations={[required]}/>
                        </div> */}
                        <div className="formbox">
                            <label>Street Address</label>
                            <Input type="text" className="form-control" id="visit_street_address" ref="visit_street_address" onChange={this.handleAddOfficerChange} value={this.state.visit_street_address} validations={[required]} autoComplete="off"/>
                        </div>
                        <div className="formbox">
                            <label>Postal Code</label>
                            <Input type="text" className="form-control" id="visit_postal_Code" ref="visit_postal_Code" onChange={this.handleAddOfficerChange} value={this.state.visit_postal_Code} validations={[required]} autoComplete="off"/>
                        </div>
                        <div className="formbox">
                            <label>City</label>
                            <Input type="text" className="form-control" id="visit_city" ref="visit_city" onChange={this.handleAddOfficerChange} value={this.state.visit_city} validations={[required]} autoComplete="off"/>
                        </div>
                        <div className="formbox">
                            <label>Country</label>
                            <Input type="text" className="form-control" id="visit_country" ref="visit_country" onChange={this.handleAddOfficerChange} value={this.state.visit_country} validations={[required]} autoComplete="off"/>
                        </div>

                        <div className="formbox sameAddress">
                            <input type="checkbox" id="checkbox_address" ref="checkbox_address" onChange={this.handleAddOfficerChange} 
                            defaultChecked={this.state.addSchoolDynamicAdd == true} autoComplete="off"/>
                             <label>same as visit address</label>
                        </div>

                        <div className="formbox">
                            <label>Invoice Address</label>
                            <Input type="text" className="form-control" id="invoice_street_address" ref="invoice_street_address" onChange={this.handleAddOfficerChange} 
                            value={this.state.addSchoolDynamicAdd ? this.state.visit_street_address : this.state.invoice_street_address}
                            // value={this.state.invoice_street_address} 
                            validations={[required]} autoComplete="off"/>
                        </div>
                        <div className="formbox">
                            <label>Invoice Postal Code</label>
                            <Input type="text" className="form-control" id="invoice_postal_Code" ref="invoice_postal_Code" onChange={this.handleAddOfficerChange} 
                            // value={this.state.invoice_postal_Code} 
                            value={this.state.addSchoolDynamicAdd ? this.state.visit_postal_Code : this.state.invoice_postal_Code}
                            validations={[required]} autoComplete="off"/>
                        </div>
                        <div className="formbox">
                            <label>Invoice City</label>
                            <Input type="text" className="form-control" id="invoice_city" ref="invoice_city" onChange={this.handleAddOfficerChange} 
                            // value={this.state.invoice_city} 
                            value={this.state.addSchoolDynamicAdd ? this.state.visit_city : this.state.invoice_city} validations={[required]} autoComplete="off"/>
                        </div>
                        
                        <div className="formbox">
                            <label>Invoice Country</label>
                            <Input type="text" className="form-control" id="invoice_country" ref="invoice_country" onChange={this.handleAddOfficerChange} 
                            // value={this.state.invoice_country} 
                            value={this.state.addSchoolDynamicAdd ? this.state.visit_country : this.state.invoice_country}
                            validations={[required]} autoComplete="off"/>
                        </div>

                        <div className="formbox custom_datepicker">
                            <label><sup className="required_icon">*</sup> Select Expiry date</label>
                              <DatePicker
                                selected={this.state.date}
                                onChange={this.dateChange}
                                // dateFormat="MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                                // showMonthYearPicker
                                // showMonthDropdown
                                // dropdownMode="select"
                                // showYearDropdown
                                // maxDate={new Date()} //imp
                                // maxDate={(new Date(), 50)}
                                // maxDate={addMonths(new Date(), 1)} //working
                                // dateFormat="MMMM, yyyy"
                                minDate={new Date(date.getFullYear(), date.getMonth(), (date.getDate() + 1))}
                                // maxDate={moment().subtract(1, 'months')}
                                placeholderText="Select a date"
                              />
                        </div>

                   </Modal.Body>
                   <Modal.Footer>
                      <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
                  </Modal.Footer>
                  </Form>
                  <button className="btn btn-danger" onClick={this.closeForAddOfficer}>Close</button>
               </Modal>
           </div>
            

                <div className="modal-container" style={{ height: 200 }}>
                      <Modal
                      show={this.state.show}
                      container={this}
                      aria-labelledby="contained-modal-title">
                  
                      <Form ref={(form) => this.loginFormRef = form} className="resetPopup" name="loginForm" onSubmit={this.handleEditSubmit}>
                      <Modal.Header >
                          <Modal.Title id="contained-modal-title">Edit {modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="formbox">
                            <label>Name</label>
                            <Input type="text" className="form-control" id="editName" ref="editName" onChange={this.handleChange} value={this.state.editName} validations={[required]}/>
                        </div>
                        <div className="formbox">
                            <label>Organizational Code</label>
                            <Input type="text" className="form-control" id="editSchoolCode" ref="editSchoolCode" onChange={this.handleChange} value={this.state.editSchoolCode} validations={[required]}/>
                        </div>
                        <div className="formbox">
                            <label>Address</label>
                            <Input type="text" className="form-control" id="editAddrees" ref="editAddrees" onChange={this.handleChange} value={this.state.editAddrees} validations={[required]}/>
                        </div>

                        <div className="formbox">
                            <label>Postal Code</label>
                            <Input type="text" className="form-control" id="editPostalCode" ref="editPostalCode" onChange={this.handleChange} value={this.state.editPostalCode} validations={[required]}/>
                        </div>
                        <div className="formbox">
                            <label>City</label>
                            <Input type="text" className="form-control" id="editCity" ref="editCity" onChange={this.handleChange} value={this.state.editCity} validations={[required]}/>
                        </div>
                        <div className="formbox">
                            <label>Country</label>
                            <Input type="text" className="form-control" id="editCountry" ref="editCountry" onChange={this.handleChange} value={this.state.editCountry} validations={[required]}/>
                        </div>

                        <div className="formbox sameAddress">
                            {/* <input type="checkbox" className="form-control" id="checkbox_Invoiceaddress" ref="checkbox_address" onChange={this.handleChange} 
                            checked={this.state.editSchoolDynamicAdd == true} autoComplete="off"/> */}
                            <input type="checkbox" id="checkbox_Invoiceaddress" name="vehicle3" onClick={this.editCheckbox} defaultChecked={this.state.editSchoolDynamicAdd === true}/>
                            <label>same as visit address</label>
                        </div>

                        <div className="formbox">
                            <label>Invoice Address</label>
                            <Input type="text" className="form-control" id="editInAdd" ref="editInAdd" onChange={this.handleChange} 
                            // value={this.state.editInAdd}
                            value={this.state.editSchoolDynamicAdd ? this.state.editAddrees : this.state.editInAdd}

                             validations={[required]}/>
                        </div>
                        <div className="formbox">
                            <label>Invoice Postal Code</label>
                            <Input type="text" className="form-control" id="editInPostalCode" ref="editInPostalCode" onChange={this.handleChange} 
                            // value={this.state.editInPostalCode} 
                            value={this.state.editSchoolDynamicAdd ? this.state.editPostalCode : this.state.editInPostalCode}

                            validations={[required]}/>
                        </div>
                        <div className="formbox">
                            <label>Invoice City</label>
                            <Input type="text" className="form-control" id="editInCity" ref="editInCity" onChange={this.handleChange} 
                            // value={this.state.editInCity} 
                            value={this.state.editSchoolDynamicAdd ? this.state.editCity : this.state.editInCity}

                            validations={[required]}/>
                        </div>

                        <div className="formbox">
                            <label>Invoice Country</label>
                            <Input type="text" className="form-control" id="editInCountry" ref="editInCountry" onChange={this.handleChange} 
                            // value={this.state.editInCountry} 
                            value={this.state.editSchoolDynamicAdd ? this.state.editCountry : this.state.editInCountry}

                            validations={[required]}/>
                        </div>
                            
                        <div className="formbox custom_datepicker">
                            <label><sup className="required_icon">*</sup> Select Expiry date</label>
                              <DatePicker
                                selected={this.state.date}
                                onChange={this.dateChange}
                                // dateFormat="MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                                // showMonthYearPicker
                                // showMonthDropdown
                                // dropdownMode="select"
                                // showYearDropdown
                                // maxDate={new Date()} //imp
                                // maxDate={(new Date(), 50)}
                                // maxDate={addMonths(new Date(), 1)} //working
                                // dateFormat="MMMM, yyyy"
                                minDate={new Date(date.getFullYear(), date.getMonth(), (date.getDate() + 1))}
                                // maxDate={moment().subtract(1, 'months')}
                                placeholderText="Select a date"
                              />
                        </div>
                        
                      </Modal.Body>
                      <Modal.Footer>
                          <button type="submit" className="savebtn btn btn-secondary">Save</button>
                      </Modal.Footer>
                      </Form>
                      <button className="btn btn-danger" onClick={this.closeEditSchool}>Close</button>
                  </Modal>
              </div>
              </BlockUi>
          </div>
        )
    }
}

export default Organizational;