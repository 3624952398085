/* import react modules  */
import React from 'react';
import { Link } from 'react-router-dom';

/* import helper file */
import HelperFunction from '../../config/helperFunction';
import Constants from '../../config/constants';
import {  Card, CardBody, CardGroup, Col, Container, Row} from 'reactstrap';

/* modules for validation */
import { isEmail, isLength } from 'validator';

// import Validation from 'react-validation';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';

/* modules for Alerts */
import Alert from 'react-s-alert';
import BlockUi from 'react-block-ui';
// import 'react-block-ui/style.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          errors: {},
          email: "",
          password: "",
          blocking: false
        };
        /**** binding to make `this` work in the callback ***/
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
   
    /**** function to change form data ****/
    handleChange(event) {
      if (event.target.id === 'email') {
        this.setState({ email: event.target.value });
      } else {
        this.setState({ password: event.target.value });
      }
	  }

    /**** function to handle submit event ****/
    handleSubmit(event){
   
                       
                        localStorage.setItem("token", "token", 365);
                        localStorage.setItem("email", "email", 365);
                        localStorage.setItem("id", "id", 365);
                        this.props.propValue();
       



            //     event.preventDefault();
            //     let _that = this;
           
            // _that.setState({ blocking: true });
            //   /***** fetch API for login starts **********/
            //   fetch(Constants.SERVER_URL + "api/v1/sign-in/", {
            //     method: 'POST',
            //     headers: {
            //       'Accept': 'application/json',
            //       'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //       contactNumber: Number(_that.state.contactNo),
            //       password: _that.state.password.trim()
            //     }),
            //   }).then(function (response) {
        
            //     let responseData = response.json();
            //     responseData.then(function (data) {
            //       if (data.status == 200) {
                               
                    
            //                     if(data.data.officerDesignation.id !=  Constants.SUPER_ADMIN_ID){ // super admin
        
            //                         // Alert.success('<h6>' + data.message + '</h6>', {
            //                         //     position: 'top-right',
            //                         //     effect: 'slide',
            //                         //     beep: false,
            //                         //     timeout: 2000
            //                         // });
            
            //                     //     localStorage.setItem("superAdminToken", data.token, 365);
            //                     //     localStorage.setItem("superAdminName", data.data.name, 365);
            //                     //     localStorage.setItem("superAdminNumber", data.data.contactNumber, 365);
            //                     //     localStorage.setItem("superAdminId", data.data.officerDesignation.id, 365);
            //                     //     _that.props.updateSuperAdminState();
            //                     // } else{ // admin DM
            
                         
            //                     } else{
            //                         Alert.error('<h6>' + Constants.Invaild_Credential + '</h6>', {
            //                             position: 'top-right',
            //                             effect: 'slide',
            //                             beep: false,
            //                             timeout: 2000
            //                         });
            //                     }
            //         // _that.resetForm();
            //         _that.setState({ blocking: false });
            //                     // _that.props.propValue.history.push("/dashboard");
            //                     // window.location = "/dashboard";
            //         // localStorage.removeItem("corporateEmail");
            //       } 
            //       else {
            
            //         _that.setState({ blocking: false });
            //         Alert.error('<h6>' + data.message + '</h6>', {
            //           position: 'top-right',
            //           effect: 'slide',
            //           beep: false,
            //           timeout: 2000
            //         });
            //       }
            //     })
            //   }).catch(function (error) {
            
            //     _that.setState({ blocking: false });
            //     if (navigator.onLine) {
            //       Alert.error('<h6>' + Constants.SERVER_CONNECTION_ISSUE + '</h6>', {
            //         position: 'top-right',
            //         effect: 'slide',
            //         beep: false,
            //         timeout: 3000
            //       });
            //     } else {
            //       Alert.error('<h6>' + Constants.INTERNET_CONNECTION_ISSUE + '</h6>', {
            //         position: 'top-right',
            //         effect: 'slide',
            //         beep: false,
            //         timeout: 3000
            //       });
            //     }
            //   });



    }

    /**** render view  ****/
    render() {  

    const required = (value, props) => {
			if (!value || (props.isCheckable && !props.checked)) {
				return <span className="form-error is-visible">Required</span>;
			}
		};

		const email = (value) => {
			if (!isEmail(value)) {
				return <span className="form-error is-visible">Please enter a valid Email</span>;
			}
		};

		const length = (value, props) => {
			if (!isLength(value, { min: 6 })) {
				return <span className="form-error is-visible">Password should contain atleast 6 characters</span>;
			}
		};

		const maxLength = (value, props) => {
			if (!isLength(value, { max: 32 })) {
				return <span className="form-error is-visible">Password length exceeds maximum allowed, 32 characters</span>;
			}
		};
        return ( 
          <div className="app flex-row align-items-center loginpage">
            <Container>
            <ToastContainer/>

            {/* <BlockUi blocking={this.state.blocking}> */}
              <Row className="justify-content-center">
              <Alert html={true} />
               <Col md="12">
                <CardGroup>
                  <Card>
                    <CardBody>
                      <Row className="justify-content-center">
                        <Col md="7" className="loginleft">
                            <Link to="/" className="navbar-brand">
                              <img src="../../assets/img/logo-inner.png" alt="madetogrow" className="navbar-brand-full" />
                            </Link>
                            <div className="welcometext">
                                <h1>Welcome to MadeTo Grow</h1>
                                <p>Log in to access your account</p>
                            </div>
                        </Col>
                        <Col md="5">
                          <div className="loginform">
					                  <Form ref={(form) => this.loginFormRef = form} name="loginForm" onSubmit={this.handleSubmit}>
                                <h1>Log in</h1>
                                <Input type="text" placeholder="Email address" name="email" id="email" ref="email" className="text" onChange={this.handleChange} validations={[required, email]} value={this.state.email}/>
                                <Input type="password" placeholder="Password" name="password" id="vendorPassword" ref="vendorPassword" onChange={this.handleChange} validations={[required, length, maxLength]} value={this.state.password}/>
                                <Button id="login" >Log in</Button>
                                <Row>
                                    <Col xs="6" className="keep_mesigned">
                                        <input type="checkbox"/><span> Keep me signed in</span>                                  
                                    </Col>
                                    <Col xs="6" className="text-right forgetpasswordtext">
                                       <Link to="/forgetPassword">Forgot password?</Link>
                                    </Col>
                                </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            {/* </BlockUi> */}
          </Container>
        </div>
        );
    }
}
 
export default Login;