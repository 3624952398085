/* import react modules  */
import React, { Component, Suspense } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';

// import { AppHeaderDropdown } from '@coreui/react';
// import { Container } from 'reactstrap';
// import { AppSidebarNav } from '@coreui/react';
import {AppSidebar} from '@coreui/react';
// import navigation from './nav';

class DefaultSidebarnav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: [false, false],
    };

    /**** binding to make `this` work in the callback ***/
    this.toggle = this.toggle.bind(this);
  }

  /*** Function For Toggle data ***/
  toggle(i) {
    const newArray = this.state.dropdownOpen.map((element, index) => {
      return (index === i ? !element : false);
    });
    this.setState({
      dropdownOpen: newArray,
    });
  }

  /**** render view  ****/
  render() {
    if(this.props.isHeader === ''){
      
      return(
        <div></div>
      )
    }
    
    else if(this.props.isHeader === 'SUPERADMIN'){
    return(  <AppSidebar fixed display="lg">   
      <div className="sidebarnaigation">
       <Suspense>
          <div className="scrollbar-container sidebar-nav ps ps-container">        
            <Nav>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/dashboard">
                <i className="fa fa-dashboard"></i> Dashboard</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/organisation">
                <i className="fa fa-university"></i> School & Organization</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/users">
                <i className="fa fa-users"></i> Users</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" 
                // to="/admin/privateusers" 
                to={{pathname: "/admin/privateusers",
                      privateusersProps: {
                        name: "Suru New Props"
                      }
                }}  >
                <i className="fa fa-user-secret"></i> Private Users</NavLink>
              </NavItem>

              {/* <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/themes">
                <i className="fa fa-th-large"></i> Themes</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/programs">
                <i className="fa fa-th-large"></i> Programs</NavLink>
              </NavItem> */}

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/task">
                <i className="fa fa-tasks"></i> Task Management</NavLink>
              </NavItem>
             
              
              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/subscription">
                <i className="fa fa-credit-card" aria-hidden="true"></i> Subscription</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/admin/lifeResetUsers">
                <i className="fa fa-address-card-o" aria-hidden="true"></i> Life Reset-users</NavLink>
              </NavItem>
              
            </Nav>
          </div>
        </Suspense>
      </div>
      </AppSidebar>
    )
    }
    else{
      
    return (
      <AppSidebar fixed display="lg">   
      <div className="sidebarnaigation">
       <Suspense>
          <div className="scrollbar-container sidebar-nav ps ps-container">            
            <Nav>
              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/dashboard">
                <img src="../../assets/img/navicon/dashboard.png" className="navicon" alt="Dashboard"/>
                Dashboard</NavLink>
              </NavItem>

             <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/setting">
                <img src="../../assets/img/navicon/activity.png" className="navicon" alt="Setting"/>
                Setting</NavLink>
              </NavItem>

            {/*  <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/sales">
                <img src="../../assets/img/navicon/sales.png" className="navicon" alt="Sales"/>
                <img src="../../assets/img/navicon/sales_active.png" className="navicon_active" alt="Sales"/>
                Sales</NavLink>
              </NavItem>

               <Dropdown nav isOpen={this.state.dropdownOpen[0]} toggle={() => {this.toggle(0);}}>
                <DropdownToggle nav caret>
                <img src="../../assets/img/navicon/support.png" className="navicon" alt="Support"/>
                <img src="../../assets/img/navicon/support_active.png" className="navicon_active" alt="Support"/>
                Support
                </DropdownToggle>
                <DropdownMenu>
                  <NavLink className="nav-links" activeClassName="active" to="/support-issue">Issues</NavLink>
                  <NavLink className="nav-links" activeClassName="active" to="/support-staff">Staff</NavLink>
                </DropdownMenu>
              </Dropdown>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/resellers">
                <img src="../../assets/img/navicon/resellers.png" className="navicon" alt="Resellers"/>
                <img src="../../assets/img/navicon/resellers_active.png" className="navicon_active" alt="Resellers"/>
                Resellers</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/plants">                
                <img src="../../assets/img/navicon/plants.png" className="navicon" alt="Plants"/>
                <img src="../../assets/img/navicon/plants_active.png" className="navicon_active" alt="Plants"/>
                Plants</NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/quantity">                
                <img src="../../assets/img/navicon/quantity_white.png" className="navicon" alt="Quantity"/>
                <img src="../../assets/img/navicon/quantity_blue.png" className="navicon_active" alt="Quantity"/>
                Quantities</NavLink>
              </NavItem>

              <Dropdown nav isOpen={this.state.dropdownOpen[1]} toggle={() => {this.toggle(1);}}>
                <DropdownToggle nav caret>
                <img src="../../assets/img/navicon/users.png" className="navicon" alt="Users"/>
                <img src="../../assets/img/navicon/users_active.png" className="navicon_active" alt="Users"/>
                Users
                </DropdownToggle>
                <DropdownMenu>
                  <NavLink className="nav-links" activeClassName="active" to="/user-admin">Admin</NavLink>
                  <NavLink className="nav-links" activeClassName="active" to="/user-staff">Support staff</NavLink>
                </DropdownMenu>
              </Dropdown>

              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/user-guide">
                <img src="../../assets/img/navicon/userguide.png" className="navicon" alt="userguide"/>
                <img src="../../assets/img/navicon/userguide_active.png" className="navicon_active" alt="userguide"/>
                User guide</NavLink>
              </NavItem> */}
            </Nav>
          </div>
        </Suspense>
        {/* <NavLink className="ml-auto sidebar-minimizer mt-auto" to="/setting" activeClassName="active"><img src={'../../assets/img/avatars.jpg'} className="img-avatar" alt="" /> <i className="fa fa-chevron-right"></i>Account Settings</NavLink> */}
      </div>
      </AppSidebar>
    );
    }
  }
}

export default DefaultSidebarnav;
