import React from 'react';
import {
     Badge,
    ButtonGroup,ButtonToolbar,Card,CardBody,CardTitle,Col,Row,Table,
    // FormGroup,Input,InputGroup,InputGroupAddon
    } from 'reactstrap';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Constants from '../../../config/constants';
import $ from 'jquery'; 
import BlockUi from 'react-block-ui';

import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';

/* modules for validation */
import { isEmail, isLength, isNumeric, isAlpha } from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Moment from 'react-moment';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";

class LifeResetUsers extends React.Component{
    constructor(props){
        super(props);
        let superAdminId = localStorage.getItem("superAdminId");
        let superAdminToken = localStorage.getItem("superAdminToken");

        this.state ={
            superAdminId: superAdminId,
            superAdminToken: superAdminToken,
            page: 1,

            search: "",
            delayTimer: 0,
            
            blocking: false,
            showForAddSchool: false,
           
            show: false,
            editName: "",
            editRadio: "",
            editYOB: "",
            
            editUserName: "",
            editEmail: "",
            editPass: "",
            currentSchoolId: 0,
            selectedState: null,
            stateOptions: [],
            userListing: [],
            currentEditId: 0,

            activePage: 1,
            pageSize: 10,
            finalPagesCount: 1,
            selectedDate: "",
            date: "" ,
            dob: {},
            day: 0,
            month: 0,
            year: 0,
            editIsprivate: false,

            labelHolder: 1,

            currentSelectRole: "",
            selectRoleData: [{lable: "STUDENT", value: "STUDENT"},
            {lable: "TEACHER", value: "TEACHER"},
            {lable: "LEADER", value: "LEADER"},
            {lable: "EMPLOYEE", value: "EMPLOYEE"},
            {lable: "OTHER", value: "OTHER"}]
        }
        this.schoolList = this.schoolList.bind(this);
        // this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.showEditSchool = this.showEditSchool.bind(this);
        this.closeEditSchool = this.closeEditSchool.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.userList = this.userList.bind(this);
        this.resetEditUser = this.resetEditUser.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.searchHandle = this.searchHandle.bind(this);
        this.newEditSubmit = this.newEditSubmit.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.downloadUsersFile = this.downloadUsersFile.bind(this);
        this.confirmCancelSubscription = this.confirmCancelSubscription.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        // this.confirmCopyPrivate = this.confirmCopyPrivate.bind(this);
        // this.copyPrivate = this.copyPrivate.bind(this);
      }


    	/**** Component life cycle method ***/
        componentWillMount() {
            // this.schoolList(); //get schoolList Details
            this.userList();
        }

        // componentDidMount() {
        //   window.scrollTo(0, 0)
        // }

    // get school listing
    schoolList() {
          
          var _that = this;
          var param = { page_number: _that.state.page, page_size: _that.state.pageSize, account_type: "SCHOOL"
            // , search: _that.state.search.trim(), designationId: _that.state.selectId
        }
          _that.setState({ blocking: true });
       
          fetch(Constants.SERVER_URL + "admin/accounts?" + $.param(param), {
            method: 'GET', 
            headers: {                
              "Accept": "application/json",
              "Content-type": "application/json",
              "Authorization": _that.state.superAdminToken
            }
          }).then(function (response) {
            
            if(response.status === 200){
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
         
                // if (data.data.length == _that.state.pageSize) {
                //   _that.setState({
                //     isMore: true
                //   });
                // } else {
                //   _that.setState({
                //     isMore: false
                //   });
                // }

                if (_that.state.page == 1) {
                    
                  _that.setState({ blocking: false, 
                    stateOptions: data.data 
                  });
                  
                }
                else if (data.data.length > 0) {
                  let oldData = JSON.parse(JSON.stringify(_that.state.stateOptions));
                  let newData = JSON.parse(JSON.stringify(data.data));
                  let totalData = oldData.concat(newData);
  
                  _that.setState({ stateOptions: totalData, blocking: false });
                } else if (_that.state.searchExist == false) {
                  
                  let oldData = JSON.parse(JSON.stringify(_that.state.stateOptions));
                  let newData = JSON.parse(JSON.stringify(data.data));
                  let totalData = oldData.concat(newData);
  
                  _that.setState({ stateOptions: totalData, blocking: false });
                } else {
                  _that.setState({ blocking: false, stateOptions: data.data, page: 1 });
                }
                
              })
            
            } else{
              let responseData = response.json();
              responseData.then(function (data) {
                
                _that.setState({ blocking: false});
               
                Alert.error('<h5>' + data.message + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                });
              })
            }
          }).catch(function (error) {
            _that.setState({ blocking: false });
            if (navigator.onLine) {
              Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            } else {
              Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            }
          });

    }


     // get user listing
     userList() {
        
        var _that = this;
        var param = { page_number: _that.state.activePage, page_size: _that.state.pageSize
          //  school_id: school_id
          , search_text: _that.state.search.trim()
          //, designationId: _that.state.selectId
      }
        _that.setState({ blocking: true });
     
        fetch(Constants.SERVER_URL + "admin/life-reset-users?" + $.param(param), {
          method: 'GET', 
          headers: {                
            "Accept": "application/json",
            "Content-type": "application/json",
            "Authorization": _that.state.superAdminToken
          }
        }).then(function (response) {
        
          if(response.status === 200){
            let responseData = response.json();
            responseData.then(function (data) {
            
              _that.setState({ blocking: false});
       
              // if (data.data.length == _that.state.pageSize) {
              //   _that.setState({
              //     isMore: true
              //   });
              // } else {
              //   _that.setState({
              //     isMore: false
              //   });
              // }

              // if (_that.state.page == 1) {
                //  let pagesCount;
                //  pagesCount = Math.ceil( (data.totalSubscriptions) / _that.state.pageSize );
                _that.setState({ blocking: false, 
                  userListing: data.data, finalPagesCount: data.totalUsers 
                });
              // }
              // else if (data.data.length > 0) {
              //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
              //   let newData = JSON.parse(JSON.stringify(data.data));
              //   let totalData = oldData.concat(newData);

              //   _that.setState({ userListing: totalData, blocking: false });
              // } else if (_that.state.searchExist == false) {
                
              //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
              //   let newData = JSON.parse(JSON.stringify(data.data));
              //   let totalData = oldData.concat(newData);

              //   _that.setState({ userListing: totalData, blocking: false });
              // } else {
              //   _that.setState({ blocking: false, userListing: data.data, page: 1 });
              // }
              
            })
          
          } else{
            let responseData = response.json();
            responseData.then(function (data) {
              
              _that.setState({ blocking: false});
             
              Alert.error('<h5>' + data.message + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            })
          }
        }).catch(function (error) {
          _that.setState({ blocking: false });
          if (navigator.onLine) {
            Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          } else {
            Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          }
        });
  }


    confirmDeleteUser = (id) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='confirmpopup custom-ui'>
              <div className="con_header">
              {/* <h1>Confirm to Block tap {tap_number} on plant {this.state.currentPlantName}?</h1> */}
                <h1>Delete User</h1>
              </div>
              <div className="con_body">
                <p>Are you sure, you want to Delete User?</p>
              </div>
              <div className="con_footer">
              <button className="nobtn" onClick={onClose}>Cancel</button>
                {/* <button className="nobtn" onClick={() => {this.suru();}}>Cancel</button> */}
                <button onClick={() => {
                  this.deleteUser(id);
                  // alert("test");
                  onClose()
                }}>Delete</button>
              </div>
            </div>
          )
        },
        closeOnClickOutside: false
      })
    };

    // API function to Delete Life Reset Users
    deleteUser(id){
      var _that = this;
      if(id){
          _that.setState({ blocking: true });
          // let param = {id: id}
          fetch(Constants.SERVER_URL + "admin/user/life-reset/" + id, {
            method: 'DELETE',
            headers: {                
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": _that.state.superAdminToken
            }
          }).then(function (response) {
         
            if(response.status === 204 || response.status === 200){
              // let responseData = response.json();
              // responseData.then(function (data) {
                _that.userList();
                Alert.success('<h5>' + "Successfully deleted" + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 2000,
                  offset: Constants.OffSetValue
                });
              // })
            } 
            
            else if(response.status === 401){
              // let responseData = response.json();
              // responseData.then(function (data) {
                _that.setState({ blocking: false});
                Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 2000,
                  offset: Constants.OffSetValue
                });
                
              // })
            } else{
              // let responseData = response.json();
              // responseData.then(function (data) {
                _that.setState({ blocking: false});
                Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 2000,
                  offset: Constants.OffSetValue
                });
              // })
            }
          }).catch(function (error) {
            
            _that.setState({ blocking: false });
            if (navigator.onLine) {
              Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            } else {
              Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            }
          });
        } else{
          Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
    }

    // show function for edit School popup
    showEditSchool(stateData){
        let editedDate, StartDate, selectedDate, custom_user_role;

        if(stateData.user_role != null){
            custom_user_role = stateData.user_role;
        }else{
            custom_user_role = "";
        }
        
        if(stateData.subscription_expiry != null){
          editedDate = stateData.subscription_expiry;
  
          StartDate = moment(editedDate, "DD/MM/YYYY").valueOf();
          selectedDate = moment(StartDate).format("DD/MM/YYYY");
          // let newDate = new Date((StartDate/1000));
        }else{
          StartDate = "";
          selectedDate = "";
        }
        this.setState({show: true, 
          currentEditId: stateData.id, editName: stateData.name,  
          //   ,editUserName: stateData.user_name,
          editRadio: (stateData.gender).toUpperCase(),
          editEmail: stateData.email,
          editYOB: stateData.year_of_birth,
          date : StartDate, 
          selectedDate: selectedDate,
          editIsprivate: stateData.is_private,
          currentSelectRole: custom_user_role
        });
      }

    // close function for add state popup
    closeEditSchool(event){
      this.setState({ show: false
        // , currentIdForAddState: "" 
      });
      this.resetEditUser();
    };

    handleChange(event) {
        event.preventDefault();
        if (event.target.id === "editName") {
          this.setState({ editName: event.target.value });
        } else if (event.target.id === "editRadio1") {
          this.setState({ editRadio: "MALE" });
        } else if (event.target.id === "editRadio2") {
          this.setState({ editRadio: "FEMALE" });
        } else if (event.target.id === "editRadio3") {
          this.setState({ editRadio: "OTHER" });
        } else if (event.target.id === "editYOB") {
          this.setState({ editYOB: event.target.value });
        }  else if (event.target.id === "editEmail") {
          this.setState({ editEmail: event.target.value });
        } else if (event.target.id === "editPass") {
          this.setState({ editPass: event.target.value });
        } else if (event.target.id === "editUserName") {
          this.setState({ editUserName: event.target.value });
        }
        return true;
    } 

    resetEditUser(){
      this.setState({
      editName: "",
      editRadio: "",
      editYOB: "",
      editEmail: "",
      editPass: "",
      editIsprivate: false});
    }

    selectState = selectedState => {
        this.setState({ selectedState }
        , () => { 
            this.userList(selectedState.id); 
        this.setState({ currentSchoolId: selectedState.value}) 
        }
        );
    };

    // // is more button functionality
    // isMoreData(){
    //   this.setState({page: this.state.page + 1},() => {this.userList()});
    // }

    // for pagination
    handlePageChange(pageNumber) {
      // this.setState({activePage: pageNumber});
      this.setState({activePage: pageNumber},() => {this.userList()});
    }

     /**** function to search Handle event ****/
     searchHandle(event) {
        if (event.target.id == "search") {
          clearTimeout(this.state.delayTimer);
          this.setState({ search: event.target.value }, () => {
            this.setState({
              delayTimer: setTimeout(function () {
                // this.officerList();
                this.userList();
              }.bind(this), 700),
              activePage: 1
            });
          })
        }
      }

      newEditSubmit(event){
        event.preventDefault();
        var _that = this;
    
        if (_that.state.editName.trim() != undefined && _that.state.editName.trim() != null && _that.state.editName.trim() != "") {
          if (_that.state.selectedDate != undefined && _that.state.selectedDate != null && _that.state.selectedDate != "") {
        _that.setState({ blocking: true });
                
        fetch(Constants.SERVER_URL + "admin/user/" + _that.state.currentEditId, {
            method: 'PUT', 
            headers: {                
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": _that.state.superAdminToken
            },
            body:JSON.stringify({
                name: _that.state.editName.trim(),
                // user_name: _that.state.editUserName.trim(),
                gender: _that.state.editRadio,
                year_of_birth: Number(_that.state.editYOB),
                email: _that.state.editEmail,
                // password: _that.state.editPass,
                account_id: 0,
                is_private: _that.state.editIsprivate,
                subscription_expiry: _that.state.selectedDate,
                user_role: _that.state.currentSelectRole
            }),
          }).then(function (response) {
          
            if(response.status === 204){
              // let responseData = response.json();
              // responseData.then(function (data) {
               
                _that.setState({ blocking: false,  show: false});
                _that.resetEditUser();
                _that.userList();
    
                Alert.success('<h5>' + "Success" + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 2000,
                  offset: Constants.OffSetValue
                });
                
              // })
            
            } else{
              // let responseData = response.json();
              // responseData.then(function (data) {
                
                _that.setState({ blocking: false,  show: false});
                _that.resetEditUser();
                Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
                  position: 'top-right',
                  effect: 'slide',
                  beep: false,
                  timeout: 3000,
                  offset: Constants.OffSetValue
                // });
                })
            }
          }).catch(function (error) {
            _that.setState({ blocking: false,  show: false });
            _that.resetEditUser();
            if (navigator.onLine) {
              Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            } else {
              Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
                position: 'top-right',
                effect: 'slide',
                beep: false,
                timeout: 3000,
                offset: Constants.OffSetValue
              });
            }
          });
    
        } else{
          Alert.error('<h5>' + "Please Select Expiry date" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
    
        } else{
          
          Alert.error('<h5>' + "Name Shouldn't be blank" + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
          });
      }
        
      }

      /**** function to get date of birth ****/
  	dateChange(date){
      let selectedDate = moment(date).format("DD/MM/YYYY");
  
        this.setState({
            date: date, selectedDate: selectedDate
        }, function(){
        let dob = moment(this.state.date).format("DD-MM-YYYY");
        
          this.setState({day: dob.split("-")[0], month: dob.split("-")[1], year: dob.split("-")[2] });
        });
      }

  //     // move to private user
  //     confirmCopyPrivate(id) {
  //       confirmAlert({
  //         customUI: ({ onClose }) => {
  //           return (
  //             <div className='confirmpopup custom-ui'>
  //               <div className="con_header">
  //               {/* <h1>Confirm to Block tap {tap_number} on plant {this.state.currentPlantName}?</h1> */}
  //                 <h1>Copy to Private User</h1>
  //               </div>
  //               <div className="con_body">
  //                 <p>Are you sure, you want to Copy to Private User?</p>
  //               </div>
  //               <div className="con_footer">
  //               <button className="nobtn" onClick={onClose}>Cancel</button>
  //                 {/* <button className="nobtn" onClick={() => {this.suru();}}>Cancel</button> */}
  //                 <button onClick={() => {
  //                   this.copyPrivate(id);
  //                   // alert("test");
  //                   onClose()
  //                 }}>Copy</button>
  //               </div>
  //             </div>
  //           )
  //         },
  //         closeOnClickOutside: false
  //       })
  //     };


  //   // API function to copy to Private User
  // copyPrivate(id){
  //   var _that = this;
  //   // if (_that.state.currentSchoolMigrateId != undefined && _that.state.currentSchoolMigrateId != null && _that.state.currentSchoolMigrateId != 0) {
    
  //   _that.setState({ blocking: true });
            
  //   fetch(Constants.SERVER_URL + "admin/migrate/user", {
  //       method: 'POST', 
  //       headers: {                
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //           "Authorization": _that.state.superAdminToken
  //       },
  //       body:JSON.stringify({
  //         user_id: id,
  //         // account_id: _that.state.currentSchoolMigrateId,
  //         migrateTo: "PRIVATE"
  //       }),
  //     }).then(function (response) {
      
  //       if(response.status === 200){
  //         let responseData = response.json();
  //         responseData.then(function (data) {
           
  //           _that.setState({ blocking: false});
            
  //           _that.userList();
            
  //           Alert.success('<h5>' + data.message + '</h5>', {
  //             position: 'top-right',
  //             effect: 'slide',
  //             beep: false,
  //             timeout: 4000,
  //             offset: Constants.OffSetValue
  //           });
            
  //         })
        
  //       } else{
  //         let responseData = response.json();
  //         responseData.then(function (data) {

  //           _that.setState({ blocking: false });
           
  //           Alert.error('<h5>' + data.message + '</h5>', {
  //             position: 'top-right',
  //             effect: 'slide',
  //             beep: false,
  //             timeout: 3000,
  //             offset: Constants.OffSetValue
  //           });
  //         })
  //       }
  //     }).catch(function (error) {
  //       _that.setState({ blocking: false });
        
  //       if (navigator.onLine) {
  //         Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
  //           position: 'top-right',
  //           effect: 'slide',
  //           beep: false,
  //           timeout: 3000,
  //           offset: Constants.OffSetValue
  //         });
  //       } else {
  //         Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
  //           position: 'top-right',
  //           effect: 'slide',
  //           beep: false,
  //           timeout: 3000,
  //           offset: Constants.OffSetValue
  //         });
  //       }
  //     });

  //       // } else{
  //       //     Alert.error('<h5>' + "Select School or Organization first" + '</h5>', {
  //       //         position: 'top-right',
  //       //         effect: 'slide',
  //       //         beep: false,
  //       //         timeout: 3000,
  //       //         offset: Constants.OffSetValue
  //       //     });
  //       // }
  //   }


  // download file api for private users
  downloadUsersFile(){

      var _that = this;
  
      var param = { type: "LIFERESET-USER", account_id: 0}
   
      _that.setState({ blocking: true });
      fetch(Constants.SERVER_URL + "admin/export-users-data?" + $.param(param), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": _that.state.superAdminToken
        }
      }).then(function (response) {
        if (response.status === 204 || response.status === 200) {
          _that.setState({ blocking: false });
          Alert.success('<h5>' + "Email Sent Successfully" + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
        }
  
        else if (response.status === 401) {
          _that.setState({ blocking: false });
          Alert.error('<h5>' + Constants.Unauthorized_Access + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
        } else {
          _that.setState({ blocking: false });
          Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
        }
      }).catch(function (error) {
  
        _that.setState({ blocking: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });
  }

  // confirm cancel sunscription popup
  confirmCancelSubscription(data){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirmpopup custom-ui'>
            <div className="con_header">
              <h1>Cancel Subscription</h1>
            </div>
            <div className="con_body">
              <p>Are you sure, you want to cancel subscription for this User?</p>
            </div>
            <div className="con_footer">
            <button className="nobtn" onClick={onClose}>Close</button>
              <button onClick={() => {
                this.cancelSubscription(data.id);
                onClose()
              }}>Submit</button>
            </div>
          </div>
        )
      },
      closeOnClickOutside: false
    })
  }

  // cancel subscription 
  cancelSubscription(id){
    var _that = this;
    var _that = this;
  
    if (id) {
      
      _that.setState({ blocking: true });
      
      fetch(Constants.SERVER_URL + "admin/cancel-private-user-subscription", {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": _that.state.superAdminToken
        },
        body: JSON.stringify({
          userId:id
        }),
      }).then(function (response) {
        if (response.status === 204 || response.status === 200) {
          let responseData = response.json();
          responseData.then(function (data) {
            _that.setState({ blocking: false });
            _that.userList();
            Alert.success('<h5>' + data.message + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 2000,
              offset: Constants.OffSetValue
           });
          })
        }
        else if (response.status === 401 || response.status === 404 ) {
           let responseData = response.json();
          responseData.then(function (data) {
            _that.setState({ blocking: false });
            _that.userList();
            Alert.error('<h5>' + data.message + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 2000,
              offset: Constants.OffSetValue
           });
          })

        } else {
          _that.setState({ blocking: false });
          Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 2000,
            offset: Constants.OffSetValue
          });
        }
      }).catch(function (error) {
        
        _that.setState({ blocking: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });
    } else {
      Alert.error('<h5>' + Constants.Something_Wrong + '</h5>', {
        position: 'top-right',
        effect: 'slide',
        beep: false,
        timeout: 3000,
        offset: Constants.OffSetValue
      });
    }
  }


    render(){
        const { selectedState, stateOptions, userListing, editRadio } = this.state;

        //imp for date picker
        var date = new Date();
        let isMorebtn;
        if(userListing.length > 0){
          isMorebtn =  <Pagination
          // prevPageText='prev'
          // nextPageText='next'
          // firstPageText='first'
          // lastPageText='last'
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.pageSize}
          totalItemsCount={this.state.finalPagesCount}
          // pageRangeDisplayed={5}
          onChange={this.handlePageChange}
        />
        } else{
            isMorebtn = ""
        }

        const required = (value, props) => {
          if (!value || (props.isCheckable && !props.checked)) {
            return <span className="validationForm">Required</span>;
          }
        };

        const minContactNo = (value, props) => {
          if (!isLength(value, { min: 10 })) {
            return <span className="validationForm">Please Enter a Valid Contact Number</span>;
          }
        };
    
        const maxContactNo = (value, props) => {
            if (!isLength(value, { max: 10 })) {
                return <span className="validationForm">Please Enter a Valid Contact Number</span>;
            }
        };
    
        const minNo = (value, props) => {
            if (!isLength(value, { min: 6 })) {
              return <span className="validationForm">Please Enter Min six character</span>;
            }
        };
    
        const maxNo = (value, props) => {
            if (!isLength(value, { max: 32 })) {
                return <span className="validationForm">Max password length exceed</span>;
            }
        };
    
        const number = (value) => {
          if (!isNumeric(value)) {
            return <span className="validationForm">Please Enter a Number</span>;
          }
        };

        const email = (value) => {
          if (!isEmail(value)) {
            return <span className="form-error is-visible">Please enter a valid Email</span>;
          }
        };

        let selectRole;
        selectRole = this.state.selectRoleData.map((list, index) => 
          (
            <option value={list.value} key={index}>{list.lable}</option>
          )
        )

        let schoolListing, schoolAlldata, name, plan_validity, expire_date, userEmail, date_subscribed, platform, schoolId,
        userGender, year_of_birth, user_role, belongTo, subscription,editbtn, isSubscribedbtn;
        schoolListing = userListing.map((list, index) => {

            // if(list.status === 2){
            //   forgetPassStatus = <button type="button" className="btn btn-warning officerCursor">Yes</button>;
            // }else{
            //   forgetPassStatus = <div className="officerStatus">No</div>
            // }
  
                    //   if (list.status == 1) {
                    //       orderStatusView = <b className="btn btn-info">Pending</b>;
                    //   } else if (list.status == 2) {
                    //       orderStatusView = <b className="btn btn-success">Completed</b>;
                    //   } else if (list.status == 3) {
                    //       orderStatusView = <b className="btn btn-danger">Cancel</b>;
                    //   } 


                      if(list.id != null){
                        schoolId = list.id;
                      } else{
                        schoolId = "-";
                      }
                      
                      if(list.name != null){
                        name = list.name;
                      } else{
                        name = "-";
                      }

                      if(list.email != null){
                        userEmail = list.email;
                      }else{
                          userEmail = "-";
                      }
                      
                      if(list.plan_validity != null  && list.platform != "MadeToGrow"){
                        plan_validity = list.plan_validity + " month";
                      } else{
                        plan_validity = "-";
                      }
  
                        if(list.date_subscribed != null){
                          
                            date_subscribed=  <Moment format="DD/MM/YYYY">{Number(list.date_subscribed)}</Moment>;
                            // date_subscribed=moment(Number(list.date_subscribed)).format('l');
                        } else{
                          
                            date_subscribed=  "-"
                        }

                       

                        if(list.platform != null ){
                          platform = list.platform;
                        }else{
                            platform = "-";
                        }

                        if(list.gender != null ){
                            userGender = list.gender;
                          }else{
                            userGender = "-";
                          } 

                          if(list.year_of_birth != null && list.year_of_birth != 0){
                            year_of_birth = list.year_of_birth;
                          }else{
                            year_of_birth = "-";
                          } 

                          if(list.user_role != null ){
                            user_role = list.user_role;
                          }else{
                            user_role = "-";
                          } 

                          if(list.subscription_expiry != null){
                            expire_date = list.subscription_expiry;
                          }else{
                              expire_date = "-";
                          } 

                          if(list.belongTo != null && list.belongTo != ""){
                            belongTo = list.belongTo;
                          }else{
                            belongTo = "-";
                          } 

                          if(list.is_private == true || list.is_life_reset == true){
                            editbtn = <button type="button" className="btn btn-info " title="Edit" onClick={() => {this.showEditSchool(list)}} data-toggle="modal" data-target="#myModal"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>;
                          }else{
                            editbtn = "";
                          } 

                          if(list.is_subscribed == true){
                            isSubscribedbtn = <button type="button" className="btn btn-success labelbtns" >Yes</button>;
                          }else{
                            isSubscribedbtn = <button type="button" className="btn btn-info labelbtns" >No</button>;
                          } 
                          if(list.is_subscribed == true){
                            subscription = <button type="button" className="btn btn-danger" onClick={() => {this.confirmCancelSubscription(list)}}>Cancel</button>
                          }else{
                            subscription = <Badge color="warning"  className="subscription-badge labelbtns" >Already Cancelled</Badge>
                          }
                        

                      return (
                          <tr key={index} >
                              <td>{schoolId}</td>
                              <td>{name}</td>
                              <td>{userEmail}</td>
                              <td>{userGender}</td>
                              <td>{year_of_birth}</td>
                              <td>{user_role}</td>
                              <td>{expire_date}</td>
                              <td>{belongTo}</td>
                              <td>{isSubscribedbtn}</td>
                              <td>
                              {editbtn}
                              {/* <button type="button" className="btn btn-secondary" title="Copy to Private user" onClick={() => {this.confirmCopyPrivate(list.id)}}><i className="fa fa-share-square-o" aria-hidden="true"></i></button>*/}
                              <button className="btn btn-danger" title="Delete" onClick={() => {this.confirmDeleteUser(list.id)}}><i className="fa fa-trash" aria-hidden="true"></i></button>  
                              </td>
                             <td>{subscription}</td>
                          </tr>
                      )
                  })

          if(userListing.length > 0){
              schoolAlldata =	<div className="table-responsive"><table className="table table-striped">
              <thead>
                  <tr>
                    <th>User Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Gender</th>
                    <th>Year Of Birth</th>
                    {/* <th>Spent Time In App <br></br>(HH:MM)</th> */}
                    <th>Role</th> 
                    <th>Subscription Expiry Time</th>
                    {/* <th>Verification Status</th> */}
                    {/* <th>Forget Password Request</th> 
                    <th>Password</th> */}
                    <th>Associated With</th>
                    <th>Subscribed</th>
                    <th>Actions</th>
                    <th>Subscription</th>
                  </tr>
              </thead>
              <tbody>
              {schoolListing}
              </tbody>
            </table></div>
          } else{
              schoolAlldata = <h3 className="nodatafound">No data found</h3>
          }
          let exportView;
          if(userListing.length > 0){
            exportView = <div className="col-md-2  text-right"><button type="button" className="btn btn-warning" onClick={() => this.downloadUsersFile()}>Export</button></div>
          }
          else{
            exportView = null;
          }
        return(
            <div className="main_contents">
              <BlockUi blocking={this.state.blocking}>
              <div className="row titleheader">
                
                <h1 className="page_title col-sm-7">Life Reset Users</h1>
                <div className="col-md-3">
                      <input type="text" id="search" className="form-control" onChange={this.searchHandle} placeholder="Search.." value={this.state.search} />
                </div>
                {exportView}
                {/* <label>{this.state.labelHolder}</label>
                <button onClick={this.myFunction.bind(this, "hindi")}>hey</button> */}
                {/* <div className="col-sm-6 text-left">
                <Select
                    value={selectedState}
                    onChange={this.selectState}
                    options={stateOptions}
                    placeholder="Select School"
                />
                </div> */}
              </div>
            
            <Row>
              <Col xs="12" lg="12" className="divright">
                <Card className="mytablesright settingright">
                  <CardBody>
                    {schoolAlldata}
                    <div className="loadMoreDiv">
                        {isMorebtn}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* ////// */}

            <div className="modal-container">
               {/* <Button
                   bsStyle="primary"
                   bsSize="large"
                   onClick={() => this.setState({ show: true })}
               >
                   Launch contained modal
               </Button> */}

                <Modal
                  show={this.state.show}
                  //  onHide={this.close}
                  container={this}
                  aria-labelledby="contained-modal-title"
               >
               
                    <Form ref={(form) => this.loginFormRef = form} className="resetPopup" name="loginForm" onSubmit={this.newEditSubmit}>
                   <Modal.Header >
                       <Modal.Title id="contained-modal-title">Edit User</Modal.Title>
                   </Modal.Header>

                   <Modal.Body>
                    <div className="formbox">
                        <label><sup className="required_icon">*</sup> Name</label>
                        <Input type="text" className="form-control" id="editName" ref="editName" onChange={this.handleChange} value={this.state.editName} validations={[required]}/>
                    </div>

                    {/* <div className="formbox">
                        <label>User Name</label>
                        <Input type="text" className="form-control" id="editUserName" ref="editUserName" onChange={this.handleChange} value={this.state.editUserName} validations={[required]}/>
                    </div> */}

                    <div className="formbox">
                        <label>Gender</label>
                        <input type="radio" id="editRadio1" name="gender" onChange={this.handleChange} checked={(editRadio == "MALE") ? true : false}/>Male
                        <input type="radio" id="editRadio2" name="gender" onChange={this.handleChange} checked={(editRadio == "FEMALE") ? true : false}/>Female
                        <input type="radio" id="editRadio3" name="gender" onChange={this.handleChange} checked={(editRadio == "OTHER") ? true : false}/>Other<br></br>
                    </div>
                    
                    <div className="formbox">
                        <label>Year Of Birth</label>
                        <input type="text" className="form-control" id="editYOB" ref="editYOB" onChange={this.handleChange} value={this.state.editYOB}/>
                    </div>

                    <div className="formbox">
                          <label>User Role</label>
                          <select className="form-control" id="selectRole" onChange={this.handleAddOfficerChange} value={this.state.currentSelectRole}>
                            <option value="">Select</option>,
                            {selectRole}
                          </select>
                    </div>

                    <div className="formbox">
                        <label><sup className="required_icon">*</sup> Email</label>
                        <Input type="text" className="form-control" id="editEmail" ref="editEmail" onChange={this.handleChange} value={this.state.editEmail} validations={[required, email]}/>
                    </div>

                    <div className="formbox custom_datepicker">
                            <label><sup className="required_icon">*</sup> Select Expiry date</label>
                              <DatePicker
                                selected={this.state.date}
                                onChange={this.dateChange}
                                // dateFormat="MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                                // showMonthYearPicker
                                // showMonthDropdown
                                // dropdownMode="select"
                                // showYearDropdown
                                // maxDate={new Date()} //imp
                                // maxDate={(new Date(), 50)}
                                // maxDate={addMonths(new Date(), 1)} //working
                                // dateFormat="MMMM, yyyy"
                                minDate={new Date(date.getFullYear(), date.getMonth(), (date.getDate() + 1))}
                                // maxDate={moment().subtract(1, 'months')}
                                placeholderText="Select a date"
                              />
                        </div>

                    {/* <div className="formbox">
                        <label>Password</label>
                        <Input type="password" className="form-control" id="editPass" ref="editPass" onChange={this.handleChange} value={this.state.editPass} validations={[required]}/>
                    </div> */}
                    
                    {/* <div className="formbox">
                        <label>State Short Name</label>
                        <Input type="text" className="form-control" id="insertStateSN" ref="insertStateSN" onChange={this.handleChange} value={this.state.insertStateSN} validations={[required]}/>
                    </div> */}
                    
                    {/* <div className="formbox">
                        <label>Comfirm Password</label>
                        <Input type="text" className="form-control" id="confirmPassword" ref="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} validations={[minNo, maxNo]}/>{modalIcon}
                    </div> */}

                    {/* <div className="createpassword">
                    <span onClick={this.createPass}>Generate Password:</span>   {this.state.createPass}
                    </div> */}
                    {/* <button type="button" className="btn btn-primary" onClick={this.createPass}>Create</button> */}
                   </Modal.Body>
                   <Modal.Footer>
                      <Button type="submit" className="savebtn btn btn-secondary">Save</Button>
                  </Modal.Footer>
                  </Form>
                  <button className="btn btn-danger" onClick={this.closeEditSchool}>Close</button>
               </Modal>
           
              {/* ////// */}




               
           </div>

           </BlockUi>
          </div>
        )
    }
}

export default LifeResetUsers;