import React from 'react';
import {
    // Badge,
    ButtonGroup,ButtonToolbar,Card,CardBody,CardTitle,Col,Row,Table,
    // FormGroup,Input,InputGroup,InputGroupAddon
    } from 'reactstrap';

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Constants from '../../../config/constants';
import $ from 'jquery'; 
import BlockUi from 'react-block-ui';

class AdminDashboard extends React.Component{
    constructor(props){
        super(props);
        let superAdminToken = localStorage.getItem("superAdminToken");
        this.state = {
          superAdminToken : superAdminToken,
          blocking: true,
          searchExist: false,
          page: 1,
          pageSize: 20,
          isMore: false,
          
          schoolCount: 0,
          organizationCount: 0,
          userCount: 0,
          subscriptionCount: 0,
          finalPagesCount: 1
        }

        this.dashboardDataList = this.dashboardDataList.bind(this);
    }

    componentWillMount(){
      this.dashboardDataList();
    }

    // get dashboardDataList
    dashboardDataList() {
        
      var _that = this;
    //   var param = { page_number: _that.state.page, page_size: _that.state.pageSize, account_id: 0, is_private: true
    //     , search_text: _that.state.search.trim()
    //     , gender: _that.state.selectedValue
    // }
      _that.setState({ blocking: true });
   
      fetch(Constants.SERVER_URL + "admin/dashboard", {
        method: 'GET', 
        headers: {                
          "Accept": "application/json",
          "Content-type": "application/json",
          "Authorization": _that.state.superAdminToken
        }
      }).then(function (response) {
      
        if(response.status === 200){
          let responseData = response.json();
          responseData.then(function (data) {
          
            _that.setState({ blocking: false});
            // if (data.data.length == _that.state.pageSize) {
            //   _that.setState({
            //     isMore: true
            //   });
            // } else {
            //   _that.setState({
            //     isMore: false
            //   });
            // }
            // if (_that.state.page == 1) {
               
              _that.setState({ blocking: false, 
                userCount: data.data.userCount,
                subscriptionCount: data.data.subscriptionCount,
                schoolCount: data.data.schoolCount,
                organizationCount: data.data.organizationCount,
              });
            // }
            // else if (data.data.length > 0) {
            //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
            //   let newData = JSON.parse(JSON.stringify(data.data));
            //   let totalData = oldData.concat(newData);

            //   _that.setState({ userListing: totalData, blocking: false });
            // } else if (_that.state.searchExist == false) {
              
            //   let oldData = JSON.parse(JSON.stringify(_that.state.userListing));
            //   let newData = JSON.parse(JSON.stringify(data.data));
            //   let totalData = oldData.concat(newData);

            //   _that.setState({ userListing: totalData, blocking: false });
            // } else {
            //   _that.setState({ blocking: false, userListing: data.data, page: 1 });
            // }
            
          })
        
        } else{
          let responseData = response.json();
          responseData.then(function (data) {
            
            _that.setState({ blocking: false});
            Alert.error('<h5>' + data.message + '</h5>', {
              position: 'top-right',
              effect: 'slide',
              beep: false,
              timeout: 3000,
              offset: Constants.OffSetValue
            });
          })
        }
      }).catch(function (error) {
        _that.setState({ blocking: false });
        if (navigator.onLine) {
          Alert.error('<h5>' + Constants.SERVER_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        } else {
          Alert.error('<h5>' + Constants.INTERNET_CONNECTION_ISSUE + '</h5>', {
            position: 'top-right',
            effect: 'slide',
            beep: false,
            timeout: 3000,
            offset: Constants.OffSetValue
          });
        }
      });
}

    render(){

      const { schoolCount, organizationCount, userCount, subscriptionCount} = this.state;

      let schoolCountNo, userCountNo, subscriptionCountNo, organizationCountNo;
      if(schoolCount != null && schoolCount != undefined && schoolCount != "" ){
        schoolCountNo = schoolCount;
      } else{
        schoolCountNo = "-"
      }
      if(organizationCount != null && organizationCount != undefined && organizationCount != ""  ){
        organizationCountNo = organizationCount;
      } else{
        organizationCountNo = "-"
      }
      if(userCount != null && userCount != undefined && userCount != ""){
        userCountNo = userCount;
      } else{
        userCountNo = "-"
      }
      if(subscriptionCount != null && subscriptionCount != undefined && subscriptionCount != "" ){
        subscriptionCountNo = subscriptionCount;
      } else{
        subscriptionCountNo = "-"
      }
        return(
          <BlockUi blocking={this.state.blocking}>
            <Row>
              
              <Col xs="12" lg="12" className="divright">
                <Card className="mytablesright settingright mydasboardmin">
                  <CardBody>
                    {/* {schoolAlldata} */}
                    <div>
                      <h1 className="page_title">Admin Dashboard</h1>
                      <div className="grid grid--corp row dashboardactivity">

                        <div className="col-lg-3 col-md-6">
                          <div className="girdbox">
                            <div className="corporateDashboard corporatepadding">
                            <i className="fa fa-first-order" aria-hidden="true"></i><b><span></span></b>
                            
                              <h4>Schools</h4>
                              <h6>(in No.)</h6>
                            </div>
                            <h1 className="corporateclr pieChart_dashboard">
                              {schoolCountNo}
                            </h1>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="girdbox">
                            <div className="corporateDashboard corporatepadding">
                            <i className="fa fa-star" aria-hidden="true"></i><span></span>
                              <h4>Organizations</h4>
                              <h6>(in No.)</h6>
                            </div>
                            <h1 className="corporateclr pieChart_dashboard">
                              {organizationCountNo}
                            </h1>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="girdbox">
                            <div className="corporateDashboard corporatepadding">
                            {/* <i className="fa fa-paper-plane-o"></i>  */}
                            <i className="fa fa-user"></i>
                              <h4>Private Users</h4>
                              <h6>(in No.)</h6>
                            </div>
                            <h1 className="corporateclr pieChart_dashboard">
                              {userCountNo}
                            </h1>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="girdbox">
                            <div className="corporateDashboard corporatepadding">
                            <i className="fa fa-money"></i> <span></span>
                              <h4>Subscriptions</h4>
                              <h6>(in No.)</h6>
                            </div>
                            <h1 className="corporateclr pieChart_dashboard">
                            {subscriptionCountNo}
                            </h1>
                          </div>
                        </div>

                      </div>

                    </div>
                    {/* <div className="loadMoreDiv">
                        {isMorebtn}
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
            </BlockUi>
        )
    }
}

export default AdminDashboard;