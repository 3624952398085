import React from 'react';
import ReactDOM from 'react-dom';
// import {$,jQuery} from 'jquery';
//import './index.css';
import Layout from './layout';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router} from 'react-router-dom';
import '../src/scss/UIblock.css'

import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient = bugsnag('cc83fd390086d71296f5be129357f8a5')
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin('react')
ReactDOM.render(
	// bugsnagClient.notify(new Error('Test error suru'))
	(
		<ErrorBoundary>
			<Router >
					<div className="w w--body" id="js-body" data-menu-type="side" data-menu="off">
						<Layout/>
					</div>
			</Router>
		</ErrorBoundary>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
