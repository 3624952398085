/* import react modules  */
import React from 'react';


// /* import helper file */
// import Constants from '../../config/constants';
// import HelperFunction from '../../config/helperFunction';

// /* import Block-ui Library */
// import BlockUi from 'react-block-ui';
// import 'react-block-ui/style.css';

// /* modules for Alerts */
// import Alert from 'react-s-alert';
// import 'react-s-alert/dist/s-alert-default.css';
// import 'react-s-alert/dist/s-alert-css-effects/slide.css';
// import 'react-s-alert/dist/s-alert-css-effects/slide.css';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    let token = localStorage.getItem("token");
    this.state = { 
      xyz : "xyz"
     }
   
  }

  /**** render view  ****/
  render() { 
   return(
     <div className="main_contents">
        <h1 className="page_title">Dashboard</h1>
        
      </div>
     );
  }
}

export default Dashboard;

