import HelperFunction from './helperFunction';

var Constants ={
    //  SERVER_URL: "http://192.168.168.21:3000/", // local
     SERVER_URL: "https://api.madetogrow.no/", // live
    // SERVER_URL: "https://stagingapi.madetogrow.no/", // staging

    SERVER_CONNECTION_ISSUE: "There Was a Problem Connecting to the Server. Please Try Again.",
    INTERNET_CONNECTION_ISSUE: "Please check your network connection.",
    Unauthorized_Access: "Unauthorized Access",
    Invaild_Credential: "Invalid Credentials",
    Something_Wrong: "Something went wrong",
    OffSetValue: 100, //alert static values,
    NO_Space: "Field should not contain space",
    Select_School_First: "Please Select School or Organization First",
    Select_Theme_First: "Please Select Theme First",
    
    EMPTY_USER: "No user found.",
    CHECK_CREDENTIALS: "Incorrect username or password, Try again.",
    SUCCESS_SIGNIN: 'You have successfully signed in.',
    SUCESSFULLY_ADD: "Added Successfully",
    SUCESSFULLY_UPDATE: "Update Successfully"
}

export default Constants;

